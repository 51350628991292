import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MyContainer } from "../../styled/Container";
import Level1 from "./Level1";
import Level2 from "./Level2";
import Level3 from "./Level3";
import Level4 from "./Level4";

const Home = () => {
  const accessToken = localStorage.getItem("token");

  const myRole = localStorage.getItem("role");
  const navigate = useNavigate();

  useEffect(() => {
    accessToken === null && navigate("/login");
  }, []);
  //화면 주소창 치고 갔을 때 유저아이디가 없으면 navigate로

  return (
    <>
      {myRole === "ROLE_LV1" && <Level1 />}
      {myRole === "ROLE_LV2" && <Level2 />}
      {myRole === "ROLE_LV3" && <Level3 />}
      {myRole === "ROLE_LV4" && <Level4 />}
    </>
  );
};

export const Container = styled.div`
  ${MyContainer}
  align-items: center;
  justify-content: center;
`;

export default Home;
