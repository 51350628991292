/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {
  MyContainerWrap,
  MyContentContainer,
  MyPaginationContainer,
} from "../../styled/Container";
import api from "../../redux/api";
import BasicList from "../../components/BasicList";
import {
  MyTitleAndSelect,
  MyTitleList,
  MyTitleListSpan,
} from "../../styled/ListStyle";
import { MyMainForumContainer } from "../../styled/Forum";
import Pagination2 from "../../components/paging/Pagination2";
import FilterSelect from "../../components/FilterSelect";
import { RiFileExcel2Fill } from "react-icons/ri";
import FilterCalander from "../../components/FilterCalander";
import { useSelector } from "react-redux";
import moment from "moment";
import FilterName from "../../components/attendance/commute/FilterName";
import FilterAuto from "../../components/attendance/commute/FilterAuto";
import { MdEditNote } from "react-icons/md";
import CommuteModal from "../../components/attendance/commute/CommuteModal";
import Blank from "../../components/Blank";
import Swal from "sweetalert2";
import { ExcellQuery } from "../../hooks/useExcellQuery";
import Loading from "../../components/Loading";
import { Box, Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Commute = () => {
  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    accessToken === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      // console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  // states
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);
  const [commutesData, setCommutesData] = useState([]);
  const [contentData, setContentData] = useState([]);
  const myRole = localStorage.getItem("role");

  const [teamList, setTeamList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [addListModal, setAddListModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const autoList = [
    { value: 0, label: "전체보기" },
    { value: "M", label: "수동" },
    { value: "A", label: "자동" },
  ];

  const teamValue = useSelector((state) => state.select.teamValue);
  const nameValue = useSelector((state) => state.select.nameValue);
  const autoValue = useSelector((state) => state.select.autoValue);

  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat =
    moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");

  const defaultValue = [{ value: 0, label: "전체보기" }];

  useEffect(() => {
    myRole !== "ROLE_LV1" &&
      api.get(`/api/v1/teams`).then((res) => {
        setTeamList(
          res.data.list
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );
      }) &&
      (((teamValue === undefined || teamValue === 0) &&
        api.get(`/api/v1/users?status=Y`).then((res) => {
          setNameList(
            res?.data?.list
              ?.map((item) => [{ value: item.name, label: item.name }])
              .map((item) => item[0])
          );
        })) ||
        (teamValue !== undefined &&
          teamValue !== 0 &&
          teamValue !== 1 &&
          api.get(`/api/v1/teams/${teamValue}`).then((res) => {
            // console.log(res);
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.name, label: item.name }])
                .map((item) => item[0])
            );
          })) ||
        (teamValue === 1 &&
          api.get(`/api/v1/teams/leaves`).then((res) => {
            // console.log(res);
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.name, label: item.name }])
                .map((item) => item[0])
            );
          })));
  }, [teamValue]);

  // useEffect(() => {
  //   console.log(contentData);
  // }, [contentData]);

  useEffect(() => {
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    const teamQuery =
      teamValue === undefined
        ? ""
        : teamValue === 0
        ? ""
        : `&teamId=${teamValue}`;

    const nameQuery =
      nameValue === undefined
        ? ""
        : nameValue === 0
        ? ""
        : `&name=${nameValue}`;

    const autoQuery =
      autoValue === undefined
        ? ""
        : autoValue === 0
        ? ""
        : `&type=${autoValue}`;

    myRole !== "ROLE_LV1"
      ? api
          .get(
            `/api/v1/commutes?page=${page}&size=9${dateQuery}${teamQuery}${nameQuery}${autoQuery}`
          )
          .then((res) => {
            setCommutesData(res.data.data);
            setContentData(res.data.data.content);
            setTotalElements(res.data.data.totalElements);
            setSize(res.data.data.size);
          })
      : api
          .get(`/api/v1/commutes?page=${page}&size=9${dateQuery}${autoQuery}`)
          .then((res) => {
            setCommutesData(res.data.data);
            setContentData(res.data.data.content);
            setTotalElements(res.data.data.totalElements);
            setSize(res.data.data.size);
          });
  }, [page, startDate, endDate, teamValue, nameValue, autoValue]);

  const excelDownload = () => {
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    const teamQuery =
      teamValue === undefined
        ? ""
        : teamValue === 0
        ? ""
        : `&teamId=${teamValue}`;

    const nameQuery =
      nameValue === undefined
        ? ""
        : nameValue === 0
        ? ""
        : `&name=${nameValue}`;

    const autoQuery =
      autoValue === undefined
        ? ""
        : autoValue === 0
        ? ""
        : `&type=${autoValue}`;

    let query = dateQuery + teamQuery + nameQuery + autoQuery;
    const excellQuery = ExcellQuery(query);

    const date = new Date();
    const fileDate = moment(date).format("YYYY-MM-DD_HH:mm:ss");

    const config = {
      responseType: "blob",
    };
    setLoading(true);

    api
      .get(`/api/v1/commutes/excell${excellQuery}`, config)
      .then((res) => {
        // console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `commutes_${fileDate}.xls`);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        Swal.fire({
          title: "알림",
          text: "문서를 다운 받았습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: "알림",
          text: "현재 문서를 다운 받을 수 없습니다.",
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  };

  const addList = () => {
    setAddListModal(!addListModal);
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px", position: "relative" }}
    >
      {/* 모달창 */}
      {loading ? <Loading /> : null}
      {addListModal && (
        <CommuteModal
          // teamList={teamList}
          // nameListForAdd={nameListForAdd}
          // nameList={nameList}
          setAddListModal={setAddListModal}
          setContentData={setContentData}
        />
      )}
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <Title subtitle={"T&A"} title={"출퇴근"} />
            <ContentContainer>
              <ContainerWrap background={"#F3F8FF"}>
                <TitleAndSelect>
                  <span>출&middot;퇴근 이력</span>
                  <div style={{ display: "flex" }}>
                    <Filters>
                      <div>
                        <FilterCalander setPage={setPage} />
                      </div>
                      {myRole !== "ROLE_LV1" && (
                        <>
                          <FilterSelect
                            width={resize < 1520 ? 130 : 180}
                            text={"부서"}
                            myOptions={[defaultValue[0], ...teamList]}
                            setPage={setPage}
                          />
                          <FilterName
                            width={resize < 1520 ? 130 : 180}
                            text={"이름"}
                            myOptions={[defaultValue[0], ...nameList]}
                            setPage={setPage}
                          />
                        </>
                      )}
                      <FilterAuto
                        width={resize < 1520 ? 140 : 180}
                        text={"입력여부"}
                        myOptions={autoList}
                        setPage={setPage}
                      />
                    </Filters>

                    {myRole === "ROLE_LV2" && (
                      <ExcelDiv onClick={addList}>
                        <MdEditNote color="#3752D3" />
                      </ExcelDiv>
                    )}
                    {(myRole === "ROLE_LV2" || myRole === "ROLE_LV3") && (
                      <ExcelDiv onClick={excelDownload}>
                        <RiFileExcel2Fill color="#3752D3" />
                      </ExcelDiv>
                    )}
                  </div>
                </TitleAndSelect>
                {contentData.length <= 0 ? (
                  <Blank text={"등록된 문서가 없습니다."} />
                ) : (
                  <>
                    <ForumContainer>
                      {myRole === "ROLE_LV1" && resize >= 995 && (
                        <TitleList>
                          <TitleListSpan spanWidth={"18%"}>
                            작성일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"18%"}>출근</TitleListSpan>
                          <TitleListSpan spanWidth={"18%"}>퇴근</TitleListSpan>
                          <TitleListSpan spanWidth={"30%"}>기타</TitleListSpan>
                          <TitleListSpan spanWidth={"16%"}>
                            입력여부
                          </TitleListSpan>
                        </TitleList>
                      )}
                      {myRole === "ROLE_LV1" && resize < 995 && (
                        <TitleList>
                          <TitleListSpan spanWidth={"40%"}>
                            작성일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"40%"}>
                            출퇴근
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"20%"}>
                            입력여부
                          </TitleListSpan>
                        </TitleList>
                      )}
                      {myRole !== "ROLE_LV1" && resize >= 995 && (
                        <TitleList>
                          <TitleListSpan spanWidth={"14%"}>
                            작성일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"12%"}>부서 </TitleListSpan>
                          <TitleListSpan spanWidth={"12%"}>이름 </TitleListSpan>
                          <TitleListSpan spanWidth={"12%"}>출근</TitleListSpan>
                          <TitleListSpan spanWidth={"12%"}>퇴근</TitleListSpan>
                          <TitleListSpan spanWidth={"23%"}>기타</TitleListSpan>
                          <TitleListSpan spanWidth={"12%"}>
                            입력여부
                          </TitleListSpan>
                          {myRole === "ROLE_LV2" && (
                            <TitleListSpan spanWidth={"5%"}></TitleListSpan>
                          )}
                        </TitleList>
                      )}
                      {myRole !== "ROLE_LV1" && resize < 995 && (
                        <TitleList>
                          <TitleListSpan spanWidth={"25%"}>
                            작성일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"25%"}>이름</TitleListSpan>
                          <TitleListSpan
                            spanWidth={myRole === "ROLE_LV2" ? "25%" : "35%"}
                          >
                            출퇴근
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"15%"}>
                            입력여부
                          </TitleListSpan>
                          {myRole === "ROLE_LV2" && (
                            <TitleListSpan spanWidth={"10%"}></TitleListSpan>
                          )}
                        </TitleList>
                      )}
                      {contentData.map((item) => (
                        <BasicList
                          key={item.commuteId}
                          id={item.commuteId}
                          date={item.createDate}
                          startTime={item.startWorkTime}
                          endTime={item.endWorkTime}
                          type={item.type}
                          name={item.user.name}
                          teamName={item.user.teamName}
                          etc={item.etc}
                          // editList={editList}
                          setSelected={setSelected}
                          color={
                            selected.includes(`${item.commuteId}`) && "white"
                          }
                          bgColor={
                            selected.includes(`${item.commuteId}`) && "#3752D3"
                          }
                          selected={selected}
                        />
                      ))}
                    </ForumContainer>
                  </>
                )}
              </ContainerWrap>
              <PaginationContainer>
                <Pagination2
                  page={page + 1}
                  totalPages={commutesData.totalPages}
                  setPage={setPage}
                  size={size}
                  totalElements={totalElements}
                />
              </PaginationContainer>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
`;

const TitleList = styled.div`
  ${MyTitleList}
`;

const TitleListSpan = styled.div`
  ${MyTitleListSpan}
`;

const ForumContainer = styled.div`
  ${MyMainForumContainer}
`;

const Filters = styled.div`
  display: flex;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

const ExcelDiv = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3752d3;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
`;

export default Commute;
