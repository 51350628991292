import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import api from "../../redux/api";
import Loading from "../../components/Loading";

const FindPasswordStepThree = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [passwordType, setPasswordType] = useState("password");

  const accessToken = localStorage.getItem("token");

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const changePassword = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      setLoading(true);
      const { data } = await api
        .post(`/api/v1/users/pw`, { password }, config)
        .then((res) => {
          setLoading(false);
          localStorage.removeItem("token");
          localStorage.removeItem("findToken");
          alert("로그인 페이지로 이동합니다.");
          navigate("/login");
        });
    } catch (error) {
      console.log("err: ", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <FindPasswordContainer>
      <div>
        <div
          style={{
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          3
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <FindInput
            type={passwordType}
            placeholder="변경할 비밀번호를 입력해주세요."
            value={password}
            onChange={onChangePassword}
          >
            {/* <div>skdhk</div> */}
          </FindInput>
          <div style={{ position: "absolute", top: "30%", right: "10px" }}>
            {passwordType === "password" ? (
              <FindInputBox
                onClick={() => {
                  setPasswordType("text");
                }}
              >
                <MdVisibility size={24} />
              </FindInputBox>
            ) : (
              <FindInputBox
                onClick={() => {
                  setPasswordType("password");
                }}
              >
                <MdVisibilityOff size={24} />
              </FindInputBox>
            )}
          </div>
        </div>

        <ConfirmButton onClick={changePassword}>비밀번호 변경</ConfirmButton>
      </div>
    </FindPasswordContainer>
  );
};

export default FindPasswordStepThree;

const FindPasswordContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const FindInput = styled.input`
  display: flex;
  height: 60px;
  font-size: 14px;
  margin: 10px 0px;
  padding-left: 15px;
  width: 400px;
  border-radius: 10px;
  border: 1px solid gray;
`;

const FindInputBox = styled.div`
  width: 50px;
  display: flex;
  justify-content: flex-end;
`;

const ConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #3752d3;
  color: white;

  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
  padding: 15px;
  margin-top: 40px;

  border-radius: 10px;
  width: 400px;
`;
