const ExcellQuery = (prop) => {
  let query = prop;

  if (query === "") {
    query = "";
  } else {
    query = `?${query.substr(1)}`;
  }

  return query;
};

export { ExcellQuery };
