import { Grid, useMediaQuery } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import styled, { createGlobalStyle } from "styled-components";
import Swal from "sweetalert2";
import api from "../../redux/api";

const CorpRegistCard = ({
  key, //
  index,
  resultCard,
  setResultCard,
  payType,
}) => {
  const 지출목적 = [
    { value: "0", label: "선택" },
    { value: "1", label: "교통비" },
    { value: "2", label: "기타" },
    { value: "3", label: "도서구입" },
    { value: "4", label: "복리후생비" },
    { value: "5", label: "부가가치세" },
    { value: "6", label: "사무비품" },
    { value: "7", label: "소모품비" },
    { value: "8", label: "숙박비" },
    { value: "9", label: "식대" },
    { value: "10", label: "용역비" },
    { value: "11", label: "접대비" },
    { value: "12", label: "주유비" },
    { value: "13", label: "주차비" },
    { value: "14", label: "지급수수료" },
    { value: "15", label: "출장비" },
    { value: "16", label: "환불 지급" },
  ];

  const [startDate, setStartDate] = useState(new Date());

  const [usedDate, setUsedDate] = useState("");
  const [payItem, setPayItem] = useState("");
  const [amount, setAmount] = useState("");
  const [department, setDepartment] = useState("");
  const [person, setPerson] = useState("");

  const [teamList, setTeamList] = useState([]);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // TODO: input
  const onChangeAmount = async (e) => {
    const regexNum = /[0-9]/g; // 숫자

    const target = e.target.value;
    const test = target.replace(/ /g, "");
    const number = test.replace(/,/g, "");

    if (!regexNum.test(Number(target[0]))) {
      Swal.fire({
        title: "금액",
        text: "금액은 숫자로 입력해주세요.",
        icon: "question",
      });
      setAmount("");
    } else {
      setAmount(test);

      const test2 = Number(number);

      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, amount: Number(test2) };
          }
        }),
      ]);
    }
  };
  // const onChangeAmount = useCallback(
  //   (e) => {
  //     const regexNum = /[0-9]/g; // 숫지

  //     if (regexNum.test(Number(amount))) {
  //       setAmount(e.target.value);
  //       setResultCard([
  //         ...resultCard.map((v, i) => {
  //           if (i !== index) {
  //             return v;
  //           } else {
  //             return { ...v, amount: Number(e.target.value) };
  //           }
  //         }),
  //       ]);
  //     } else {
  //       setAmount("");
  //     }
  //   },
  //   [index, setResultCard, resultCard, amount]
  // );

  const onChangePerson = useCallback(
    (e) => {
      const regexNum = /[0-9]/g; // 숫자

      if (regexNum.test(Number(person))) {
        setPerson(e.target.value);
        setResultCard([
          ...resultCard.map((v, i) => {
            if (i !== index) {
              return v;
            } else {
              return { ...v, person: Number(e.target.value) };
            }
          }),
        ]);
      } else {
        setPerson("");
      }
    },
    [index, setResultCard, resultCard, person]
  );

  // TODO: select
  const onChangePayItem = useCallback(
    (e) => {
      setPayItem(e.currentTarget.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, payItem: e.currentTarget.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  const onChangeDepartment = useCallback(
    (e) => {
      setDepartment(e.currentTarget.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, department: e.currentTarget.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  useEffect(() => {
    api.get(`/api/v1/teams`).then((res) => {
      setTeamList(
        res?.data?.list
          ?.map((item) => [{ value: item?.id, label: item?.name }])
          .map((item) => item[0])
      );
    });
  }, []);

  return (
    <Grid container>
      <PayRegisterLists key={key}>
        {mdUp ? (
          <>
            <Grid
              item
              md={2.4}
              lg={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  width: "20%",
                }}
              >
                날짜
              </span>
              <ReactDatePicker
                wrapperClassName="date_picker full-width"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);

                  setResultCard([
                    ...resultCard.map((v, i) => {
                      if (i !== index) {
                        return v;
                      } else {
                        return {
                          ...v,
                          usedDate: moment(date).format("YYYY-MM-DD"),
                        };
                      }
                    }),
                  ]);
                }}
              />
              <DatePickerWrapperStyles />
            </Grid>
            <Grid
              item
              md={2.4}
              lg={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지출목적</span>
              <RegisterSelect onChange={onChangePayItem}>
                {지출목적?.map((item) => {
                  return <option key={item?.value}>{item?.label}</option>;
                })}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              md={2.4}
              lg={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>금액</span>
              <ContentInput
                value={amount}
                onChange={onChangeAmount}
                placeholder="10000"
              />
            </Grid>
            <Grid
              item
              md={2.4}
              lg={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지출부서</span>
              <RegisterSelect onChange={onChangeDepartment}>
                {teamList?.map((item) => {
                  return <option key={item?.value}>{item?.label}</option>;
                })}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              md={2.4}
              lg={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>인원</span>
              <ContentInput
                value={person}
                onChange={onChangePerson}
                // placeholder="인원을 입력해주세요"
                placeholder="10"
              />
            </Grid>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    width: "20%",
                  }}
                >
                  날짜
                </span>
                <ReactDatePicker
                  wrapperClassName="date_picker full-width"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setResultCard([
                      ...resultCard.map((v, i) => {
                        if (i !== index) {
                          return v;
                        } else {
                          return {
                            ...v,
                            usedDate: moment(date).format("YYYY-MM-DD"),
                          };
                        }
                      }),
                    ]);
                  }}
                />
                <DatePickerWrapperStyles />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span>지출목적</span>
                <RegisterSelect onChange={onChangePayItem}>
                  {지출목적?.map((item) => {
                    return <option key={item?.value}>{item?.label}</option>;
                  })}
                </RegisterSelect>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span>금액</span>
                <ContentInput
                  value={amount}
                  onChange={onChangeAmount}
                  // placeholder="금액을 입력해주세요"
                  placeholder="10000"
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span>지출부서</span>
                <RegisterSelect onChange={onChangeDepartment}>
                  {teamList?.map((item) => {
                    return <option key={item?.value}>{item?.label}</option>;
                  })}
                </RegisterSelect>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span>인원</span>
                <ContentInput
                  value={person}
                  onChange={onChangePerson}
                  // placeholder="인원을 입력해주세요"
                  placeholder="10"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </PayRegisterLists>
    </Grid>
  );
};

const PayRegisterLists = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #3752d3;
  border-radius: 10px;
  align-items: center;
  padding: 12px 8px;
`;

const ContentInput = styled.input`
  width: 100px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  margin-left: 5px;
  padding: 8px;
`;

const RegisterSelect = styled.select`
  width: 100px;
  height: 35px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  margin-left: 5px;
`;

const DatePickerWrapperStyles = createGlobalStyle`
  .react-datepicker__input-container {
    width: 100%;
    display: flex;
    justify-content: center;

    input {
        width: 100px;
        height: 35px;
        border: 1px solid #3752d3;
        border-radius: 10px;
        margin-left: 5px;
        text-align: center;
    }
  } 
`;

export default CorpRegistCard;
