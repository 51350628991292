import { Grid } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { MyPayType, MyPayTypeContainer, MyTypeWrap } from "../styled/Payment";

const PayInfoType = ({
  id,
  payType,
  category,
  // 등록
  typeArr,
  onClickPayType,
  categoryArr,
  onClickCategory,

  // 휴가 상세
  startDate,
  endDate,
  hours,

  // 휴가 등록
  today,
  onChangeStartDate,
  onChangeEndDate,
  onChangeHours,

  // 시간차
  timeOffArr,
  time,
  setTime,
  onChangeTime,
}) => {
  const location = useLocation();

  return (
    <>
      {location.pathname === `/payment/${id}` && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={6} lg={6}>
              <PayDetailPayType>
                <span>형식</span>
                <PayType>{payType}</PayType>
              </PayDetailPayType>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <PayDetailPayType>
                <span>유형</span>
                <PayType>{category}</PayType>
              </PayDetailPayType>
            </Grid>
          </Grid>
        </>
      )}
      {/* 등록 */}
      {location.pathname === "/payment/register" && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <PaymentRegisterType>
                <span style={{ width: "40%" }}>형식</span>
                {typeArr.map((type) => (
                  <RegisterPayType
                    key={type}
                    payType={payType === type}
                    onClick={onClickPayType}
                  >
                    {type}
                  </RegisterPayType>
                ))}
              </PaymentRegisterType>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "10px 10px",
                  border: "1px solid #3752d3",
                  borderRadius: "10px",
                  gap: "20px",
                }}
              >
                <span style={{ width: "40%" }}>유형</span>
                {categoryArr.map((type) => (
                  <RegisterPayType
                    key={type}
                    category={category === type}
                    onClick={onClickCategory}
                  >
                    {type}
                  </RegisterPayType>
                ))}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* TODO: 휴가 상세 */}
      {location.pathname === `/day-off/${id}` && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={time ? 4 : 6} lg={time ? 4 : 6}>
              <PayTypeContainer>
                <span>타입</span>
                <PayType>{payType}</PayType>
              </PayTypeContainer>
            </Grid>

            <Grid item xs={12} md={time ? 4 : 6} lg={time ? 4 : 6}>
              <PayTypeContainer>
                <span>종류</span>
                <PayType>{category}</PayType>
              </PayTypeContainer>
            </Grid>

            {time ? (
              <Grid item xs={12} md={4} lg={4}>
                <PayTypeContainer>
                  <span>시간차 종류</span>
                  <PayType>
                    {time === "LC"
                      ? "늦게출근" //"출근지연"
                      : time === "EL"
                      ? "일찍퇴근" // "조기퇴근"
                      : "외출"}
                  </PayType>
                </PayTypeContainer>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={hours ? 4 : 6}>
              <PayTypeContainer>
                <span>시작일</span>
                <PayType>{startDate}</PayType>
              </PayTypeContainer>
            </Grid>
            <Grid item xs={12} lg={hours ? 4 : 6}>
              <PayTypeContainer>
                <span>종료일</span>
                <PayType>{endDate}</PayType>
              </PayTypeContainer>
            </Grid>
            {hours ? (
              <Grid item xs={12} lg={4}>
                <PayTypeContainer>
                  <span>총시간</span>
                  <PayType>{hours}시간</PayType>
                </PayTypeContainer>
              </Grid>
            ) : null}
          </Grid>
        </>
      )}

      {/* FIXME: 휴가 등록 */}
      {location.pathname === `/day-off/register` && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "10px 10px",
                  border: "1px solid #3752d3",
                  borderRadius: "10px",
                  gap: "20px",
                }}
              >
                <span style={{ width: "40%" }}>타입</span>
                {typeArr.map((type) => (
                  <RegisterPayType
                    key={type}
                    payType={payType === type}
                    onClick={onClickPayType}
                    // gap={"30px"}
                  >
                    {type}
                  </RegisterPayType>
                ))}
              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <>
                <CategoryContainer>
                  <span style={{ width: "40%" }}>종류</span>
                  {categoryArr.map((type) => (
                    <RegisterPayType
                      key={type}
                      category={category === type}
                      onClick={onClickCategory}
                    >
                      {type}
                    </RegisterPayType>
                  ))}
                </CategoryContainer>

                {/* TODO: 시간차 선택 */}
                {category === "시간" ? (
                  <TimeCategoryContainer>
                    {timeOffArr.map((type) => (
                      <TimeRegisterType
                        key={type}
                        time={time === type}
                        onClick={onChangeTime}
                      >
                        {type}
                      </TimeRegisterType>
                    ))}
                  </TimeCategoryContainer>
                ) : null}
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={4} lg={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "10px 10px",
                  border: "1px solid #3752d3",
                  borderRadius: "10px",
                }}
              >
                <span style={{ width: "30%" }}>시작일</span>
                <RegisterInput
                  value={startDate}
                  onChange={onChangeStartDate}
                  // placeholder={`${today} 형식으로 날짜를 입력해주세요`}
                  placeholder={`${today}`}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <PayTypeContainer>
                <span style={{ width: "30%" }}>종료일</span>
                <RegisterInput
                  value={endDate}
                  onChange={onChangeEndDate}
                  placeholder={`${today}`}
                />
              </PayTypeContainer>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <PayTypeContainer>
                <span style={{ width: "30%" }}>총시간</span>
                {category === "기간" ? (
                  <RegisterInput value={hours} readOnly />
                ) : (
                  <RegisterInput
                    value={hours}
                    onChange={onChangeHours}
                    placeholder="`8시간` 형식으로 작성해주세요"
                  />
                )}
              </PayTypeContainer>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const PayTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 10px;
  border: 1px solid #3752d3;
  border-radius: 10px;
`;

const PayType = styled.div`
  ${MyPayType}
  border: 1px solid #3752D3;
  background: #3752d3;
  color: #ffffff;
`;

const PayDetailPayType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 10px;
  border: 1px solid #3752d3;
  border-radius: 10px;
`;

const PaymentRegisterType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 10px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  gap: 20px;
`;

const RegisterPayType = styled.div`
  ${MyPayType}
  border: 1px solid #b2b8c1;
  color: #b2b8c1;

  ${({ payType }) =>
    payType &&
    `
    border: 1px solid #3752D3;
    background: #3752D3;
    color: white;
  `}
  ${({ category }) =>
    category &&
    `
    border: 1px solid #3752D3;
    background: #3752D3;
    color: white;
  `};
`;

const TimeRegisterType = styled.div`
  border: 1px solid #b2b8c1;
  padding: 5px 20px;
  border-radius: 15px;

  ${({ time }) =>
    time &&
    `
    border: 1px solid #3752D3;
    background: #3752D3;
    color: white;
    `}
`;

const RegisterInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  width: 70%;
  height: 35px;
  text-align: center;
  border: 1px solid #3752d3;
  border-radius: 18px;
`;

const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 10px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  gap: 20px;
`;

const TimeCategoryContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 10px;
  border: 1px solid #3752d3;
  border-radius: 10px;
`;

export default PayInfoType;
