import { css } from "styled-components";

export const RowCenter = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const RowAlignCenter = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const RowJustifyCenter = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const Column = css`
    display: flex;
    flex-direction: column;
`;

export const ColumnCenter = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ColumnAlignCenter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ColumnJustifyCenter = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

