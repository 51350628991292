import React, { useCallback, useState } from "react";
import styled from "styled-components";
import api from "../../redux/api";

import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";

const FindPasswordStepOne = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState("");

  const onChangePhone = useCallback(
    (e) => {
      setPhone(e.target.value.trim());
    },
    [phone]
  );

  const onChangeUserId = useCallback(
    (e) => {
      setUserId(e.target.value.trim());
    },
    [userId]
  );

  const gotoFindPasswordStep2 = async () => {
    try {
      setLoading(true);

      const { data } = await api.post(`/api/v1/users/sms-req`, {
        userId,
        phone,
      });

      localStorage.setItem("findToken", data?.data?.token);
      navigate("/findpw2");

      // console.log("data:::", data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <FindPasswordContainer>
      <div>
        <div
          style={{
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          1
        </div>
        <FindInput
          placeholder="사용자 아이디를 입력해주세요."
          value={userId}
          onChange={onChangeUserId}
        />
        <FindInput
          placeholder="휴대폰 번호를 입력해주세요."
          value={phone}
          onChange={onChangePhone}
        />

        <ConfirmButton onClick={gotoFindPasswordStep2}>
          문자 인증 요청
        </ConfirmButton>
      </div>
    </FindPasswordContainer>
  );
};

export default FindPasswordStepOne;

// 이름 겹칠 우려가 있어서 일부러 풀네임으로 합니당!
const FindPasswordContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const FindInput = styled.input`
  display: flex;
  height: 60px;
  font-size: 14px;
  margin: 10px 0px;
  padding-left: 15px;
  width: 400px;
  border-radius: 10px;
  border: 1px solid gray;
`;

const ConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #3752d3;
  color: white;

  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
  padding: 15px;
  margin-top: 40px;

  border-radius: 10px;
  width: 400px;
`;
