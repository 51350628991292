// 달력
import React from "react";
import { createGlobalStyle } from "styled-components";
import Calendar from "react-calendar";
import moment from "moment";

import "react-calendar/dist/Calendar.css";
import { Card } from "@mui/material";

// TODO: 문서화가 필요할 듯..?
// react-calendar 라이브러리를 사용하였으나, 스타일이 엉망이기 때문에
// styled-component의 GlobalStyle로
const ReactCalendarWrapperStyles = createGlobalStyle`
  .react-calendar {
    display: flex;
    flex-direction: column;
    border: none;
    width: 100%;
    height: 100%;
    font-size: 20px;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    line-height: 1.125em;
  }

  .react-calendar__navigation {
    height: 20%;
    font-size: 20px;
    .react-calendar__navigation__arrow {
      display: none;
    }
  }
  .react-calendar__navigation__label__labelText {
    font-size: 20px;
  }

  .react-calendar__viewContainer {
    height: 80%;
  }

  .react-calendar__month-view__days__day {
    border: 1px solid #B2B8C1;
    border-radius: 10px;
    padding: 20px;
    
  }

  .react-calendar__tile--now {
    background: #4285F4 !important;
    color: white;
  }

  abbr {
    text-decoration: none;
    // margin-bottom: 30px;
  }
`;

const CalendarOverview = () => {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        height: "100%",
        p: 2,
      }}
    >
      <Calendar
        formatDay={(locale, date) => moment(date).format("DD")}
        showNeighboringMonth={false}
      />

      <ReactCalendarWrapperStyles />
    </Card>
  );
};

export default CalendarOverview;
