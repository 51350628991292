import React, { useState } from "react";
import {
  experimentalStyled,
  useMediaQuery,
  Container,
  Box,
} from "@mui/material";
import Header2 from "./Header2";
import Sidebar2 from "./Sidebar2";
import { Outlet } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const MainWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));

const PageWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    paddingTop: "64px",
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: "64px",
  },
}));

const FullLayout2 = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      <MainWrapper>
        <Header2
          sx={{
            paddingLeft: isSidebarOpen && lgUp ? "265px" : "",
            backgroundColor: "#fbfbfb",
          }}
          toggleMobileSidebar={() => setMobileSidebarOpen(true)}
        />
        <Sidebar2
          isSidebarOpen={isSidebarOpen}
          isMobileSidebarOpen={isMobileSidebarOpen}
          onSidebarClose={() => setMobileSidebarOpen(false)}
          setMobileSidebarOpen={setMobileSidebarOpen}
          sx={{ padding: "0px" }}
        />
        <PageWrapper
          sx={{
            background: " #f3f3f3",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              paddingTop: "20px",
              paddingLeft: isSidebarOpen && lgUp ? "280px!important" : "",
            }}
          >
            <Box sx={{ minHeight: "calc(100vh - 170px)" }}>
              <Outlet />
            </Box>
          </Container>
        </PageWrapper>
      </MainWrapper>
      <AllSwalModal />
      {mdUp ? null : <ModalWrapperStyles />}
    </>
  );
};

const AllSwalModal = createGlobalStyle`
  .swal2-container {
    z-index: 9999;
  }
`;

const ModalWrapperStyles = createGlobalStyle`
  .swal2-popup.swal2-modal {
    width: 300px;
  }
`;

export default FullLayout2;
