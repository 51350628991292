import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../redux/api";
import moment from "moment";

import { Box, Card, Grid, Switch, useMediaQuery } from "@mui/material";
import Title from "../../../components/Title";
import Blank from "../../../components/Blank";
import ForumCard from "../../../components/forum/ForumCard";
import styled from "styled-components";

import { MyPaginationContainer } from "../../../styled/Container";
import Pagination2 from "../../../components/paging/Pagination2";
import Buttons from "../../../components/Buttons";
import MyButton from "../../../components/MyButton";
import FilterCalander2 from "../../../components/FilterCalander2";
import { MyTitleAndSelect } from "../../../styled/ListStyle";

import { FaLock, FaLockOpen } from "react-icons/fa";
import Swal from "sweetalert2";

const Anonymous = () => {
  const role = localStorage.getItem("role");

  const anonymousToken = localStorage.getItem("anonymousToken");

  const navigate = useNavigate();

  const [anonymousList, setAnonymousList] = useState([]);
  const [anonymousTotalPage, setAnonymousTotalPage] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);
  const [password, setPassword] = useState("");

  const [secret, setSecret] = useState(role === "ROLE_LV3" ? true : false);

  const { current: anonymousListArray } = anonymousList && anonymousList;

  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat =
    moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken === null && navigate("/login");
    // console.log(startDate);
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    api
      .get(`/api/v1/anonymous?page=${page}&size=6${dateQuery}&secret=${secret}`)
      .then((res) => {
        setAnonymousTotalPage(res.data.data.totalPages);
        setAnonymousList(res.data.data.content);
        setTotalElements(res.data.data.totalElements);
        setSize(res.data.data.size);
      });

    anonymousToken === null &&
      api.get(`/api/v1/anonymous/token`).then((res) => {
        localStorage.setItem("anonymousToken", res.data.data.token);
      });
  }, [page, anonymousListArray, startDate, endDate, secret]);

  // console.log("secret: ", secret);

  const goToNew = () => {
    navigate("/anonymous/new");
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <Card sx={{ borderRadius: "10px", width: "100%" }}>
            <Box p={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Title subtitle={"ANONYMOUS"} title={"익명게시판"} />

                <div style={{ display: lgUp ? "none" : "" }}>
                  <MyButton
                    width={"60px"}
                    height={"40px"}
                    text={"+"}
                    onClick={goToNew}
                  />
                </div>
              </div>

              <TitleAndSelect
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Filters>
                  <FilterCalander2 setPage={setPage} />
                  <div style={{ padding: 20 }}>
                    <span>비밀게시글</span>
                    <Switch
                      checked={secret}
                      onChange={() => {
                        setSecret(!secret);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Filters>
              </TitleAndSelect>

              {anonymousList?.length <= 0 ? (
                <Blank text={"등록된 문서가 없습니다."} height={"500"} />
              ) : (
                <Grid container spacing={2}>
                  {anonymousList?.map((item, idx) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} key={item.id}>
                        <ForumCard
                          // key={idx.toString()}
                          id={item.id}
                          value={item.id}
                          date={item.createDate}
                          title={item.title}
                          isSecret={item.isSecret}
                          content={item.shortContents}
                          name={"anonymous"}
                          commentCount={item.commentCount}
                          cursor={"pointer"}
                          password={password}
                          setPassword={setPassword}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}

              <PaginationContainer>
                <Pagination2
                  page={page + 1}
                  totalPages={anonymousTotalPage}
                  setPage={setPage}
                  totalElements={totalElements}
                  size={size}
                />
                <div
                  style={{
                    display: lgUp ? "" : "none",
                  }}
                >
                  <Buttons text={"작성"} color={"white"} onClick={goToNew} />
                </div>
              </PaginationContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const Filters = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect};
`;

export default Anonymous;
