import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../Title";
import {
  MyContainer,
  MyContainerWrap,
  MyContentContainer,
  MyMainContainer,
  MyPaginationContainer,
} from "../../styled/Container";
import {
  MyMainForumContainer,
  MyMainForumContainerForCard,
} from "../../styled/Forum";
import { useLocation, useNavigate } from "react-router-dom";
import ForumCard from "./ForumCard";
import {
  MyTitleAndSelect,
  MyTitleList,
  MyTitleListSpan,
} from "../../styled/ListStyle";
import BasicList from "../BasicList";
import Pagination2 from "../paging/Pagination2";
import Buttons from "../Buttons";
import { useDispatch, useSelector } from "react-redux";
import forumReducer from "../../redux/reducers/forumReducer";
import FilterSelect from "../FilterSelect";
import api from "../../redux/api";
import FilterCalander from "../FilterCalander";
import Blank from "../Blank";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../MyButton";

const ForumLayout = ({
  Lists,
  name,
  subtitle,
  title,
  buttonboolean,
  buttontext1,
  buttontext2,
  littleTitle,
  page,
  totalPages,
  setPage,
  goToNew,
  totalElements,
  size,
  cursor,
}) => {
  const myRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const files = useSelector((state) => state.forum.files);

  const [teamList, setTeamList] = useState([]);

  const goToNoticesWrite = () => {
    navigate("/notices/new");
    dispatch(forumReducer.actions.resetFiles(files));
  };
  const goToMasterWrite = () => {
    navigate("/master-notices/new");
    dispatch(forumReducer.actions.resetFiles(files));
  };
  const goToMeetingWrite = () => {
    navigate("/meeting/new");
    dispatch(forumReducer.actions.resetFiles(files));
  };

  const defaultValue = [{ value: 0, label: "전체보기" }];

  const [resize, setResize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    // console.log(Lists);
  }, [Lists.length]);

  useEffect(() => {
    location.pathname === "/meeting" &&
      api.get(`/api/v1/teams`).then((res) => {
        setTeamList(
          res.data.list
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );
      });
  }, []);

  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px" }}
    >
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                // padding: "0px 10px",
              }}
            >
              <Title subtitle={subtitle} title={title} />
              <div
                style={{
                  display: resize < 995 ? "flex" : "none",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                {location.pathname === "/master-notices" &&
                myRole === "ROLE_LV4" ? (
                  <MyButton
                    text={"+"}
                    width={"60px"}
                    height={"40px"}
                    // color={"white"}
                    onClick={goToMasterWrite}
                  />
                ) : null}
                {location.pathname === "/notices" && myRole !== "ROLE_LV1" ? (
                  <MyButton
                    text={"+"}
                    width={"60px"}
                    height={"40px"}
                    // color={"white"}
                    onClick={goToNoticesWrite}
                  />
                ) : null}
                {location.pathname === "/anonymous" && (
                  <MyButton
                    text={"+"}
                    width={"60px"}
                    height={"40px"}
                    // color={"white"}
                    onClick={goToNew}
                  />
                )}
                {location.pathname === "/meeting" &&
                  (myRole === "ROLE_LV1" || myRole === "ROLE_LV2") && (
                    <MyButton
                      text={"+"}
                      width={"60px"}
                      height={"40px"}
                      // color={"white"}
                      onClick={goToMeetingWrite}
                    />
                  )}
              </div>
            </div>
            <ContentContainer>
              <ContainerWrap
                background={
                  location.pathname === "/anonymous" ? "white" : "#F3F8FF"
                }
                width={location.pathname === "/anonymous" ? "1580" : "1540"}
                boxShadow={
                  location.pathname === "/anonymous"
                    ? "none"
                    : "0px 2px 7px rgba(0, 0, 0, 0.07)"
                }
              >
                <TitleAndSelect
                  width={location.pathname === "/anonymous" ? "1540px" : "95%"}
                >
                  <span>{littleTitle}</span>
                  <div style={{ display: "flex" }}>
                    <Filters>
                      <div>
                        <FilterCalander setPage={setPage} />
                      </div>
                      {location.pathname === "/meeting" ? (
                        <FilterSelect
                          width={200}
                          text={"부서"}
                          myOptions={[defaultValue[0], ...teamList]}
                          setPage={setPage}
                        />
                      ) : null}
                    </Filters>
                  </div>
                </TitleAndSelect>
                {Lists.length <= 0 ? (
                  <Blank text={"등록된 문서가 없습니다."} />
                ) : (
                  <>
                    {location.pathname === "/anonymous" ? (
                      <ForumContainerForCard>
                        {Lists.map((item) => (
                          <ForumCard
                            key={item.id}
                            id={item.id}
                            value={item.id}
                            date={item.createDate}
                            title={item.title}
                            content={item.shortContents}
                            name={name}
                            commentCount={item.commentCount}
                            cursor={cursor}
                          />
                        ))}
                      </ForumContainerForCard>
                    ) : (
                      <ForumContainer>
                        {(location.pathname === "/master-notices" ||
                          location.pathname === "/notices") && (
                          <TitleList>
                            <TitleListSpan
                              spanWidth={resize >= 995 ? "15%" : "25%"}
                            >
                              작성일자
                            </TitleListSpan>
                            <TitleListSpan
                              spanWidth={resize >= 995 ? "85%" : "75%"}
                            >
                              제목
                            </TitleListSpan>
                          </TitleList>
                        )}
                        {location.pathname === "/meeting" && (
                          <TitleList>
                            <TitleListSpan
                              spanWidth={resize >= 995 ? "20%" : "25%"}
                            >
                              작성일자
                            </TitleListSpan>
                            <TitleListSpan
                              spanWidth={resize >= 995 ? "65%" : "50%"}
                            >
                              제목
                            </TitleListSpan>
                            <TitleListSpan
                              spanWidth={resize >= 995 ? "15%" : "25%"}
                            >
                              필요부서
                            </TitleListSpan>
                          </TitleList>
                        )}
                        {Lists.map((item) => (
                          <BasicList
                            key={item.id}
                            value={item.id}
                            id={item.id}
                            date={item.createDate}
                            forumtitle={item.title}
                            buttonboolean={buttonboolean}
                            buttontext1={buttontext1}
                            buttontext2={buttontext2}
                            name={name}
                            team={item.teamName}
                            writer={item.writer}
                            isRead={item?.isRead}
                          />
                        ))}
                      </ForumContainer>
                    )}
                  </>
                )}
              </ContainerWrap>
              <PaginationContainer>
                <Pagination2
                  page={page}
                  totalPages={totalPages}
                  setPage={setPage}
                  totalElements={totalElements}
                  size={size}
                />
                <div
                  style={{
                    display: resize < 995 ? "none" : "",
                  }}
                >
                  {location.pathname === "/master-notices" &&
                  myRole === "ROLE_LV4" ? (
                    <Buttons
                      text={"작성"}
                      color={"white"}
                      onClick={goToMasterWrite}
                    />
                  ) : null}
                  {location.pathname === "/notices" &&
                  (myRole === "ROLE_LV2" || myRole === "ROLE_LV3") ? (
                    <Buttons
                      text={"작성"}
                      color={"white"}
                      onClick={goToNoticesWrite}
                    />
                  ) : null}
                  {location.pathname === "/anonymous" && (
                    <Buttons text={"작성"} color={"white"} onClick={goToNew} />
                  )}
                  {location.pathname === "/meeting" &&
                    (myRole === "ROLE_LV1" || myRole === "ROLE_LV2") && (
                      <Buttons
                        text={"작성"}
                        color={"white"}
                        onClick={goToMeetingWrite}
                      />
                    )}
                </div>
              </PaginationContainer>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const MainContainer = styled.div`
  ${MyMainContainer}
`;

const Container = styled.div`
  ${MyContainer}
`;

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const ForumContainer = styled.div`
  ${MyMainForumContainer}
`;

const ForumContainerForCard = styled.div`
  ${MyMainForumContainerForCard}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
`;

const TitleList = styled.div`
  ${MyTitleList}
`;

const TitleListSpan = styled.div`
  ${MyTitleListSpan}
`;

const Filters = styled.div`
  display: flex;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

export default ForumLayout;
