import React from "react";
import styled, { css } from "styled-components";
import { ThemeProvider } from "styled-components";
import { lighten, darken } from "polished";

const MyButton = ({
  text,
  color,
  onClick,
  background,
  width,
  height,
  padding,
  fontSize,
  fontWeight,
  margin,
  letterSpacing,
  border,
}) => {
  // const btnType = ['positive', 'negative'].includes(type) ? type : "default";

  return (
    <ThemeProvider
      theme={{
        rgbColors: {
          default: "#3752D3",
          danger: "#D33751",
          wait: "#F79514",
          cancel: "transparent",
        },
      }}
    >
      <Button
        onClick={onClick}
        color={color}
        background={background}
        width={width}
        height={height}
        padding={padding}
        fontSize={fontSize}
        fontWeight={fontWeight}
        margin={margin}
        letterSpacing={letterSpacing}
        border={border}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};

const Button = styled.button`
  cursor: pointer;
  border: ${(props) => props.border || "none"};
  border-radius: 10px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: ${(props) => props.fontWeight || "700"};

  letter-spacing: ${(props) => props.letterSpacing || "0.1em"};
  white-space: nowrap;
  color: ${(props) => props.color || "white"};
  ${(props) => {
    const selected = props.theme.rgbColors[props.background];
    return css`
      background: ${selected};
      &:hover {
        background: ${lighten(0.1, selected)};
      }
      &:active {
        background: ${darken(0.1, selected)};
      }
    `;
  }}
`;

Button.defaultProps = {
  background: "default",
};

export default MyButton;
