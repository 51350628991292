import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  MyBasicList,
  MyListContents,
  MyListStatusContents,
} from "../styled/ListStyle";
import moment from "moment";
// import MyButton from "./MyButton";
import { MdModeEditOutline } from "react-icons/md";
import api from "../redux/api";
import Swal from "sweetalert2";
import { Button } from "@mui/material";

const BasicList = ({
  date,
  name,
  id,

  //commute
  startTime,
  endTime,
  type,
  teamName,
  etc,

  // editList,
  setSelected,
  color,
  bgColor,
  selected,

  //forum
  forumtitle,
  team,
  writer,

  isRead,

  //payment
  title,
  payType,
  status,

  // dayoff
  dayOffType,
  hours,
  dayOffStatus,
  deductionDay,
  timeOffType,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const utcDate = new Date(date);
  const utcStartTime = new Date(startTime);
  const utcEndTime = new Date(endTime);
  const myDate = moment(utcDate).format("YYYY/MM/DD");
  const forMobileDate = moment(utcDate).format("YY/MM/DD");
  const forMobileStartTime = moment(utcStartTime).format("YY/MM/DD");
  const forMobileEndTime = moment(utcEndTime).format("YY/MM/DD");
  const myRole = localStorage.getItem("role");
  const [startState, setStartState] = useState("");
  const [endState, setEndState] = useState("");
  const [newEtc, setNewEtc] = useState("");
  const listRef = useRef();

  const [resize, setResize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const goToDetailPage = () => {
    navigate(`/${name}/${id}`);
  };

  const [commuteId, setCommuteId] = useState();

  const editMode = (e) => {
    const id = e.currentTarget.id;

    !selected.includes(id)
      ? setSelected(() => [id])
      : setSelected(selected.filter((button) => button !== id));

    setCommuteId(id);
  };

  const editCancle = (e) => {
    !selected.includes(commuteId)
      ? setSelected(() => [commuteId])
      : setSelected(selected.filter((button) => button !== commuteId));

    window.location.reload();
  };

  const editList = (e) => {
    // console.log(commuteId);

    !selected.includes(commuteId)
      ? setSelected(() => [commuteId])
      : setSelected(selected.filter((button) => button !== commuteId));

    // console.log(id);

    const body = {
      commuteId: commuteId,
      startTime: startState,
      endTime: endState,
      etc: newEtc === "" ? etc : newEtc,
    };

    const currentStartTime = moment(
      `2022-09-01 ${startState === "" ? startTime : startState}`
    );
    const currentEndTime = moment(
      `2022-09-01 ${endState === "" ? endTime : endState}`
    );
    const result = moment
      .duration(currentEndTime.diff(currentStartTime))
      .asMinutes();

    // console.log(result);
    // console.log(currentStartTime);
    if (result <= 0) {
      Swal.fire(
        "알림",
        "출근시간보다 퇴근시간이 더 빠르거나 같습니다. 등록에 실패하였습니다.",
        "error",
        "확인"
      );
      setStartState(startTime);
      setEndState(endTime);
      return;
    }

    // console.log(body);

    selected.includes(commuteId) &&
      api
        .put(`/api/v1/commute`, body)
        .then((res) => {
          // console.log(res.data.data);
          Swal.fire({
            title: "알림",
            text: "수정이 완료되었습니다.",
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        })
        .catch((err) => {
          // console.log("err: ", err?.response?.data?.code);
          Swal.fire({
            title: "알림",
            text: "수정에 실패하였습니다.",
            icon: "error",
            confirmButtonText: "확인",
          });
        });
  };

  useEffect(() => {
    parseInt(listRef?.current?.id) === parseInt(selected && selected[0]) &&
      setStartState(startTime);
    parseInt(listRef?.current?.id) === parseInt(selected && selected[0]) &&
      setEndState(endTime);
  }, [endTime, selected, startTime]);

  return (
    <BasicLists
      onClick={location.pathname === "/commutes" ? null : goToDetailPage}
      style={
        location.pathname === "/commutes"
          ? { cursor: "default" }
          : { cursor: "pointer" }
      }
      value={id}
      id={id}
      ref={listRef}
    >
      {location.pathname === "/newcomer" && (
        <>
          <ListContents contentsWidth={resize >= 995 ? "15%" : "25%"}>
            {resize >= 995 ? myDate : forMobileDate}
          </ListContents>
          <ListContents contentsWidth={resize >= 995 ? "85%" : "75%"}>
            {writer}
          </ListContents>
        </>
      )}
      {location.pathname === "/commutes" &&
        myRole === "ROLE_LV1" &&
        resize >= 995 && (
          <>
            <ListContents contentsWidth={"18%"}>{myDate}</ListContents>
            <ListContents contentsWidth={"18%"}>{startTime}</ListContents>
            <ListContents contentsWidth={"18%"}>{endTime}</ListContents>
            <ListContents contentsWidth={"30%"}>{etc}</ListContents>
            <ListContents contentsWidth={"16%"}>
              <ListStatusContents
                contentsColor={type === "M" ? "#D33751" : "#3752D3"}
              >
                {type === "M" ? "수동" : "자동"}
              </ListStatusContents>
            </ListContents>
          </>
        )}
      {location.pathname === "/commutes" &&
        myRole === "ROLE_LV1" &&
        resize < 995 && (
          <>
            <ListContents contentsWidth={"40%"}>{forMobileDate}</ListContents>
            <ListContents
              contentsWidth={"40%"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div>{startTime}</div>
              <div>{endTime}</div>
            </ListContents>
            <ListContents contentsWidth={"20%"}>
              <ListStatusContents
                contentsColor={type === "M" ? "#D33751" : "#3752D3"}
              >
                {type === "M" ? "수동" : "자동"}
              </ListStatusContents>
            </ListContents>
          </>
        )}
      {location.pathname === "/commutes" &&
        myRole !== "ROLE_LV1" &&
        resize >= 995 && (
          <>
            <ListContents contentsWidth={"14%"}>{myDate}</ListContents>
            <ListContents contentsWidth={"12%"}>{teamName}</ListContents>
            <ListContents contentsWidth={"12%"}>{name}</ListContents>

            {parseInt(listRef?.current?.id) ===
            parseInt(selected && selected[0]) ? (
              <ListContents contentsWidth={"12%"}>
                <ListContentsInput
                  // defaultValue={startTime}
                  // onChange={(e) => setStartTime(e)}
                  value={startState}
                  onChange={(e) => {
                    const regexKor = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g; // 한글
                    const regexEng = /[a-zA-Z]/g; // 영어

                    if (regexKor.test(e?.target?.value)) {
                      setStartState("");
                    } else if (regexEng.test(e?.target?.value)) {
                      setStartState("");
                    } else {
                      setStartState(e.target.value);
                    }
                  }}
                ></ListContentsInput>
              </ListContents>
            ) : (
              <ListContents contentsWidth={"12%"}>
                {startState !== "" ? startState : startTime}
              </ListContents>
            )}

            {parseInt(listRef?.current?.id) ===
            parseInt(selected && selected[0]) ? (
              <ListContents contentsWidth={"12%"}>
                <ListContentsInput
                  // defaultValue={endTime}
                  // onChange={(e) => setEndTime(e)}
                  // onChange={setEndTime}
                  value={endState}
                  onChange={(e) => {
                    const regexKor = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g; // 한글
                    const regexEng = /[a-zA-Z]/g; // 영어

                    if (regexKor.test(e?.target?.value)) {
                      setEndState("");
                    } else if (regexEng.test(e?.target?.value)) {
                      setEndState("");
                    } else {
                      setEndState(e.target.value);
                    }
                  }}
                ></ListContentsInput>
              </ListContents>
            ) : (
              <ListContents contentsWidth={"12%"}>
                {endState !== "" ? endState : endTime}
              </ListContents>
            )}
            {parseInt(listRef?.current?.id) ===
            parseInt(selected && selected[0]) ? (
              <ListContents contentsWidth={"23%"}>
                <ListEtcInput
                  defaultValue={etc}
                  onChange={(e) => setNewEtc(e.target.value)}
                ></ListEtcInput>
              </ListContents>
            ) : (
              <ListContents contentsWidth={"23%"}>
                {newEtc !== "" ? newEtc : etc}
              </ListContents>
            )}

            <ListContents contentsWidth={"8%"}>
              <ListStatusContents
                contentsColor={type === "M" ? "#D33751" : "#3752D3"}
              >
                {type === "M" ? "수동" : "자동"}
              </ListStatusContents>
            </ListContents>
            {myRole === "ROLE_LV2" && (
              <ListContents contentsWidth={"9%"}>
                {parseInt(listRef?.current?.id) ===
                parseInt(selected && selected[0]) ? (
                  <>
                    <Button onClick={editList}>수정</Button>
                    <Button onClick={editCancle}>취소</Button>
                  </>
                ) : (
                  <EditListBtn
                    // onClick={(e) => editList(e)}
                    onClick={(e) => editMode(e)}
                    id={id}
                    color={color}
                    bgColor={bgColor}
                  >
                    <MdModeEditOutline />
                  </EditListBtn>
                )}
              </ListContents>
            )}
          </>
        )}
      {location.pathname === "/commutes" &&
        myRole !== "ROLE_LV1" &&
        resize < 995 && (
          <>
            <ListContents contentsWidth={"25%"}>{forMobileDate}</ListContents>
            <ListContents
              contentsWidth={"25%"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div>{teamName}</div>
              <div>{name}</div>
            </ListContents>

            {parseInt(listRef?.current?.id) ===
            parseInt(selected && selected[0]) ? (
              <ListContents
                contentsWidth={myRole === "ROLE_LV2" ? "25%" : "35%"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <ListContentsInput
                  // defaultValue={startTime}
                  // onChange={(e) => setStartTime(e)}
                  value={startState}
                  onChange={(e) => {
                    const regexKor = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g; // 한글
                    const regexEng = /[a-zA-Z]/g; // 영어

                    if (regexKor.test(e?.target?.value)) {
                      setStartState("");
                    } else if (regexEng.test(e?.target?.value)) {
                      setStartState("");
                    } else {
                      setStartState(e.target.value);
                    }
                  }}
                ></ListContentsInput>
                <ListContentsInput
                  // defaultValue={endTime}
                  // onChange={(e) => setEndTime(e)}
                  value={endState}
                  onChange={(e) => {
                    const regexKor = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g; // 한글
                    const regexEng = /[a-zA-Z]/g; // 영어

                    if (regexKor.test(e?.target?.value)) {
                      setEndState("");
                    } else if (regexEng.test(e?.target?.value)) {
                      setEndState("");
                    } else {
                      setEndState(e.target.value);
                    }
                  }}
                ></ListContentsInput>
              </ListContents>
            ) : (
              <>
                <ListContents
                  contentsWidth={myRole === "ROLE_LV2" ? "25%" : "35%"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <div>{startState !== "" ? startState : startTime}</div>
                  <div>{endState !== "" ? endState : endTime}</div>
                </ListContents>
              </>
            )}

            <ListContents contentsWidth={"15%"}>
              <ListStatusContents
                contentsColor={type === "M" ? "#D33751" : "#3752D3"}
              >
                {type === "M" ? "수동" : "자동"}
              </ListStatusContents>
            </ListContents>
            {myRole === "ROLE_LV2" && (
              <ListContents contentsWidth={"5%"}>
                <EditListBtn
                  onClick={(e) => editList(e)}
                  id={id}
                  color={color}
                  bgColor={bgColor}
                >
                  <MdModeEditOutline />
                </EditListBtn>
              </ListContents>
            )}
          </>
        )}
      {(location.pathname === "/notices" ||
        location.pathname === "/master-notices") && (
        <>
          <ListContents contentsWidth={resize >= 995 ? "15%" : "25%"}>
            {resize >= 995 ? myDate : forMobileDate}
          </ListContents>
          <ListContents contentsWidth={resize >= 995 ? "85%" : "75%"}>
            <span style={{ color: isRead ? "gray" : "black" }}>
              {forumtitle}
            </span>
          </ListContents>
        </>
      )}
      {location.pathname === "/meeting" && (
        <>
          <ListContents contentsWidth={resize >= 995 ? "20%" : "25%"}>
            {resize >= 995 ? myDate : forMobileDate}
          </ListContents>
          <ListContents
            style={{ paddingRight: resize >= 995 ? "0px" : "5px" }}
            contentsWidth={resize >= 995 ? "65%" : "50%"}
          >
            {forumtitle}
          </ListContents>
          <ListContents contentsWidth={resize >= 995 ? "15%" : "25%"}>
            <TeamName>{team}</TeamName>
          </ListContents>
        </>
      )}
      {location.pathname === "/payment" &&
        myRole === "ROLE_LV1" &&
        resize >= 995 && (
          <>
            <ListContents contentsWidth={"25%"}>{myDate}</ListContents>
            <ListContents contentsWidth={"40%"}>{title}</ListContents>
            <ListContents contentsWidth={"15%"}>
              {payType === "R" ? "송금" : "법인카드"}
            </ListContents>
            <ListContents contentsWidth={"20%"}>
              <ListStatusContents
                contentsColor={
                  status === "A"
                    ? "#3752D3"
                    : status === "W"
                    ? "#F79514"
                    : "#D33751"
                }
              >
                {status === "A" ? "승인" : status === "W" ? "대기" : "반려"}
              </ListStatusContents>
            </ListContents>
          </>
        )}
      {location.pathname === "/payment" &&
        myRole === "ROLE_LV1" &&
        resize < 995 && (
          <>
            <ListContents contentsWidth={"25%"}>{myDate}</ListContents>
            <ListContents contentsWidth={"40%"} style={{ paddingRight: "5px" }}>
              {title}
            </ListContents>
            <ListContents contentsWidth={"15%"}>
              {payType === "R" ? "송금" : "법인카드"}
            </ListContents>
            <ListContents contentsWidth={"20%"}>
              <ListStatusContents
                contentsColor={
                  status === "A"
                    ? "#3752D3"
                    : status === "W"
                    ? "#F79514"
                    : "#D33751"
                }
              >
                {status === "A" ? "승인" : status === "W" ? "대기" : "반려"}
              </ListStatusContents>
            </ListContents>
          </>
        )}
      {location.pathname === "/payment" &&
        myRole !== "ROLE_LV1" &&
        resize >= 995 && (
          <>
            <ListContents contentsWidth={"17%"}>{myDate}</ListContents>
            <ListContents contentsWidth={"24%"}>{title}</ListContents>
            <ListContents contentsWidth={"17%"}>{teamName}</ListContents>
            <ListContents contentsWidth={"17%"}>{writer}</ListContents>
            <ListContents contentsWidth={"17%"}>
              {payType === "R" ? "송금" : "법인카드"}
            </ListContents>
            <ListContents contentsWidth={"10%"}>
              <ListStatusContents
                contentsColor={
                  status === "A"
                    ? "#3752D3"
                    : status === "W"
                    ? "#F79514"
                    : "#D33751"
                }
              >
                {status === "A" ? "승인" : status === "W" ? "대기" : "반려"}
              </ListStatusContents>
            </ListContents>
          </>
        )}
      {location.pathname === "/payment" &&
        myRole !== "ROLE_LV1" &&
        resize < 995 && (
          <>
            <ListContents contentsWidth={"20%"}>{forMobileDate}</ListContents>
            <ListContents contentsWidth={"45%"} style={{ paddingRight: "5px" }}>
              {title}
            </ListContents>
            <ListContents
              contentsWidth={"20%"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div>{teamName}</div>
              <div>{writer}</div>
            </ListContents>
            <ListContents contentsWidth={"15%"}>
              <ListStatusContents
                contentsColor={
                  status === "A"
                    ? "#3752D3"
                    : status === "W"
                    ? "#F79514"
                    : "#D33751"
                }
              >
                {status === "A" ? "승인" : status === "W" ? "대기" : "반려"}
              </ListStatusContents>
            </ListContents>
          </>
        )}

      {location.pathname === "/day-off" && resize >= 995 && (
        <>
          <ListContents contentsWidth={"16%"}>{myDate}</ListContents>
          <ListContents contentsWidth={"12%"}>{team}</ListContents>
          <ListContents contentsWidth={"12%"}>{writer}</ListContents>
          <ListContents contentsWidth={"10%"}>
            {dayOffType === "U"
              ? "유급"
              : dayOffType === "M"
              ? "무급"
              : dayOffType === "R"
              ? "포상"
              : "기타"}
          </ListContents>
          <ListContents contentsWidth={"17%"}>{startTime}</ListContents>
          <ListContents contentsWidth={"17%"}>{endTime}</ListContents>
          <ListContents contentsWidth={"12%"}>-{deductionDay}</ListContents>
          <ListContents contentsWidth={"10%"}>
            <ListStatusContents
              contentsColor={dayOffStatus === null ? "#3752D3" : "#D33751"}
            >
              {dayOffStatus === null ? "제출" : "취소"}
            </ListStatusContents>
          </ListContents>

          <ListContents contentsWidth={"12%"}>
            {timeOffType ? (
              <ListStatusContents
                contentsColor={
                  timeOffType === "LC"
                    ? "#00C6CF"
                    : timeOffType === "IM"
                    ? "#009063"
                    : "#8685EF"
                }
              >
                {
                  timeOffType === "LC"
                    ? "늦게출근" // "출근지연"
                    : timeOffType === "IM"
                    ? "외출"
                    : "일찍퇴근" // 조기퇴근
                }
              </ListStatusContents>
            ) : null}
          </ListContents>

          {hours !== null ? (
            <ListContents contentsWidth={"10%"}>
              <DeductionDayStatus>{hours} 시간</DeductionDayStatus>
            </ListContents>
          ) : (
            <ListContents contentsWidth={"10%"}></ListContents>
          )}
        </>
      )}
      {location.pathname === "/day-off" && resize < 995 && (
        <>
          <ListContents contentsWidth={"22%"}>{forMobileDate}</ListContents>
          <ListContents
            contentsWidth={"23%"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div>{team}</div>
            <div>{writer}</div>
          </ListContents>
          <ListContents contentsWidth={"15%"}>
            {dayOffType === "U" ? "유급" : dayOffType === "M" ? "무급" : "기타"}
          </ListContents>
          <ListContents
            contentsWidth={"25%"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div>{forMobileStartTime}</div>
            <div>{forMobileEndTime}</div>
          </ListContents>
          <ListContents contentsWidth={"15%"}>
            <ListStatusContents
              contentsColor={dayOffStatus === null ? "#3752D3" : "#D33751"}
            >
              {dayOffStatus === null ? "제출" : "취소"}
            </ListStatusContents>
          </ListContents>
        </>
      )}
    </BasicLists>
  );
};

const BasicLists = styled.div`
  ${MyBasicList}
`;

const ListContents = styled.div`
  ${MyListContents}
`;

const ListStatusContents = styled.div`
  ${MyListStatusContents}
`;

const TeamName = styled.div`
  display: flex;
  background-color: #3752d3;
  color: white;
  border-radius: 16px;
  padding: 5px 10px;
`;

const DeductionDayStatus = styled.div`
  ${MyListStatusContents}
  color: #3752D3;
  border: 1px solid #3752d3;
`;

const EditListBtn = styled.button`
  border: 1px solid #3752d3;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.bgColor || "transparent"};
  color: ${(props) => props.color || "#3752D3"};

  svg {
    height: 2em;
    width: 2em;
  }

  @media screen and (max-width: 1150px) {
    width: 25px;
    height: 25px;
  }
`;

const ListContentsInput = styled.input`
  ${MyListContents}
  width: 86px;
  background: transparent;
  border: 1px solid #3752d3;
  border-radius: 10px;
  padding: 5px 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-size: 13px;
  margin-left: -11px;

  @media screen and (max-width: 1024px) {
    width: 86px;
  }
`;

const ListEtcInput = styled.input`
  ${MyListContents}
  width: 95%;
  background: transparent;
  border: 1px solid #3752d3;
  border-radius: 10px;
  padding: 5px 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-size: 13px;
  margin-left: -11px;

  @media screen and (max-width: 1024px) {
    width: 86px;
  }
`;

export default BasicList;
