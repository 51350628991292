import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import { useSelector } from "react-redux";
import api from "../../redux/api";
import { useLocation } from "react-router-dom";

const ToastEditor = ({ setContentsHTML, onEdit }) => {
  const editorRef = useRef();
  const noticecontents = useSelector((state) => state.forum.noticecontents);
  const location = useLocation();
  // const [imgURL, setImgURL] = useState('');
  const getContents = () => {
    let myRegExp1 = /<img[^>]+class=[^>]*>/gi;
    let myRegExp2 = /<br[^>]+class=[^>]*>/gi;
    const noimg = editorRef.current
      .getInstance()
      .getHTML()
      .replace(myRegExp1, "");
    setContentsHTML(noimg.replace(myRegExp2, ""));
  };

  useEffect(() => {
    onEdit && editorRef.current.getInstance().setHTML(noticecontents);
  }, [editorRef, noticecontents, onEdit]);

  useEffect(() => {
    const addImage = (blob, callback) => {
      let formData = new FormData();
      formData.append("img", blob);
      api
        .post("/api/v1/img", formData, {
          header: { "content-type": "multipart/form-data" },
        })
        .then((res) => {
          // console.log(res);
          callback(`${res.data.data.url}?w=600&h=600`, "alt text");
        });
    };
    editorRef.current.getInstance().removeHook("addImageBlobHook");
    editorRef.current.getInstance().addHook("addImageBlobHook", addImage);
  }, [editorRef]);

  return (
    <div>
      {location.pathname === "/anonymous/new" ? (
        <Editor
          ref={editorRef}
          placeholder="내용을 입력해주세요."
          previewStyle="vertical"
          height="600px"
          initialEditType="wysiwyg"
          toolbarItems={[]}
          useCommandShortcut={false}
          hideModeSwitch={true}
          onChange={getContents}
        ></Editor>
      ) : (
        <Editor
          ref={editorRef}
          placeholder="내용을 입력해주세요."
          previewStyle="vertical"
          height="435px"
          initialEditType="wysiwyg"
          toolbarItems={[
            // 툴바 옵션 설정
            ["heading", "bold", "italic", "strike"],
            ["hr", "quote"],
            ["ul", "ol", "task", "indent", "outdent"],
            ["image"],
            ["code", "codeblock"],
          ]}
          useCommandShortcut={false}
          hideModeSwitch={true}
          onChange={getContents}
        ></Editor>
      )}
    </div>
  );
};

export default ToastEditor;
