import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startWorkTime: "",
  createDate: "",
  success: false,
  endWorkTime: "",
  selectedDate: "",
  selectedTeam: "",
  selectedName: "",
};

const commutesReducer = createSlice({
  name: "commutes",
  initialState,
  reducers: {
    setMyWorksData: (state, action) => {
      state.endWorkTime = action.payload.endWorkTime;
      state.createDate = action.payload.createDate;
      state.startWorkTime = action.payload.startWorkTime;
    },
    setCommuteDates: (state, action) => {
      state.selectedDate = action.payload.selectedDate;
    },
    resetCommuteDates: (state, action) => {
      state.selectedDate = action.payload;
    },
    setCommuteTeam: (state, action) => {
      state.selectedTeam = action.payload.selectedTeam;
    },
    setCommuteName: (state, action) => {
      state.selectedName = action.payload.selectedName;
    },
  },
});

// export const { getCommuteData } = commutesReducer.actions
export default commutesReducer;
