import { css } from "styled-components";

export const MyEditorTitle = css`
  width: 50%;
  height: 40px;
  border: 1px solid #b2b8c1;
  background-color: transparent;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 1px;
  padding-left: 25px;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 995px) {
    width: 70%;
  }
`;
