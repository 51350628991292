import React from "react";
import Select from "react-select";

const SelectHuman = ({
  text,

  // 직급
  ranks,
  selectedRankId,
  setSelectedRankId,

  //  부서
  workTypes,
  selectedWorkTypeId,
  setSelectedWorkTypeId,

  //  부서
  teams,
  selectedTeamId,
  setSelectedTeamId,
}) => {
  const customStyles = {
    options: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "transparent",
      border: "0",
      height: "100%",
      borderRadius: "10px",
      letterSpacing: "0.1em",
      fontWeight: 500,
      fontSize: "13px",
      borderColor: "hsl(0, 0%, 80%)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "hsl(0, 0%, 80%)",
      },
    }),
  };

  if (ranks) {
    return (
      <Select
        value={selectedRankId}
        onChange={setSelectedRankId}
        options={ranks}
        placeholder={text}
        styles={customStyles}
      />
    );
  }
  if (workTypes) {
    return (
      <Select
        value={selectedWorkTypeId}
        onChange={setSelectedWorkTypeId}
        options={workTypes}
        placeholder={text}
        styles={customStyles}
      />
    );
  }
  if (teams) {
    return (
      <Select
        value={selectedTeamId}
        onChange={setSelectedTeamId}
        options={teams}
        placeholder={text}
        styles={customStyles}
      />
    );
  }
};

export default SelectHuman;
