import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../redux/api";
import forumReducer from "../../redux/reducers/forumReducer";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FaTimes } from "react-icons/fa";
import Errorcode from "../../util/Errorcode";

const FileUpload = ({ marginTop, setLoading }) => {
  const fileInput = useRef();
  const dispatch = useDispatch();
  const allFilteredFileList = useSelector(
    (state) => state.forum.allFilteredFileList
  );
  const filteredFileIdList = useSelector(
    (state) => state.forum.filteredFileIdList
  );
  const files = useSelector((state) => state.forum.files);
  const { id } = useParams();
  const location = useLocation();

  // useEffect(() => {
  //   console.log(location);
  //   console.log("필터된 id list", filteredFileIdList);
  //   console.log("필터된 file list", allFilteredFileList);
  //   console.log(files.files);
  // }, [filteredFileIdList, allFilteredFileList, files.files]);

  useEffect(() => {
    // 파일 설정
    location.pathname === `/notices/edit/${id}` &&
      dispatch(forumReducer.actions.updateFileListFilter(files.files));
    location.pathname === `/master-notices/edit/${id}` &&
      dispatch(forumReducer.actions.updateFileListFilter(files.files));
    location.pathname === `/meeting/edit/${id}` &&
      dispatch(forumReducer.actions.updateFileListFilter(files.files));
  }, []);

  useEffect(() => {
    // 아이디 설정
    const withEditId = allFilteredFileList?.map((item) => item.fileId);
    location.pathname === `/notices/edit/${id}` &&
      dispatch(forumReducer.actions.updateFileIdFilter(withEditId));
    location.pathname === `/master-notices/edit/${id}` &&
      dispatch(forumReducer.actions.updateFileIdFilter(withEditId));
    location.pathname === `/meeting/edit/${id}` &&
      dispatch(forumReducer.actions.updateFileIdFilter(withEditId));
    location.pathname === `/idea` &&
      dispatch(forumReducer.actions.updateFileIdFilter(withEditId));
  }, []);

  const getFile = async (e) => {
    let fileList = e.target.files[0];
    // console.log(fileList);

    const formData = new FormData();
    formData.append("file", fileList);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    setLoading(true);

    try {
      const res = await api.post("/api/v1/files", formData, config);
      dispatch(forumReducer.actions.addFileFilterList(res.data.data));
      dispatch(forumReducer.actions.addFileFilterIdList(res.data.data.fileId));
      Swal.fire({
        title: "알림",
        text: `파일이 등록되었습니다.`,
        icon: "success",
        confirmButtonText: "확인",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Swal.fire({
        title: "알림",
        text: `파일 등록에 실패하였습니다.`,
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  const inputHTML = () => {
    fileInput.current.click();
  };

  const deleteFile = (idx, e) => {
    const fileListId = e.currentTarget.id;
    // console.log(fileListId);

    const filteredList = allFilteredFileList.filter(
      (item) => item.fileId !== parseInt(fileListId)
    );
    const filteredIdList = filteredFileIdList.filter(
      (item) => item !== parseInt(fileListId)
    );
    dispatch(forumReducer.actions.updateFileListFilter(filteredList));
    dispatch(forumReducer.actions.updateFileIdFilter(filteredIdList));

    // console.log(idx);
    // setLoading(true);
    // api
    //   .delete(`/api/v1/files/${fileListId}`)
    //   .then((res) => {
    //     console.log(`${fileListId} DB삭제 완료`, res);
    //     const filteredList = allFilteredFileList.filter(
    //       (item) => item.fileId !== parseInt(fileListId)
    //     );
    //     const filteredIdList = filteredFileIdList.filter(
    //       (item) => item !== parseInt(fileListId)
    //     );
    //     dispatch(forumReducer.actions.updateFileListFilter(filteredList));
    //     dispatch(forumReducer.actions.updateFileIdFilter(filteredIdList));
    //     setLoading(false);
    // Swal.fire({
    //   title: "알림",
    //   text: `파일이 삭제되었습니다.`,
    //   icon: "success",
    //   confirmButtonText: "확인",
    // });
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     Swal.fire({
    //       title: "알림",
    //       text: `파일이 삭제에 실패하였습니다.`,
    //       icon: "error",
    //       confirmButtonText: "확인",
    //     });
    //     Errorcode({ code: err?.response?.data?.code });
    //   });
  };

  return (
    <FileBox>
      <FileSection marginTop={marginTop}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <span>파일첨부</span> */}
          <button onClick={inputHTML} style={{ cursor: "pointer" }}>
            파일첨부
          </button>
        </div>

        <div>
          <input
            style={{ display: "none" }}
            ref={fileInput}
            type={"file"}
            onChange={getFile}
            accept={
              "image/*, application/pdf, application/msword, .pptx, .hwp, application/vnd.ms-powerpoint, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
          />
        </div>
      </FileSection>
      {location.pathname === "/idea" ? (
        <FileListSectionIdea>
          {allFilteredFileList?.map((item, idx) => (
            <FileList key={item.fileId}>
              <span>{item.originFileName}</span>
              <div>
                <span>
                  {item.size > 100000
                    ? `${Math.round((item.size / 1000000) * 100) / 100}mb`
                    : `${Math.round((item.size / 1000) * 100) / 100}kb`}
                </span>
                <XButton id={item.fileId} onClick={(e) => deleteFile(idx, e)}>
                  <FaTimes color={"white"} />
                </XButton>
              </div>
            </FileList>
          ))}
        </FileListSectionIdea>
      ) : (
        <FileListSection>
          {allFilteredFileList?.map((item, idx) => (
            <FileList key={item.fileId}>
              <span>{item.originFileName}</span>
              <div>
                <span>
                  {item.size > 100000
                    ? `${Math.round((item.size / 1000000) * 100) / 100}mb`
                    : `${Math.round((item.size / 1000) * 100) / 100}kb`}
                </span>
                <XButton id={item.fileId} onClick={(e) => deleteFile(idx, e)}>
                  <FaTimes color={"white"} />
                </XButton>
              </div>
            </FileList>
          ))}
        </FileListSection>
      )}
    </FileBox>
  );
};

const FileBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  min-height: 100%;
  // background: pink;
`;

const FileSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
  height: 45px;
  margin-top: ${(props) => props.marginTop};

  div {
    span {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1em;
      margin-right: 20px;
    }
    button {
      background: #3752d3;
      border-radius: 10px;
      width: 65px;
      height: 28px;
      color: white;
      font-weight: 500;
      font-size: 11px;
      letter-spacing: 0.1em;
      border: none;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
  }
`;

const FileListSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70%;
`;

const FileListSectionIdea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  overflow-y: scroll;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: #232323;

  div {
    display: flex;
  }
`;

const XButton = styled.div`
  width: 20px;
  height: 20px;
  background: #d33751;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
`;

export default FileUpload;
