import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ForumLayout from "../../../components/forum/ForumLayout";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Meeting = () => {
  const dispatch = useDispatch();
  const [contentList, setContentList] = useState([]);
  const [noticeTotalPage, setNoticeTotalPage] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);
  // const contentLists = useSelector((state) => state.forum.contentList);
  const teamValue = useSelector((state) => state.select.teamValue);

  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat =
    moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");

  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    accessToken === null && navigate("/login");
    // console.log(startDate);
    // console.log(endDate);
    const teamQuery =
      teamValue === undefined
        ? ""
        : teamValue === 0
        ? ""
        : `&team=${teamValue}`;
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    // ResetPage(dateQuery, setPage, 0);
    // ResetPage(teamQuery, setPage, 0);

    api
      .get(`/api/v1/meeting?page=${page}&size=9${dateQuery}${teamQuery}`)
      .then((res) => {
        setNoticeTotalPage(res.data.data.totalPages);
        setContentList(res.data.data.content);
        setTotalElements(res.data.data.totalElements);
        setSize(res.data.data.size);

        dispatch(
          forumReducer.actions.setAllList({
            contentList: contentList,
          })
        );
      });
  }, [page, teamValue, startDate, endDate]);

  return (
    <ForumLayout
      subtitle={"MEETING"}
      title={"회의록"}
      buttonboolean={false}
      Lists={contentList}
      name={"meeting"}
      page={page + 1}
      totalPages={noticeTotalPage}
      totalElements={totalElements}
      size={size}
      setPage={setPage}
      littleTitle={"회의록 항목"}
    ></ForumLayout>
  );
};

export default Meeting;
