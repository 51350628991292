// 사내 공지사항

import { Card, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../redux/api";
import {
  DashboardContent,
  DashboardDate,
  DashboardListContainer,
  DashboardListContent,
  DashboardOverviewButton,
  TitleContainer,
} from "./styled";

const Notice = ({ role }) => {
  let navigate = useNavigate();

  const onClickOverview = useCallback(() => {
    navigate(`/notices`);
  });

  const [contentData, setContentData] = useState([]);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    if (role === "ROLE_LV1") {
      api.get(`/api/v1/notices?page=0&size=4`).then((res) => {
        setContentData(res?.data?.data?.content);
      });
    } else if (role === "ROLE_LV4") {
      api.get(`/api/v1/notices?page=0&size=4`).then((res) => {
        setContentData(res?.data?.data?.content);
      });
    } else {
      api.get(`/api/v1/notices?page=0&size=2`).then((res) => {
        setContentData(res?.data?.data?.content);
      });
    }
  }, []);

  const sliceContent = contentData.slice(0, 5);

  return (
    <Card
      sx={{
        borderRadius: "10px",
        // minHeight: "100%",
        p: 2,
      }}
    >
      <TitleContainer>
        <MainTitle>NOTICE</MainTitle>
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          사내 공지사항
        </span>
      </TitleContainer>

      <DashboardListContainer>
        {mdUp
          ? contentData?.map((item) => (
              <DashboardListContent
                key={item?.id}
                onClick={() => {
                  navigate(`/notices/${item?.id}`);
                }}
              >
                <DashboardDate>
                  {item?.createDate?.substr(2, 9).replace(/-/g, ".")}
                </DashboardDate>
                {mdUp ? (
                  <DashboardContent>
                    {item?.title?.length >= 18
                      ? item?.title.substr(0, 15) + "..."
                      : item?.title}
                  </DashboardContent>
                ) : (
                  <DashboardContent>
                    {item?.title?.length >= 10
                      ? item?.title.substr(0, 7) + "..."
                      : item?.title}
                  </DashboardContent>
                )}
                <div style={{ color: "#4285F4", paddingRight: "5px" }}>
                  &gt;
                </div>
              </DashboardListContent>
            ))
          : sliceContent?.map((item) => (
              <DashboardListContent
                key={item?.id}
                onClick={() => {
                  navigate(`/notices/${item?.id}`);
                }}
              >
                <DashboardDate>
                  {item?.createDate?.substr(2, 9).replace(/-/g, ".")}
                </DashboardDate>
                {mdUp ? (
                  <DashboardContent>{item?.title}</DashboardContent>
                ) : (
                  <DashboardContent>
                    {item?.title?.length >= 10
                      ? item?.title.substr(0, 7) + "..."
                      : item?.title}
                  </DashboardContent>
                )}
                <div style={{ color: "#4285F4", paddingRight: "5px" }}>
                  &gt;
                </div>
              </DashboardListContent>
            ))}
        <DashboardOverviewButton onClick={onClickOverview}>
          공지사항 전체보기
        </DashboardOverviewButton>
      </DashboardListContainer>
    </Card>
  );
};

//
const MainTitle = styled.span`
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
`;

export default Notice;
