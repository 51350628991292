import React from "react";
import styled from "styled-components";
import moment from "moment";

const DetailDateAndTitle = ({ date, title, width }) => {
  // const createDate = date?.replace(/-/g, "/");
  // const utcDate = new Date(createDate);
  // const myDate = moment(utcDate).format("YY.MM.DD");

  const myDate =
    date?.substr(2, 2) + "." + date?.substr(5, 2) + "." + date?.substr(8, 2);

  return (
    <DetailDateAndTitles width={width}>
      <div>
        <span>{myDate}</span>
      </div>
      <Title>{title}</Title>
    </DetailDateAndTitles>
  );
};

const DetailDateAndTitles = styled.div`
  display: flex;
  // width: 100%;
  width: ${(props) => props.width || "100%"};
  height: 35px;
  margin-top: 15px;

  div {
    color: #5886bd;
    font-weight: 700;
    background: #c9e4ff;
    border-radius: 15px;
    letter-spacing: 0.1em;
    height: 35px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 995px) {
    height: auto;
  }
`;

const Title = styled.span`
  margin-left: 10px;
  display: flex;
  width: 80%;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  align-items: center;
  letter-spacing: 0.1em;
  overflow-wrap: break-word;

  @media screen and (max-width: 995px) {
    font-size: 18px;
    margin-left: 15px;
  }
`;

export default DetailDateAndTitle;
