import { css } from "styled-components";

export const MyTitleAndSelect = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => props.width || "95%"};
  color: #3b4351;
  height: 60px;
  margin-top: 10px;
  // flex-wrap: wrap;

  span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.1em;
  }
`;

export const MyTitleList = css`
  display: flex;
  width: 100%;
  color: #b2b8c1;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;

  // @media screen and (max-width: 1150px) {
  //   width: 1000px;
  // }
`;

export const MyTitleListSpan = css`
  width: ${(props) => props.spanWidth};
`;

export const MyBasicList = css`
  display: flex;
  font-size: 13px;
  color: #232323;
  font-weight: 700;
  align-items: center;
  width: 100%;
  height: 60px;
  // background: lightblue;
  border-top: 1px solid #b2b8c1;

  @media screen and (max-width: 455px) {
    font-size: 10.5px;
  }
`;

export const MyListContents = css`
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  width: ${(props) => props.contentsWidth};
`;

export const MyListStatusContents = css`
  text-align: center;
  padding: 5px 10px;

  background: ${(props) => props.contentsColor};
  border-radius: 16px;
  // width: 40px;
  color: #ffffff;
`;

export const MyPaymentContents = css`
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  width: ${(props) => props.contentsWidth};
`;
