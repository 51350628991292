import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ranks: [],
  teams: [],
  workTypes: [],
};

const humanReducer = createSlice({
  name: "human",
  initialState,
  reducers: {
    setUserInfoList: (state, action) => {
      state.ranks = action.payload.ranks;
      state.teams = action.payload.teams;
      state.workTypes = action.payload.workTypes;
    },
  },
});

export default humanReducer;
