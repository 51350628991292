import {
  FaRegCalendarCheck,
  FaRegListAlt,
  FaRegUserCircle,
  FaRegCreditCard,
  FaRegIdCard,
  FaChevronDown,
  FaChevronLeft,
} from "react-icons/fa";

export const dummyDataLevel1 = [
  {
    id: 1,
    directory: "T&A",
    title: "근태관리",
    subtitle: [
      { id: 0, name: "commutes", detailtitle: "출퇴근" },
      { id: 1, name: "day-off", detailtitle: "휴가" },
    ],
    icon: <FaRegCalendarCheck />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 2,
    directory: "APPROVAL",
    title: "전자결재",
    subtitle: [{ id: 0, name: "payment", detailtitle: "지출결의" }],
    icon: <FaRegCreditCard />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 3,
    directory: "NOTICE",
    title: "게시판",
    subtitle: [
      { id: 0, name: "master-notices", detailtitle: "대표이사 공지사항" },
      { id: 1, name: "notices", detailtitle: "사내 공지사항" },
      { id: 2, name: "idea", detailtitle: "아이디어 게시판" },
      { id: 3, name: "meeting", detailtitle: "회의록" },
      { id: 4, name: "anonymous", detailtitle: "익명게시판" },
    ],
    icon: <FaRegListAlt />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 4,
    directory: "INFORMATION",
    title: "내정보",
    subtitle: [{ id: 0, name: "myinfo", detailtitle: "내정보" }],
    icon: <FaRegUserCircle />,
    icon2: <FaChevronLeft />,
  },
];

export const dummyDataLevel2 = [
  {
    id: 1,
    directory: "T&A",
    title: `근태관리`,
    subtitle: [
      { id: 0, name: "commutes", detailtitle: "출퇴근" },
      { id: 1, name: "day-off", detailtitle: "휴가" },
    ],
    icon: <FaRegCalendarCheck />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 2,
    directory: "APPROVAL",
    title: "전자결재",
    subtitle: [{ id: 0, name: "payment", detailtitle: "지출결의" }],
    icon: <FaRegCreditCard />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 3,
    directory: "NOTICE",
    title: "게시판",
    subtitle: [
      { id: 0, name: "master-notices", detailtitle: "대표이사 공지사항" },
      { id: 1, name: "notices", detailtitle: "사내 공지사항" },
      { id: 2, name: "idea", detailtitle: "아이디어 게시판" },
      { id: 3, name: "meeting", detailtitle: "회의록" },
      { id: 4, name: "anonymous", detailtitle: "익명게시판" },
    ],
    icon: <FaRegListAlt />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 4,
    directory: "INFORMATION",
    title: "내정보",
    subtitle: [{ id: 0, name: "myinfo", detailtitle: "내정보" }],
    icon: <FaRegUserCircle />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 5,
    directory: "P&M",
    title: "인사관리",
    subtitle: [
      { id: 0, name: "newcomer", detailtitle: "신규입사" },
      { id: 1, name: "department", detailtitle: "부서관리" },
    ],
    icon: <FaRegIdCard />,
    icon2: <FaChevronLeft />,
  },
];

export const dummyDataLevel3 = [
  {
    id: 1,
    directory: "T&A",
    title: "근태관리",
    subtitle: [
      { id: 0, name: "commutes", detailtitle: "출퇴근" },
      { id: 1, name: "day-off", detailtitle: "휴가" },
    ],
    icon: <FaRegCalendarCheck />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 2,
    directory: "APPROVAL",
    title: "전자결재",
    subtitle: [{ id: 0, name: "payment", detailtitle: "지출결의" }],
    icon: <FaRegCreditCard />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 3,
    directory: "NOTICE",
    title: "게시판",
    subtitle: [
      { id: 0, name: "master-notices", detailtitle: "대표이사 공지사항" },
      { id: 1, name: "notices", detailtitle: "사내 공지사항" },
      { id: 2, name: "idea", detailtitle: "아이디어 게시판" },
      { id: 3, name: "meeting", detailtitle: "회의록" },
      { id: 4, name: "anonymous", detailtitle: "익명게시판" },
    ],
    icon: <FaRegListAlt />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 4,
    directory: "INFORMATION",
    title: "내정보",
    subtitle: [{ id: 0, name: "myinfo", detailtitle: "내정보" }],
    icon: <FaRegUserCircle />,
    icon2: <FaChevronLeft />,
  },
];

export const dummyDataLevel4 = [
  {
    id: 1,
    directory: "T&A",
    title: `근태관리`,
    subtitle: [
      { id: 0, name: "commutes", detailtitle: "출퇴근" },
      { id: 1, name: "day-off", detailtitle: "휴가" },
    ],
    icon: <FaRegCalendarCheck />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 2,
    directory: "NOTICE",
    title: "게시판",
    subtitle: [
      { id: 0, name: "master-notices", detailtitle: "대표이사 공지사항" },
      { id: 1, name: "notices", detailtitle: "사내 공지사항" },
      { id: 2, name: "idea", detailtitle: "아이디어 게시판" },
      { id: 3, name: "meeting", detailtitle: "회의록" },
      { id: 4, name: "anonymous", detailtitle: "익명게시판" },
    ],
    icon: <FaRegListAlt />,
    icon2: <FaChevronLeft />,
  },
  {
    id: 3,
    directory: "INFORMATION",
    title: "내정보",
    subtitle: [{ id: 0, name: "myinfo", detailtitle: "내정보" }],
    icon: <FaRegUserCircle />,
    icon2: <FaChevronLeft />,
  },
];
