import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";

const Pagination2 = ({ page, setPage, totalElements, size }) => {
  const handlePageChange = (page) => {
    setPage(page - 1);
  };
  const widthRef = useRef();
  const [halfWidth, setHalfWidth] = useState(0);

  useEffect(() => {
    // console.log(totalElements);
    // console.log(width);
    setHalfWidth(widthRef.current.offsetWidth / 2);
    // console.log(halfWidth);
  }, [totalElements, halfWidth]);

  return (
    <PaginationWrap ref={widthRef} margin={`-${halfWidth}`}>
      <PaginationBox>
        <Pagination
          activePage={page}
          itemsCountPerPage={size}
          totalItemsCount={totalElements === 0 ? 1 : totalElements}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassFirst={"ltgt-items"}
          itemClassPrev={"ltgt-items"}
          itemClassNext={"ltgt-items"}
          itemClassLast={"ltgt-items"}
          firstPageText={<BsChevronDoubleLeft />}
          prevPageText={<BsChevronLeft />}
          nextPageText={<BsChevronRight />}
          lastPageText={<BsChevronDoubleRight />}
        ></Pagination>
      </PaginationBox>
    </PaginationWrap>
  );
};

const PaginationWrap = styled.div`
  height: 40px;
  background: #f3f8ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  margin-left: ${(props) => props.margin}px;
`;

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination .ltgt-items {
    background-color: #e9f0f8;
    a {
      color: #232323;
      font-size: 15px;
      margin-top: 3px;
    }
  }
  ul.pagination li {
    width: 25px;
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: white;
    font-size: 14px;
    font-weight: 700;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #666666;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #3752d3;
  }
  // ul.pagination li a:hover,
  // ul.pagination li a.active { color: #3752D3; }
`;

export default Pagination2;
