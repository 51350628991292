import React, { useEffect, useState } from "react";
import Pagination2 from "../../components/paging/Pagination2";
import styled from "styled-components";
import Title from "../../components/Title";
import {
  MyContainerWrap,
  MyContentContainer,
  MyPaginationContainer,
} from "../../styled/Container";
import api from "../../redux/api";
import {
  MyTitleAndSelect,
  MyTitleList,
  MyTitleListSpan,
} from "../../styled/ListStyle";
import { MyMainForumContainer } from "../../styled/Forum";
import BasicList from "../../components/BasicList";
import { useNavigate } from "react-router-dom";
import Blank from "../../components/Blank";
import { Box, Card, Grid } from "@mui/material";

const NewComer = () => {
  const navigate = useNavigate();
  const [newComerList, setNewComerList] = useState([]);
  const myRole = localStorage.getItem("role");

  useEffect(() => {
    myRole === null && navigate("/login");
    myRole !== "ROLE_LV2" && navigate("/home");
    api.get(`/api/v1/users?status=G`).then((res) => {
      // console.log(res);
      setNewComerList(res.data.list);
    });
    // console.log(newComerList);
  }, []);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      // console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px" }}
    >
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <Title subtitle={"P&M"} title={"신규입사"} />
            <ContentContainer>
              {newComerList.length <= 0 ? (
                <Blank text={"신규 입사자가 없습니다."} />
              ) : (
                <ContainerWrap
                  background={"#F3F8FF"}
                  boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
                >
                  <TitleAndSelect>
                    <span>신규입사자 항목</span>
                  </TitleAndSelect>

                  <ForumContainer>
                    <TitleList>
                      <TitleListSpan spanWidth={resize >= 995 ? "15%" : "25%"}>
                        작성일자
                      </TitleListSpan>
                      <TitleListSpan spanWidth={resize >= 995 ? "85%" : "75%"}>
                        이름
                      </TitleListSpan>
                    </TitleList>
                    {newComerList ? (
                      newComerList.map((item) => (
                        <BasicList
                          key={item.id}
                          id={item.id}
                          date={item.createDate}
                          name={"newcomer"}
                          writer={item.name}
                        />
                      ))
                    ) : (
                      <div>신규입사자가 없습니다.</div>
                    )}
                  </ForumContainer>
                </ContainerWrap>
              )}
              <PaginationContainer>
                <Pagination2
                  page={1}
                  totalPages={1}
                  setPage={1}
                  totalElements={newComerList.length}
                  size={9}
                />
              </PaginationContainer>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;
const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
`;

const TitleList = styled.div`
  ${MyTitleList}
`;

const TitleListSpan = styled.div`
  ${MyTitleListSpan}
`;

const ForumContainer = styled.div`
  ${MyMainForumContainer}
`;

export default NewComer;
