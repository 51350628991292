import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import styled from "styled-components";
import moment from "moment";
import { useDispatch } from "react-redux";
import commutesReducer from "../../../redux/reducers/commutesReducer";

const CommuteModalCalendar = ({ myDate }) => {
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);

  const selectedDate = new Date(date);
  const forApiSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
  const forRenderingSelectedDate = moment(selectedDate).format("YY/MM/DD");
  const dispatch = useDispatch();

  //   const utcDate = new Date();

  // useEffect(() => {
  //   console.log(open);
  //   console.log(myDate);
  //   console.log(forApiSelectedDate);
  //   console.log(forRenderingSelectedDate);
  // }, [date, forApiSelectedDate, forRenderingSelectedDate, myDate, open]);

  const showCalander = () => {
    setOpen(!open);
  };

  const dateHandler = () => {
    setOpen(!open);
    dispatch(
      commutesReducer.actions.setCommuteDates({
        selectedDate: forApiSelectedDate,
      })
    );
  };

  const currentDate = new Date();

  return (
    <SelectButtonWrap>
      <SelectDiv>
        {date !== null ? (
          <SelectText>{forRenderingSelectedDate}</SelectText>
        ) : (
          <SelectText>{myDate && myDate}</SelectText>
        )}
        <IconDiv onClick={showCalander}>
          <div>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
        </IconDiv>
      </SelectDiv>

      {open && (
        <CalendarDiv>
          <Calendar
            onChange={(item) => setDate(item)}
            date={date}
            maxDate={currentDate}
          />
          <CheckBox>
            <button onClick={dateHandler}>확인</button>
          </CheckBox>
        </CalendarDiv>
      )}
      <div style={{ display: "flex", flexFlow: "column nowrap" }}></div>
    </SelectButtonWrap>
  );
};

const SelectButtonWrap = styled.div`
  width: 115px;
  height: 35px;
  background: transparent;
  border-radius: 10px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 13px;
  outline: 0 !important;
  display: flex;
  justify-content: space-between;
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  position: relative;
`;

const CalendarDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: -100%;
  margin-top: 0.5px;
  background: white;
  height: 350px;
  z-index: 200;
  // border-right: 1px solid black;
  // border-left: 1px solid black;
  // border-bottom: 1px solid black;
`;

const CheckBox = styled.div`
  width: 100%;
  height: 46.4px;

  display: flex;
  align-items: center;
  justify-content: end;
  // border-top: 1px solid black;

  button {
    border: none;
    background: #4285f4;
    border-radius: 5px;
    height: 30px;
    color: white;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-right: 10px;
    cursor: pointer;
  }
`;

const SelectDiv = styled.div`
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;

const SelectText = styled.div`
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 12px;
`;

const IconDiv = styled.div`
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
  box-sizing: border-box;

  div {
    color: hsl(0, 0%, 80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      color: hsl(0, 0%, 40%);
    }

    svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
      vertical-align: middle;

      &:after,
      &:before {
        box-sizing: border-box;
      }
    }
  }
`;

export default CommuteModalCalendar;
