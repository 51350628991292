import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamValue: 0,
  nameValue: "",
  autoValue: "",
  startDate: "",
  endDate: "",

  // 팀 이름
  teamNameValue: "",

  // 지출결의
  payType: "",
  payStatus: "",
};

const selectReducer = createSlice({
  name: "select",
  initialState,
  reducers: {
    setTeamValue: (state, action) => {
      state.teamValue = action.payload.teamValue;
    },
    setNameValue: (state, action) => {
      state.nameValue = action.payload.nameValue;
    },
    setAutoValue: (state, action) => {
      state.autoValue = action.payload.autoValue;
    },
    setDates: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    // 팀이름
    setTeamNameValue: (state, action) => {
      state.teamNameValue = action.payload.teamNameValue;
    },
    // 지출 결의
    setPayType: (state, action) => {
      state.payType = action.payload.payType;
    },
    setPayStatus: (state, action) => {
      state.payStatus = action.payload.payStatus;
    },
  },
});

export default selectReducer;
