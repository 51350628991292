// Library
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Components
import BasicList from "../../components/BasicList";
import Blank from "../../components/Blank";
import Buttons from "../../components/Buttons";
import FilterCalander from "../../components/FilterCalander";
import Pagination2 from "../../components/paging/Pagination2";
import Title from "../../components/Title";

import { MyMainForumContainer } from "../../styled/Forum";
import {
  MyBasicList,
  MyListContents,
  MyListStatusContents,
  MyTitleAndSelect,
  MyTitleList,
  MyTitleListSpan,
} from "../../styled/ListStyle";

// util
// import { ResetPage } from "../../hooks/useResetPage";
import api from "../../redux/api";
import {
  MyContentContainer,
  MyContainerWrap,
  MyPaginationContainer,
} from "../../styled/Container";
import FilterPayType from "../../components/payment/FilterPayType";
import FilterPayStatus from "../../components/payment/FilterPayStatus";
import FilterTeamName from "../../components/payment/FilterTeamName";
import FilterUserName from "../../components/payment/FilterUserName";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../../components/MyButton";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";

const Payment = () => {
  const navigate = useNavigate();
  const RegisterButton = useCallback(() => {
    navigate("/payment/register");
  });
  // const goToDetailPage = useCallback((item) => {
  //   navigate(`/payment/${item?.id}`);
  // }, []);

  const myRole = localStorage.getItem("role");

  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);
  const [paymentData, setPaymentData] = useState({});
  const [contentData, setContentData] = useState([]);

  const [teamList, setTeamList] = useState([]);
  const [nameList, setNameList] = useState([]);

  const defaultValue = [{ value: 0, label: "전체보기" }];
  const payTypeList = [
    { value: "0", label: "전체보기" },
    { value: "1", label: "송금" },
    { value: "2", label: "법인카드" },
  ];
  const payStatusList = [
    { value: "0", label: "전체보기" },
    { value: "1", label: "승인" },
    { value: "2", label: "대기" },
    { value: "3", label: "반려" },
  ];

  // 날짜
  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat =
    moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");

  // reducer
  const teamValue = useSelector((state) => state.select.teamValue);
  const teamNameValue = useSelector((state) => state.select.teamNameValue);
  const nameValue = useSelector((state) => state.select.nameValue);
  const payType = useSelector((state) => state.select.payType);
  const payStatus = useSelector((state) => state.select.payStatus);

  // 리벨3에서 사용하는 체크박스
  const [check, setCheck] = useState([]);

  const [totalCheck, setTotalCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [waitModal, setWaitModal] = useState(false);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  // TODO: 부서, 이름 리스트
  useEffect(() => {
    myRole !== "ROLE_LV1" &&
      api.get(`/api/v1/teams`).then((res) => {
        setTeamList(
          res.data.list
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );
      }) &&
      (((teamValue === undefined || teamValue === 0) &&
        api.get(`/api/v1/users?status=Y`).then((res) => {
          setNameList(
            res?.data?.list
              ?.map((item) => [{ value: item.name, label: item.name }])
              .map((item) => item[0])
          );
        })) ||
        (teamValue !== undefined &&
          teamValue !== 0 &&
          teamValue !== 1 &&
          api.get(`/api/v1/teams/${teamValue}`).then((res) => {
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.name, label: item.name }])
                .map((item) => item[0])
            );
          })) ||
        (teamValue === 1 &&
          api.get(`/api/v1/teams/leaves`).then((res) => {
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.name, label: item.name }])
                .map((item) => item[0])
            );
          })));
  }, [teamValue]);

  useEffect(() => {
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    const teamQuery =
      teamNameValue === undefined
        ? ""
        : teamNameValue === "전체보기"
        ? ""
        : `&teamName=${teamNameValue}`;

    const NameQuery =
      nameValue === undefined
        ? ""
        : nameValue === 0
        ? ""
        : `&name=${nameValue}`;

    const payTypeQuery =
      payType === undefined
        ? ""
        : payType === "전체보기"
        ? ""
        : `&payType=${payType === "송금" ? "R" : "C"}`;

    const StatusQuery =
      payStatus === undefined
        ? ""
        : payStatus === "전체보기"
        ? ""
        : `&status=${
            payStatus === "대기" ? "W" : payStatus === "승인" ? "A" : "R"
          }`;

    myRole !== "ROLE_LV1"
      ? api
          .get(
            `/api/v1/expense?page=${page}&size=9${dateQuery}${payTypeQuery}${StatusQuery}${NameQuery}${teamQuery}`
          )
          .then((res) => {
            setPaymentData(res?.data?.data);
            setContentData(res?.data?.data?.content);
            setTotalElements(res?.data?.data?.totalElements);
            setSize(res?.data?.data?.size);
          })
      : api
          .get(
            `/api/v1/expense?page=${page}&size=9${dateQuery}${payTypeQuery}${StatusQuery}`
          )
          .then((res) => {
            setPaymentData(res?.data?.data);
            setContentData(res?.data?.data?.content);
            setTotalElements(res?.data?.data?.totalElements);
            setSize(res?.data?.data?.size);
          });
  }, [
    page, //
    startDate,
    endDate,
    teamNameValue,
    nameValue,
    payType,
    payStatus,
  ]);

  const onClickReject = async () => {
    setRejectModal(true);
  };

  const onHandleReject = async () => {
    console.log("content: ", contentData);
    const total = contentData.map((data) => {
      return data.id;
    });

    console.log("total: ", total);
    setRejectModal(false);
    setLoading(true);

    let body = {};

    if (totalCheck === true) {
      body = {
        ids: total,
        type: "R",
      };
    } else {
      body = {
        ids: check,
        type: "R",
      };
    }

    api
      .put(`/api/v1/expense`, body)
      .then((res) => {
        Swal.fire(
          "알림",
          "해당 지출 결의 문서를 반려했습니다.",
          "success"
        ).then((res) => {
          setLoading(false);

          window.location.reload();
        });
      })
      .catch((err) => {
        Swal.fire("알림", "반려가 가능한 리스트가 없습니다.", "error");
        setLoading(false);
        setRejectModal(false);
      });
  };

  // 승인
  const onClickApproval = async () => {
    setAcceptModal(true);
  };
  const onHandleApproval = async () => {
    const total = contentData.map((data) => {
      return data.id;
    });
    // const totalOnlyWait = total.filter((word) => word !== null);

    setAcceptModal(false);
    setLoading(true);

    let body = {};

    if (totalCheck === true) {
      body = {
        ids: total,
        type: "A",
      };
    } else {
      body = {
        ids: check,
        type: "A",
      };
    }

    api
      .put(`/api/v1/expense`, body)
      .then((res) => {
        Swal.fire(
          "알림",
          "해당 지출 결의 문서를 승인하였습니다.",
          "success"
        ).then((res) => {
          setLoading(false);
          window.location.reload();
        });
      })
      .catch((err) => {
        Swal.fire("알림", "승인이 가능한 리스트가 없습니다.", "error");
        setLoading(false);
      });
  };

  const onClickWait = async () => {
    setWaitModal(true);
  };

  const onHandleWait = async () => {
    const total = contentData.map((data) => {
      return data.id;
    });

    setWaitModal(false);
    setLoading(true);

    let body = {};

    if (totalCheck === true) {
      body = {
        ids: total,
        type: "W",
      };
    } else {
      body = {
        ids: check,
        type: "W",
      };
    }

    api
      .put(`/api/v1/expense`, body)
      .then((res) => {
        Swal.fire(
          "알림",
          "해당 지출 결의 문서를 대기 상태로 변경하였습니다.",
          "success"
        ).then((res) => {
          setLoading(false);
          window.location.reload();
        });
      })
      .catch((err) => {
        Swal.fire(
          "알림",
          "대기 상태로 변경 가능한 리스트가 없습니다.",
          "error"
        );
        setLoading(false);
      });
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ background: "#fff", borderRadius: "10px" }}
      >
        <Grid item xs={12} lg={12}>
          <Card sx={{ borderRadius: "10px" }}>
            {loading ? <Loading /> : null}
            {rejectModal ? (
              <Modal
                title={"지출 결의 반려"}
                content={"해당 지출 결의 문서를 반려하시겠습니까?"}
                onClick={onHandleReject}
                setOpenModal={setRejectModal}
              />
            ) : null}
            {acceptModal ? (
              <Modal
                title={"지출 결의 승인"}
                content={"해당 지출 결의 문서를 승인하시겠습니까?"}
                onClick={onHandleApproval}
                setOpenModal={setAcceptModal}
              />
            ) : null}
            {waitModal ? (
              <Modal
                title={"지출 결의 대기"}
                content={"해당 지출 결의 문서를 대기하시겠습니까?"}
                onClick={onHandleWait}
                setOpenModal={setWaitModal}
              />
            ) : null}
            <Box p={3}>
              <ContentContainer>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0px 10px",
                    alignItems: "center",
                  }}
                >
                  <Title subtitle={"APPROVAL"} title={"지출결의"} />

                  {myRole === "ROLE_LV3" ? (
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "flex-end",
                        display: resize < 1605 ? "flex" : "none",
                      }}
                    >
                      <MyButton
                        width={"80px"}
                        height={"50px"}
                        text={"승인"}
                        onClick={onClickApproval}
                      />
                      <div style={{ padding: "8px" }} />
                      <MyButton
                        width={"80px"}
                        height={"50px"}
                        text={"반려"}
                        background="danger"
                        onClick={onClickReject}
                      />
                      <div style={{ padding: "8px" }} />
                      <MyButton
                        width={"80px"}
                        height={"50px"}
                        text={"대기"}
                        background="wait"
                        onClick={onClickWait}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: resize < 995 ? "flex" : "none",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MyButton
                        width={"60px"}
                        height={"40px"}
                        text={"+"}
                        onClick={RegisterButton}
                      />
                    </div>
                  )}
                </div>
                <ContainerWrap
                  background={"#F3F8FF"}
                  boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
                >
                  <TitleAndSelect>
                    <span>지출이력</span>
                    <div style={{ display: "flex" }}>
                      {/* level1 */}
                      {myRole === "ROLE_LV1" ? (
                        <>
                          <Filters>
                            <FilterCalander setPage={setPage} />
                            <FilterPayType
                              width={resize < 1520 ? 120 : 180}
                              text={"형식"}
                              myOptions={payTypeList}
                              setPage={setPage}
                            />
                            <FilterPayStatus
                              width={resize < 1520 ? 120 : 180}
                              text={"상태"}
                              myOptions={payStatusList}
                              setPage={setPage}
                            />
                          </Filters>
                        </>
                      ) : myRole === "ROLE_LV2" ? (
                        <Filters>
                          <FilterCalander setPage={setPage} />
                          <FilterTeamName
                            width={resize < 1520 ? 120 : 180}
                            text={"부서"}
                            myOptions={[defaultValue[0], ...teamList]}
                            setPage={setPage}
                          />
                          <FilterUserName
                            width={resize < 1520 ? 120 : 180}
                            text={"이름"}
                            myOptions={[defaultValue[0], ...nameList]}
                            setPage={setPage}
                          />
                          <FilterPayType
                            width={resize < 1520 ? 120 : 180}
                            text={"형식"}
                            myOptions={payTypeList}
                            setPage={setPage}
                          />
                          <FilterPayStatus
                            width={resize < 1520 ? 120 : 180}
                            text={"상태"}
                            myOptions={payStatusList}
                            setPage={setPage}
                          />
                        </Filters>
                      ) : (
                        <Filters>
                          <FilterCalander setPage={setPage} />
                          <FilterTeamName
                            width={resize < 1520 ? 120 : 180}
                            text={"부서"}
                            myOptions={[defaultValue[0], ...teamList]}
                            setPage={setPage}
                          />
                          <FilterUserName
                            width={resize < 1520 ? 120 : 180}
                            text={"이름"}
                            myOptions={[defaultValue[0], ...nameList]}
                            setPage={setPage}
                          />
                          <FilterPayType
                            width={resize < 1520 ? 120 : 180}
                            text={"형식"}
                            myOptions={payTypeList}
                            setPage={setPage}
                          />
                          <FilterPayStatus
                            width={resize < 1520 ? 120 : 180}
                            text={"상태"}
                            myOptions={payStatusList}
                            setPage={setPage}
                          />
                        </Filters>
                      )}
                    </div>
                  </TitleAndSelect>

                  {contentData?.length <= 0 ? (
                    <Blank text={"등록된 문서가 없습니다."} />
                  ) : (
                    <>
                      <ForumContainer>
                        <TitleList>
                          {myRole === "ROLE_LV3" && resize >= 995 && (
                            <>
                              <TitleListSpan spanWidth={"8%"}>
                                <input
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                  }}
                                  type={"checkbox"}
                                  onClick={() => {
                                    setTotalCheck(
                                      contentData?.map(
                                        (item, index) =>
                                          item?.status[index] === "W"
                                      ) && !totalCheck
                                    );
                                  }}
                                />
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"12%"}>
                                작성일자
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"20%"}>
                                제목
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                부서
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                이름
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                형식
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                상태
                              </TitleListSpan>
                            </>
                          )}
                          {myRole === "ROLE_LV1" && resize >= 995 && (
                            <>
                              <TitleListSpan spanWidth={"25%"}>
                                작성일자
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"40%"}>
                                제목
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                형식
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"20%"}>
                                상태
                              </TitleListSpan>
                            </>
                          )}
                          {myRole === "ROLE_LV1" && resize < 995 && (
                            <>
                              <TitleListSpan spanWidth={"25%"}>
                                작성일자
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"40%"}>
                                제목
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                형식
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"20%"}>
                                상태
                              </TitleListSpan>
                            </>
                          )}

                          {myRole !== "ROLE_LV1" && resize < 995 && (
                            <>
                              <TitleListSpan spanWidth={"20%"}>
                                작성일자
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"45%"}>
                                제목
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"20%"}>
                                이름
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"15%"}>
                                상태
                              </TitleListSpan>
                            </>
                          )}

                          {myRole === "ROLE_LV2" && resize > 995 && (
                            <>
                              <TitleListSpan spanWidth={"17%"}>
                                작성일자
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"24%"}>
                                제목
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"17%"}>
                                부서
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"17%"}>
                                이름
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"17%"}>
                                형식
                              </TitleListSpan>
                              <TitleListSpan spanWidth={"10%"}>
                                상태
                              </TitleListSpan>
                            </>
                          )}
                        </TitleList>

                        {/**
                         * @return level에 따라 보여주는 리스트들이 다름
                         * 하아... 저도 이렇게 하고 싶진 않았는데..
                         * 기획도 너무 늦게 나오고 디자인까지 다 작업한 이후에 나오니까
                         * basicList를 건드리게 되면 손이 너무 많이 가여...
                         * 그래서 일단은 임시방편으로 이렇게 작업하게 된 점
                         * 양해 부탁드립니다.
                         */}
                        {myRole === "ROLE_LV3" && resize >= 995
                          ? contentData?.map((item, index) => {
                              return (
                                <Level3BasicLists key={item?.id}>
                                  <Level3ListContents contentsWidth={"8%"}>
                                    <input
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                      }}
                                      type={"checkbox"}
                                      checked={totalCheck ? "checked" : null}
                                      onClick={() => {
                                        if (check.includes(item?.id)) {
                                          let checkout = check.filter(
                                            (e) => e !== item?.id
                                          );
                                          setCheck(checkout);
                                        } else {
                                          setCheck((check) => [
                                            ...check,
                                            item?.id,
                                          ]);
                                        }
                                      }}
                                    />
                                  </Level3ListContents>
                                  <Level3ListContents
                                    contentsWidth={"12%"}
                                    onClick={() => {
                                      navigate(`/payment/${item?.id}`);
                                    }}
                                  >
                                    {item?.createDate}
                                  </Level3ListContents>
                                  <Level3ListContents
                                    contentsWidth={"20%"}
                                    onClick={() => {
                                      navigate(`/payment/${item?.id}`);
                                    }}
                                  >
                                    {item?.title}
                                  </Level3ListContents>
                                  <Level3ListContents
                                    contentsWidth={"15%"}
                                    onClick={() => {
                                      navigate(`/payment/${item?.id}`);
                                    }}
                                  >
                                    {item?.teamName}
                                  </Level3ListContents>
                                  <Level3ListContents
                                    contentsWidth={"15%"}
                                    onClick={() => {
                                      navigate(`/payment/${item?.id}`);
                                    }}
                                  >
                                    {item?.name}
                                  </Level3ListContents>
                                  <Level3ListContents
                                    contentsWidth={"15%"}
                                    onClick={() => {
                                      navigate(`/payment/${item?.id}`);
                                    }}
                                  >
                                    {item?.payType === "R"
                                      ? "송금"
                                      : "법인카드"}
                                  </Level3ListContents>
                                  <Level3ListContents contentsWidth={"15%"}>
                                    <Level3ListStatusContents
                                      contentsColor={
                                        item?.status === "A"
                                          ? "#3752D3"
                                          : item?.status === "W"
                                          ? "#F79514"
                                          : "#D33751"
                                      }
                                    >
                                      {item?.status === "A"
                                        ? "승인"
                                        : item?.status === "W"
                                        ? "대기"
                                        : "반려"}
                                    </Level3ListStatusContents>
                                  </Level3ListContents>
                                </Level3BasicLists>
                              );
                            })
                          : contentData?.map((item) => {
                              return (
                                <BasicList
                                  key={item.id}
                                  role={myRole}
                                  id={item.id}
                                  name={"payment"}
                                  date={item?.createDate}
                                  title={item?.title}
                                  payType={item?.payType}
                                  status={item?.status}
                                  writer={item?.name}
                                  teamName={item?.teamName}
                                />
                              );
                            })}
                      </ForumContainer>
                    </>
                  )}
                </ContainerWrap>
                <PaginationContainer>
                  <Pagination2
                    page={page + 1}
                    totalPages={paymentData.totalPages}
                    setPage={setPage}
                    size={size}
                    totalElements={totalElements}
                  />
                  {myRole === "ROLE_LV3" ? (
                    <div
                      style={{
                        display: resize < 1605 ? "none" : "",
                      }}
                    >
                      <Buttons
                        onClick={onClickApproval}
                        color={"white"}
                        text={"승인"}
                      />
                      <Buttons
                        onClick={onClickReject}
                        color={"white"}
                        background={"danger"}
                        text={"반려"}
                      />
                      <Buttons
                        onClick={onClickWait}
                        color={"white"}
                        background={"wait"}
                        text={"대기"}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: resize < 995 ? "none" : "",
                      }}
                    >
                      <Buttons
                        onClick={RegisterButton}
                        color={"white"}
                        text={"작성"}
                      />
                    </div>
                  )}
                </PaginationContainer>
              </ContentContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
`;

const TitleList = styled.div`
  ${MyTitleList}
`;

const TitleListSpan = styled.div`
  ${MyTitleListSpan}
`;

const ForumContainer = styled.div`
  ${MyMainForumContainer}
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const Filters = styled.div`
  display: flex;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

const Level3BasicLists = styled.div`
  ${MyBasicList}
`;

const Level3ListContents = styled.div`
  ${MyListContents}
  cursor: pointer;
`;

const Level3ListStatusContents = styled.div`
  ${MyListStatusContents}
`;

export default Payment;
