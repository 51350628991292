import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Title from "../../components/Title";

import {
  MyContainerWrap,
  MyContentContainer,
  MyDetailButtonContainerWrap,
} from "../../styled/Container";

import {
  MyPayInfoContainer,
  MyPayInfoDate,
  MyTitleContainer,
} from "../../styled/Payment";

import { TypeArr, CategoryArr } from "../../components/payment/RegisterArray";

import AuthorInfo from "../../components/AuthorInfo";
import PayInfoType from "../../components/PayInfoType";
import RemittanceRegistCard from "../../components/payment/RemittanceRegistCard";
import moment from "moment";
import Swal from "sweetalert2";
import Buttons from "../../components/Buttons";
import api from "../../redux/api";
import CorpRegistCard from "../../components/payment/CorpRegistCard";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../../components/MyButton";
import Errorcode from "../../util/Errorcode";

const Register2 = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate("/payment");
  }, []);

  // TODO: Modal
  const [cancelModal, setCancelModal] = useState(false);
  const [registModal, setRegistModal] = useState(false);

  const [loading, setLoading] = useState(false);

  // TODO: 날짜
  const startDate = new Date();
  const today = moment(startDate).format("YYYY-MM-DD");

  // TODO: title
  const [title, setTitle] = useState("");
  const onChangeTitle = useCallback((e) => {
    setTitle(e.currentTarget.value);
  }, []);

  // TODO: 작성자 정보
  const userName = localStorage.getItem("name");
  const userTeam = localStorage.getItem("team");
  const userPosition = localStorage.getItem("position");

  // 결제 타입
  const [payType, setPayType] = useState(TypeArr[0]);
  const [category, setCategory] = useState(CategoryArr[0]);
  const onClickPayType = useCallback((e) => {
    setPayType(e.target.innerText);
  }, []);
  const onClickCategory = useCallback((e) => {
    setCategory(e.target.innerText);
  }, []);

  // TODO: note
  const [note, setNote] = useState("");

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  // TODO: 등록 부분
  const [resultCard, setResultCard] = useState([]);

  // TODO: 플러스 버튼
  const [remittanceCount, setRemittanceCount] = useState(0);
  const [corpCount, setCorpCount] = useState(0);
  const onClickPulsRegist = useCallback(() => {
    if (payType === "송금") {
      setResultCard((prev) => [
        ...prev,
        {
          usedDate: today,
          amount: 0,
          department: "선택",
          payItem: "선택",
          companyName: "",
          payBank: "은행 선택",
          accountNumber: "",
          accountName: "",
        },
      ]);
      setRemittanceCount((prev) => prev + 1);
    } else {
      setResultCard((prev) => [
        ...prev,
        {
          usedDate: today,
          amount: 0,
          department: "선택",
          payItem: "선택",
          person: 0,
        },
      ]);
      setCorpCount((prev) => prev + 1);
    }
  }, [setResultCard, payType]);

  useEffect(() => {
    if (payType === "법인카드") {
      // console.log("category", category);
      if (category !== "없음") {
        Swal.fire(
          "Info!",
          "법인카드의 유형은 세금계산서나 현금영수증을 선택할 수 없습니다.",
          "info"
        );
        setCategory(CategoryArr[2]);
      }
      setResultCard([]);
    }
  }, [payType, category]);

  // TODO: 취소 버튼 클릭 시
  const onClickCancel = useCallback(() => {
    setCancelModal(true);
  }, [cancelModal]);

  const onClickRegister = useCallback(() => {
    setRegistModal(true);
  }, [registModal]);

  // TODO: 지출 결의 작성
  const onHandleRegister = useCallback(
    (e) => {
      if (note?.length > 200) {
        return Swal.fire({
          title: "알림",
          text: "비고란을 200자 이내로 작성해주세요",
          icon: "error",
          confirmButtonText: "확인",
        });
      }

      let body = {};

      if (payType === "송금") {
        body = {
          title: title,
          note: note,
          payType: "R",
          category:
            category === "세금계산서"
              ? "T"
              : category === "현금영수증"
              ? "C"
              : "N",
          remittanceList: resultCard,
        };
      }

      if (payType === "법인카드") {
        body = {
          title: title,
          note: note,
          payType: "C",
          category: "N",
          corpList: resultCard,
        };
      }

      setCancelModal(false);
      setRegistModal(false);
      setLoading(true);

      api
        .post(`/api/v1/expense`, body)
        .then((res) => {
          setLoading(false);
          Swal.fire({
            title: "알림",
            text: "등록이 완료되었습니다.",
            icon: "success",
            confirmButtonText: "확인",
          });
          navigate("/payment");
        })
        .catch((err) => {
          setLoading(false);

          const errlistLength = err.response.data.list.length;
          Errorcode({
            code: err?.response?.data?.code,
            message: err?.response?.data?.list[errlistLength - 1].message,
          });
        });
    },
    [navigate, payType, title, category, resultCard, registModal, note]
  );

  return (
    <Grid>
      <Card
        sx={{
          borderRadius: "10px",
          width: "100%",
        }}
      >
        {loading ? <Loading /> : null}
        {cancelModal ? (
          <Modal
            title={"지출결의 작성"}
            content={"지출결의 작성을 취소하시겠습니까?"}
            onClick={goBack}
            setOpenModal={setCancelModal}
          />
        ) : null}
        {registModal ? (
          <Modal
            title={"지출결의 작성"}
            content={"지출결의를 등록하시겠습니까?"}
            onClick={onHandleRegister}
            setOpenModal={setRegistModal}
          />
        ) : null}
        <Box p={3}>
          <ContentContainer>
            <Title subtitle={"APPROVAL"} title={"지출결의"} />
            <ContainerWrap
              background={"#F3F8FF"}
              boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
            >
              <TitleContainer>
                <PayInfoDate>{today}</PayInfoDate>
                <input
                  style={{
                    padding: "15px",
                    border: "1px solid #b2b8c1",
                    borderRadius: "10px",
                    width: "600px",
                  }}
                  value={title}
                  onChange={onChangeTitle}
                  placeholder="제목을 입력해주세요."
                />
              </TitleContainer>

              {/* 작성자 정보 */}
              <AuthorInfo
                userName={userName}
                userTeam={userTeam}
                userPosition={userPosition}
              />

              <PayInfoContainer>
                결재정보
                <PayInfoType
                  typeArr={TypeArr}
                  payType={payType}
                  onClickPayType={onClickPayType}
                  categoryArr={CategoryArr}
                  category={category}
                  onClickCategory={onClickCategory}
                />
                <textarea
                  rows={2}
                  style={{
                    marginTop: 20,
                    resize: "none",
                    overflowY: "scroll",
                    height: "120px",
                    padding: 20,
                    border: "1px solid #3752d3",
                    borderRadius: 10,
                  }}
                  placeholder={"비고"}
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
                <ButtonGroup>
                  <PlusButton onClick={onClickPulsRegist}>+</PlusButton>
                </ButtonGroup>
                {payType === "송금"
                  ? [...Array(remittanceCount)].map((n, i) => {
                      return (
                        <RemittanceRegistCard
                          key={i}
                          index={i}
                          payType={payType}
                          setResultCard={setResultCard}
                          resultCard={resultCard}
                        />
                      );
                    })
                  : [...Array(corpCount)].map((n, i) => {
                      return (
                        <CorpRegistCard
                          key={i}
                          index={i}
                          payType={payType}
                          setResultCard={setResultCard}
                          resultCard={resultCard}
                        />
                      );
                    })}
              </PayInfoContainer>
            </ContainerWrap>
            <ButtonGroup width={"95%"} marginTop={"30px"} gap={"10px"}>
              {resize >= 995 ? (
                <>
                  <Buttons
                    onClick={onClickCancel}
                    text={"작성 취소"}
                    color={"white"}
                    background={"danger"}
                  />
                  <Buttons
                    onClick={onClickRegister}
                    text={"작성 완료"}
                    color={"white"}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <MyButton
                    onClick={onClickCancel}
                    text={"작성취소"}
                    background={"danger"}
                    width={"90px"}
                    height={"55px"}
                  />
                  <MyButton
                    onClick={onClickRegister}
                    text={"작성완료"}
                    width={"90px"}
                    height={"55px"}
                  />
                </div>
              )}
            </ButtonGroup>
          </ContentContainer>
        </Box>
      </Card>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

// 지출 결의 등록
const TitleContainer = styled.div`
  ${MyTitleContainer}
  align-items: center;
`;

const PayInfoDate = styled.div`
  ${MyPayInfoDate}
`;

const PayInfoContainer = styled.div`
  ${MyPayInfoContainer}
`;

const ButtonGroup = styled.div`
  ${MyDetailButtonContainerWrap}
`;

const PlusButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: #3752d3;
  border-radius: 10px;
  color: white;
  width: 65px;
  height: 45px;
  margin-bottom: 20px;
`;

export default Register2;
