import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ForumEditor from "../../../components/forum/ForumEditor";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";
import Swal from "sweetalert2";

const NoticeEditMaster = () => {
  const navigate = useNavigate();
  const contentTitle = useSelector((state) => state.forum.contentTitle);
  const contentsHTML = useSelector((state) => state.forum.contentsHTML);
  const noticetitle = useSelector((state) => state.forum.noticetitle);
  const myRole = localStorage.getItem("role");

  const { id } = useParams();
  const dispatch = useDispatch();

  const fileId = useSelector((state) => state.forum.fileId);
  const filteredFileIdList = useSelector(
    (state) => state.forum.filteredFileIdList
  );
  const allFilteredFileList = useSelector(
    (state) => state.forum.allFilteredFileList
  );

  const [openModal, setOpenModal] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(forumReducer.actions.fileIdList(fileId));
  }, []);

  useEffect(() => {
    myRole === null && navigate("/login");
    myRole !== "ROLE_LV4" && navigate("/master-notices");
  }, []);

  // useEffect(() => {
  //   console.log(noticetitle);
  //   console.log(contentTitle);
  // }, [noticetitle, contentTitle]);

  const cancel = () => {
    navigate(`/master-notices`);
    setOpenModal(false);
    dispatch(forumReducer.actions.deleteFileListFilter(allFilteredFileList));
    dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
  };

  const editMasterNoticesContent = () => {
    const body = {
      id,
      title: contentTitle !== "" ? contentTitle : noticetitle,
      contents: contentsHTML,
      files: filteredFileIdList,
    };

    setOpenCheckModal(false);
    setLoading(true);

    if (contentsHTML !== "<p></p>") {
      api.put(`/api/v1/master-notices`, body).then((res) => {
        // console.log(res);
        setLoading(false);
        Swal.fire({
          title: "알림",
          text: "수정이 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
        navigate("/master-notices");
        dispatch(
          forumReducer.actions.deleteFileListFilter(allFilteredFileList)
        );
        dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
      });
    } else {
      setLoading(false);
      Swal.fire({
        title: "알림",
        text: "컨텐츠를 입력해 주세요.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  return (
    <ForumEditor
      subtitle={"MASTER NOTICE"}
      title={"대표이사 공지사항"}
      buttontext={"작성 완료"}
      onEdit={true}
      cancel={cancel}
      onClick={editMasterNoticesContent}
      setOpenModal={setOpenModal}
      openModal={openModal}
      setOpenCheckModal={setOpenCheckModal}
      openCheckModal={openCheckModal}
      setLoading={setLoading}
      loading={loading}
    />
  );
};

export default NoticeEditMaster;
