import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ForumEditor from "../../../components/forum/ForumEditor";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";
import Swal from "sweetalert2";

const MeetingEdit = () => {
  const navigate = useNavigate();
  const contentTitle = useSelector((state) => state.forum.contentTitle);
  const contentsHTML = useSelector((state) => state.forum.contentsHTML);
  const noticetitle = useSelector((state) => state.forum.noticetitle);
  const teamId = useSelector((state) => state.forum.teamId);
  const myRole = localStorage.getItem("role");

  const { id } = useParams();
  const dispatch = useDispatch();

  const fileId = useSelector((state) => state.forum.fileId);
  const filteredFileIdList = useSelector(
    (state) => state.forum.filteredFileIdList
  );
  const allFilteredFileList = useSelector(
    (state) => state.forum.allFilteredFileList
  );
  const accessToken = localStorage.getItem("token");

  const [teams, setTeams] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [teamsId, setTeamsId] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(forumReducer.actions.fileIdList(fileId));
  }, []);

  useEffect(() => {
    accessToken === null && navigate("/login");
    (myRole === "ROLE_LV3" || myRole === "ROLE_LV4") && navigate("/notices");
  }, []);

  const cancel = () => {
    navigate(`/meeting`);
    setOpenModal(false);
    dispatch(forumReducer.actions.deleteFileListFilter(allFilteredFileList));
    dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
  };

  const editNoticesContent = () => {
    const body = {
      id,
      title: contentTitle !== "" ? contentTitle : noticetitle,
      contents: contentsHTML,
      files: filteredFileIdList,
      teamId: teamsId === "1" ? 2 : teamsId,
    };
    setOpenCheckModal(false);
    setLoading(true);
    if (contentsHTML !== "<p></p>") {
      api.put(`/api/v1/meeting`, body).then((res) => {
        // console.log(res);
        setLoading(false);
        Swal.fire({
          title: "알림",
          text: "수정이 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
        navigate("/meeting");

        dispatch(
          forumReducer.actions.deleteFileListFilter(allFilteredFileList)
        );
        dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
      });
    } else {
      setLoading(false);
      Swal.fire({
        title: "알림",
        text: "컨텐츠를 입력해 주세요.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  useEffect(() => {
    setTeamsId(teamId);
  }, []);

  useEffect(() => {
    api.get(`/api/v1/teams`).then((res) => {
      setTeams(res.data.list);
    });
  }, []);

  const onChangeTeams = (e) => {
    setTeamsId(e.target.value);
    // console.log(e.target.value);
  };

  return (
    <ForumEditor
      subtitle={"MEETING"}
      title={"회의록"}
      buttontext={"작성 완료"}
      onEdit={true}
      cancel={cancel}
      onChangeTeams={onChangeTeams}
      onClick={editNoticesContent}
      setOpenModal={setOpenModal}
      openModal={openModal}
      teamsId={teamsId}
      teams={teams}
      setOpenCheckModal={setOpenCheckModal}
      openCheckModal={openCheckModal}
      setLoading={setLoading}
      loading={loading}
    />
  );
};

export default MeetingEdit;
