import React from "react";
import styled from "styled-components";

const Title = ({ subtitle, title }) => {
  return (
    <MyTitle>
      <SubTitle>{subtitle}</SubTitle>
      <MainTitle>{title}</MainTitle>
    </MyTitle>
  );
};

const MyTitle = styled.div`
  // width: 1540px;
  width: 98%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SubTitle = styled.p`
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1c2026;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const MainTitle = styled.p`
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  margin: 0;
  line-height: 32px;
  letter-spacing: 0.12em;
  color: #1c2026;

  @media screen and (max-width: 500px) {
    width: 270px;
    font-size: 20px;
  }
`;

export default Title;
