import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {
  MyContainerWrap,
  MyContentContainer,
  MyDetailContainerWrap,
  MyDetailContent,
  MyDetailInnerContainer,
  MyPaginationContainer,
} from "../../styled/Container";
import Buttons from "../../components/Buttons";
import NewComerInfoList from "../../components/human/NewComerInfoList";
import api from "../../redux/api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import SelectHuman from "../../components/human/SelectHuman";
import MyButton from "../../components/MyButton";
import { Box, Card, Grid } from "@mui/material";
import Errorcode from "../../util/Errorcode";

const RegisterNewComer = () => {
  const { id } = useParams();
  const [newComerDetailInfo, setNewComerDetailInfo] = useState();

  const utcDate = new Date(newComerDetailInfo?.createDate);
  const myDate = moment(utcDate).format("YYYY.MM.DD");
  const Bday = new Date(newComerDetailInfo?.birthDate);
  const myBDate = moment(Bday).format("YYYY.MM.DD");

  const [rankList, setRankList] = useState([]);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const [selectedRankId, setSelectedRankId] = useState("1");
  const [selectedWorkTypeId, setSelectedWorkTypeId] = useState("1");
  const [selectedTeamId, setSelectedTeamId] = useState("1");

  const navigate = useNavigate();

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      // console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    api.get(`/api/v1/users/${id}`).then((res) => {
      setNewComerDetailInfo(res.data.data);
    });
  }, []);

  useEffect(() => {
    api.get(`/api/v1/items`).then((res) => {
      setWorkTypeList(
        res.data.data.workTypes
          ?.map((item) => [{ value: item.id, label: item.name }])
          .map((item) => item[0])
      );

      setTeamList(
        res.data.data.teams
          ?.map((item) => [{ value: item.id, label: item.name }])
          .map((item) => item[0])
      );

      setRankList(
        res.data.data.ranks
          ?.map((item) => [{ value: item.id, label: item.name }])
          .map((item) => item[0])
      );
    });
  }, []);

  useEffect(() => {
    teamList.shift();
  }, [teamList]);

  const cancel = () => {
    navigate("/newcomer");
  };

  const RegisterNew = () => {
    const body = {
      rankId: selectedRankId.value,
      workTypeId: selectedWorkTypeId.value,
      teamId: selectedTeamId.value,
    };
    api
      .put(`/api/v1/users/${id}`, body)
      .then((res) => {
        navigate("/newcomer");
        Swal.fire({
          title: "알림",
          text: "입사 처리가 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
      })
      .catch((err) => {
        // Swal.fire({
        //   title: err?.response?.data?.msg,
        //   text:
        //     err?.response?.data?.code === 102
        //       ? `해당 ID를 갖는 유저가 없습니다.`
        //       : "등록에 실패하였습니다.",
        //   icon: "error",
        //   confirmButtonText: "확인",
        // });
        const errlistLength = err.response.data.list.length;

        Errorcode({
          code: err?.response?.data?.code,
          message: err?.response?.data?.list[errlistLength - 1].message,
        });
      });
  };
  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px" }}
      style={{ position: "relative" }}
    >
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <Title subtitle={"P&M"} title={"신규입사"} />
            <ContentContainer>
              <ContainerWrap background={"white"}>
                <DetailContainerWrap height={"400"}>
                  <DetailInnerContainer>
                    <NewComerTitle>입사자 정보</NewComerTitle>
                    <DetailContent>
                      <NewComerInfoList title={"입사일자"} detail={myDate} />
                      <NewComerInfoList
                        title={"소속"}
                        detail={newComerDetailInfo?.team.name}
                      />
                      <NewComerInfoList
                        title={"직급"}
                        detail={newComerDetailInfo?.rank.name}
                      />
                      <NewComerInfoList title={"생년월일"} detail={myBDate} />
                      {/* <NewComerInfoList
                    title={"성별"}
                    detail={newComerDetailInfo?.gender}
                  /> */}
                      <NewComerInfoList
                        title={"전화번호"}
                        detail={newComerDetailInfo?.phone?.replace(
                          /(\d{3})(\d{4})(\d{4})/,
                          "$1-$2-$3"
                        )}
                      />
                      <NewComerInfoList
                        title={"계좌번호"}
                        detail={`${newComerDetailInfo?.accountNumber?.replace(
                          /(\d{6})(\d{2})(\d{6})/,
                          "$1-$2-$3"
                        )} 국민은행`}
                      />
                    </DetailContent>
                  </DetailInnerContainer>
                </DetailContainerWrap>
                <AddInfo height={"200"}>
                  <AddInfoInner>
                    <NewComerTitle
                      style={{
                        color: "#232323",
                        marginTop: "7px",
                        marginBottom: "5px",
                      }}
                    >
                      입력 정보
                    </NewComerTitle>
                    <SelectionList>
                      <SelectionTitle>직급</SelectionTitle>
                      <SelectBox>
                        <SelectHuman
                          text={"직급을 선택해 주세요"}
                          ranks={rankList}
                          selectedRankId={selectedRankId}
                          setSelectedRankId={setSelectedRankId}
                        />
                      </SelectBox>
                    </SelectionList>
                    <SelectionList>
                      <SelectionTitle>근로형태</SelectionTitle>
                      <SelectBox>
                        <SelectHuman
                          text={"근로 형태를 선택해 주세요"}
                          workTypes={workTypeList}
                          selectedWorkTypeId={selectedWorkTypeId}
                          setSelectedWorkTypeId={setSelectedWorkTypeId}
                        />
                      </SelectBox>
                    </SelectionList>
                    <SelectionList>
                      <SelectionTitle>부서</SelectionTitle>
                      <SelectBox>
                        <SelectHuman
                          text={"부서를 선택해 주세요"}
                          teams={teamList}
                          selectedTeamId={selectedTeamId}
                          setSelectedTeamId={setSelectedTeamId}
                        />
                      </SelectBox>
                    </SelectionList>
                  </AddInfoInner>
                </AddInfo>
              </ContainerWrap>
              <PaginationContainer>
                {resize >= 995 ? (
                  <>
                    <Buttons
                      text={"취소"}
                      color={"white"}
                      background={"danger"}
                      onClick={cancel}
                    />
                    <Buttons
                      text={"등록"}
                      color={"white"}
                      onClick={RegisterNew}
                    />
                  </>
                ) : (
                  <>
                    <MyButton
                      width={"90px"}
                      height={"55px"}
                      background={"danger"}
                      onClick={cancel}
                      text={"취소"}
                      margin={"0px 5px 10px 5px"}
                    />
                    <MyButton
                      width={"90px"}
                      height={"55px"}
                      onClick={RegisterNew}
                      text={"등록"}
                      margin={"0px 5px 10px 5px"}
                    />
                  </>
                )}
              </PaginationContainer>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;
const DetailContainerWrap = styled.div`
  ${MyDetailContainerWrap}
  background: #3B4351;
  margin-top: 21px;
`;
const AddInfo = styled.div`
  ${MyDetailContainerWrap}
  align-items: center;
  background: white;
  border: 1px solid #3752d3;
  margin-top: 13px;
`;
const AddInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 95%;
  height: 100%;
`;

const DetailInnerContainer = styled.div`
  ${MyDetailInnerContainer}
`;

const DetailContent = styled.div`
  ${MyDetailContent}
`;

const NewComerTitle = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 13px;
`;

const SelectionList = styled.div`
  border: 1px solid #3752d3;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
`;

const SelectionTitle = styled.div`
  width: 8%;
  margin-left: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 14px;
  color: #232323;
  display: flex;
  align-items: center;

  @media screen and (max-width: 995px) {
    width: 15%;
  }
`;

const SelectBox = styled.div`
  width: 92%;
  margin-top: 2.45px;
  @media screen and (max-width: 995px) {
    width: 85%;
  }
`;

export default RegisterNewComer;
