import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FaUser } from "react-icons/fa";
import MyButton from "../MyButton";
import { useLocation, useParams } from "react-router-dom";
import api from "../../redux/api";
import Swal from "sweetalert2";
import Errorcode from "../../util/Errorcode";
import { useDispatch, useSelector } from "react-redux";

const ForumCommentLists = ({
  key,
  contents,
  parentId,
  userName,
  createDate,
  childCount,
}) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { id } = useParams();

  const [textLength, setTextLength] = useState(0);
  const [replyContent, setReplyContent] = useState("");
  const boardId = useParams();

  const anonymousToken = localStorage.getItem("anonymousToken");
  const secretToken = useSelector((state) => state.forum.secretToken);

  const [replyList, setReplyList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [childCounts, setChildCounts] = useState();

  useEffect(() => {
    setChildCounts(childCount !== undefined ? childCount : 0);
  }, [childCount]);

  const handleSetValue = (e) => {
    setReplyContent(e.target.value);
  };

  const handleSetTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      let val = e.target.value;
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      e.target.value = val.substring(0, start) + "\t" + val.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      handleSetValue(e);
      return false;
    }
  };

  const handleSetLength = (e) => {
    let test_length = replyContent.length;
    let max_length = 200;

    setTextLength(test_length);
    if (test_length > max_length) {
      Swal.fire({
        title: "Alert",
        text: `${max_length}자 이상 입력할 수 없습니다.`,
        icon: "info",
        confirmButtonText: "확인",
      });
      setReplyContent(replyContent.substring(0, max_length));
      setTextLength(max_length);
    }
  };

  const postReply = useCallback(
    (e) => {
      e.preventDefault();

      // const body = {
      //   boardId: boardId.id,
      //   parentId: parseInt(parentId),
      //   contents: replyContent,
      //   token: anonymousToken,
      // };

      let body = {};

      if (location.pathname === `/anonymous/secret/${id}`) {
        body = {
          boardId: boardId.id,
          parentId: parseInt(parentId),
          contents: replyContent,
          token: secretToken,
        };
      } else {
        body = {
          boardId: boardId.id,
          parentId: parseInt(parentId),
          contents: replyContent,
          token: anonymousToken,
        };
      }

      api
        .post(`/api/v1/comments`, body)
        .then((res) => {
          // console.log(res);
          const replyData = res.data.data;
          setReplyList([...replyList, replyData]);
          setChildCounts(Number(childCounts) + 1);
          setReplyContent("");
          setTextLength(0);
        })
        .catch((err) => {
          // Swal.fire({
          //   title: err?.response?.data?.msg,
          //   text:
          //     err?.response?.data?.code === 203
          //       ? `토큰이 발급되지 않았습니다.`
          //       : err?.response?.data?.code === 204
          //       ? "존재하지 않는 게시글 입니다."
          //       : "등록에 실패하였습니다.",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // });
          Errorcode({ code: err?.response?.data?.code });
        });
    },
    [anonymousToken, boardId.id, childCounts, parentId, replyContent, replyList]
  );

  const getReply = (parentId, e) => {
    // console.log(e.target.id);
    // console.log(parentId);
    api.get(`/api/v1/comments/parents/${parentId}`).then((res) => {
      // console.log(res);
      // console.log(res.data);
      setReplyList(res.data.list);
    });
    setShowList(!showList);
    setShowTextArea(!showTextArea);
  };

  return (
    <CommentList key={key}>
      {/* 댓글 */}
      <CommentListWrap>
        <CommentPhoto>
          <div>
            <FaUser />
          </div>
        </CommentPhoto>
        <CommentWrap>
          <CommentName>{userName}</CommentName>
          <CommentContent>
            {contents.split("\n").map((line) => (
              <CommentPtag>
                {line}
                {/* {console.log("나와라나와라얼른나와라", line)} */}

                <br />
              </CommentPtag>
            ))}
          </CommentContent>
          <div>
            <span>{createDate}</span>
            <button
              id={`reply_${parentId}`}
              onClick={(e) => getReply(parentId, e)}
            >
              답글 확인
              {`(${childCounts === undefined ? 0 : childCounts})`}
            </button>
          </div>
        </CommentWrap>
      </CommentListWrap>
      {/* 답글 */}
      {showList &&
        replyList &&
        replyList.map((item) => (
          <CommentListWrap key={item.id}>
            <ReplyListWrap>
              <CommentPhoto>
                <div>
                  <FaUser />
                </div>
              </CommentPhoto>
              <CommentWrap>
                <CommentName>{item.userName}</CommentName>
                <CommentContent>
                  {item.contents.split("\n").map((line) => (
                    <CommentPtag>
                      {line}
                      {/* {console.log("나와라나와라얼른나와라", line)} */}
                      <br />
                    </CommentPtag>
                  ))}
                </CommentContent>
                <div>
                  <span>{item.createDate}</span>
                </div>
              </CommentWrap>
            </ReplyListWrap>
          </CommentListWrap>
        ))}
      {/* 답글 등록 */}
      {showTextArea && (
        <CommentDiv id={parentId}>
          <ReplyColumnDiv>
            <ReplyTextArea
              value={replyContent}
              onChange={(e) => handleSetValue(e)}
              placeholder="답글을 작성하세요"
              onKeyDown={(e) => handleSetTab(e)}
              onKeyUp={handleSetLength}
            ></ReplyTextArea>
            <span>{textLength}자/200자</span>
          </ReplyColumnDiv>
          <MyButton
            onClick={postReply}
            text={"등록"}
            color={"white"}
            width={"95px"}
          />
        </CommentDiv>
      )}
    </CommentList>
  );
};

const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #b2b8c1;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  min-height: 0;
`;

const CommentListWrap = styled.div`
  display: flex;
  width: 100%;
  min-height: 0;
  justify-content: end;
`;

const ReplyListWrap = styled.div`
  display: flex;
  width: 96%;
  min-height: 0;
  margin-top: 15px;
`;

const CommentPhoto = styled.div`
  display: flex;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #79c4e5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
  }
`;

const CommentWrap = styled.div`
  dispaly: flex;
  width: 96%;
  margin-left: 15px;

  div {
    span {
      font-weight: 500;
      font-size: 11px;
      letter-spacing: 0.05em;
      color: #666666;
      margin-right: 30px;
    }
    button {
      font-weight: 500;
      font-size: 11px;
      letter-spacing: 0.05em;
      color: #666666;
      margin-right: 10px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;

const CommentContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CommentPtag = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #232323;
  min-height: 0;
  font-size: 12px;
`;

const CommentName = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #232323;
`;

const CommentDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 20px;
`;

const ReplyColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 87%;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid #b2b8c1;
  padding: 5px;

  span {
    width: 100% !important;
    text-align: right !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    margin: 0 !important;
  }
`;

const ReplyTextArea = styled.textarea`
  width: 100%;
  height: 30px;
  resize: none;
  border: none;
  background: transparent;
  letter-spacing: 0.1em;

  &:focus {
    outline: none;
  }
`;

export default ForumCommentLists;
