import React, { useEffect } from "react";
import Select from "react-select";

const SelectDepartment = ({
  text,

  // 직급
  ranks,
  selectedRank,
  setSelectedRank,
  rankValue,
  setRankValue,

  //  부서
  workTypes,
  selectedWorkType,
  setSelectedWorkType,
  workTypeValue,
  setWorkTypeValue,

  //  부서
  teams,
  selectedTeam,
  setSelectedTeam,
  teamValue,
  setTeamValue,
}) => {
  useEffect(() => {
    if (ranks) {
      console.log("rankValue", rankValue);
    }
    if (workTypes) {
      console.log("workTypeValue", workTypeValue);
    }
    if (teams) {
      console.log("teamValue", teamValue);
    }
  }, [rankValue, teamValue, workTypeValue]);

  const customStyles = {
    options: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "transparent",
      border: "none",
      height: "100%",
      borderRadius: "10px",
      letterSpacing: "0.1em",
      fontWeight: 500,
      fontSize: "13px",
      borderColor: "#3752d3",
      marginTop: "5px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#3752d3",
      },
    }),
  };

  if (ranks) {
    return (
      <Select
        key={"ranks"}
        // defaultValue={rankValue}
        value={rankValue}
        // onChange={setSelectedRank}
        onChange={(e) => {
          setRankValue(e);
        }}
        options={ranks}
        placeholder={text}
        styles={customStyles}
      />
    );
  }

  if (workTypes) {
    return (
      <Select
        key={"worktypes"}
        // defaultValue={workTypeValue}
        value={workTypeValue}
        // onChange={setSelectedWorkType}
        onChange={(e) => {
          setWorkTypeValue(e);
        }}
        options={workTypes}
        placeholder={text}
        styles={customStyles}
      />
    );
  }
  if (teams) {
    return (
      <Select
        key={"teams"}
        // defaultValue={teamValue}
        value={teamValue}
        // onChange={setSelectedTeam}
        onChange={(e) => {
          setTeamValue(e);
        }}
        options={teams}
        placeholder={text}
        styles={customStyles}
      />
    );
  }
};

export default SelectDepartment;
