// Library
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";

// Components
import Loading from "../Loading";
import Modal from "../../components/Modal";
import {
  DashboardContent,
  DashboardDate,
  DashboardListContainer,
  DashboardListContent,
  DashboardOverviewButton,
  TitleContainer,
} from "./styled";

// util
import api from "../../redux/api";
import { Card, Grid, useMediaQuery } from "@mui/material";

const Payment = ({ role }) => {
  let navigate = useNavigate();
  const onClickOverview = useCallback(() => {
    navigate("/payment");
  });

  const [contentData, setContentData] = useState([]);
  const [rejectPaymentId, setRejectPaymentId] = useState();
  const [acceptPaymentId, setAcceptPaymentId] = useState();

  const [cancelModal, setCancelModal] = useState(false);
  const [registModal, setRegistModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const xsUp = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  const onClickAccept = useCallback(() => {
    // setLoading(true);
    const body = {
      ids: [acceptPaymentId],
      type: "A",
    };

    api.put(`/api/v1/expense`, body).then((res) => {
      setLoading(false);
      Swal.fire("알림", "해당 문서를 승인하였습니다.", "success").then(
        (res) => {
          setCancelModal(false);
          setRegistModal(false);
          setLoading(true);
          window.location.reload().then(() => {
            setLoading(false);
          });
        }
      );
    });
  }, [acceptPaymentId]);

  const onClickReject = useCallback(() => {
    // setLoading(true);
    const body = {
      ids: [rejectPaymentId],
      type: "R",
    };

    api.put(`/api/v1/expense`, body).then((res) => {
      setLoading(false);
      Swal.fire("알림", "해당 문서를 반려하였습니다.", "success").then(
        (res) => {
          setCancelModal(false);
          setRegistModal(false);
          setLoading(true);
          window.location.reload().then(() => {
            setLoading(false);
          });
        }
      );
    });
  }, [rejectPaymentId]);

  useEffect(() => {
    api.get(`/api/v1/expense?page=0&size=2&status=W`).then((res) => {
      setContentData(res?.data?.data?.content);
    });
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      {cancelModal ? (
        <Modal
          title={"알림"}
          content={"해당 문서를 반려하시겠습니까?"}
          onClick={onClickReject}
          setOpenModal={setCancelModal}
        />
      ) : null}
      {registModal ? (
        <Modal
          title={"알림"}
          content={"해당 문서를 승인하시겠습니까?"}
          onClick={onClickAccept}
          setOpenModal={setRegistModal}
        />
      ) : null}
      <Card
        sx={{
          borderRadius: "10px",
          // height: "100%",
          p: 2,
        }}
      >
        <TitleContainer>
          <MainTitle>APPROVAL</MainTitle>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            지출결의
          </span>
        </TitleContainer>
        {/*  */}
        <DashboardListContainer>
          {contentData?.map((item) => (
            <React.Fragment key={item?.id}>
              {role === "ROLE_LV3" ? (
                <>
                  {mdUp ? (
                    <DashboardListContent key={item?.id}>
                      <DashboardDate
                        onClick={() => {
                          navigate(`/payment/${item?.id}`);
                        }}
                      >
                        {item?.createDate?.substr(2, 11).replace(/-/g, ".")}
                      </DashboardDate>
                      <DashboardContent
                        onClick={() => {
                          navigate(`/payment/${item?.id}`);
                        }}
                      >
                        {item?.title?.length >= 18
                          ? item?.title.substr(0, 15) + "..."
                          : item?.title}
                      </DashboardContent>
                      <ApprovalButton
                        onClick={() => {
                          setRejectPaymentId(item?.id);
                          setCancelModal(true);
                        }}
                        color={"#D33751"}
                      >
                        <FaTimesCircle
                          style={{
                            color: "#D33751",
                          }}
                        />
                        <ButtonText>반려</ButtonText>
                      </ApprovalButton>

                      <ApprovalButton
                        onClick={() => {
                          setAcceptPaymentId(item?.id);
                          setRegistModal(true);
                        }}
                        color={"#3752D3"}
                      >
                        <FaCheckCircle
                          style={{
                            color: "#3752D3",
                          }}
                        />
                        <ButtonText>승인</ButtonText>
                      </ApprovalButton>
                      <div style={{ color: "#4285F4", paddingRight: "5px" }}>
                        &gt;
                      </div>
                    </DashboardListContent>
                  ) : (
                    // TODO: level3 에서 웹 크기가 작을 떄
                    <Level3MdupContent key={item?.id}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <SpaceBox>
                            <div
                              style={{
                                color: "#5886bd",
                                fontWeight: "700",
                              }}
                              onClick={() => {
                                navigate(`/payment/${item?.id}`);
                              }}
                            >
                              {item?.createDate
                                ?.substr(2, 11)
                                .replace(/-/g, ".")}
                            </div>
                            <div
                              style={{ color: "#4285F4", paddingRight: "5px" }}
                            >
                              &gt;
                            </div>
                          </SpaceBox>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <DashboardContent
                            onClick={() => {
                              navigate(`/payment/${item?.id}`);
                            }}
                          >
                            {item?.title?.length >= 10
                              ? item?.title.substr(0, 7) + "..."
                              : item?.title}
                          </DashboardContent>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Level3MdupApprovalButton
                              onClick={() => {
                                setRejectPaymentId(item?.id);
                                setCancelModal(true);
                              }}
                              color={"#D33751"}
                            >
                              <FaTimesCircle
                                style={{
                                  color: "#D33751",
                                }}
                              />
                              <Level3MdUpButtonText>반려</Level3MdUpButtonText>
                            </Level3MdupApprovalButton>

                            <Level3MdupApprovalButton
                              onClick={() => {
                                setAcceptPaymentId(item?.id);
                                setRegistModal(true);
                              }}
                              color={"#3752D3"}
                            >
                              <FaCheckCircle
                                style={{
                                  color: "#3752D3",
                                }}
                              />
                              <Level3MdUpButtonText>승인</Level3MdUpButtonText>
                            </Level3MdupApprovalButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Level3MdupContent>
                  )}
                </>
              ) : (
                // TODO: level3이 아닐 떄
                <>
                  <DashboardListContent key={item?.id}>
                    <DashboardDate
                      onClick={() => {
                        navigate(`/payment/${item?.id}`);
                      }}
                    >
                      {item?.createDate?.substr(2, 11).replace(/-/g, ".")}
                    </DashboardDate>
                    <DashboardContent
                      onClick={() => {
                        navigate(`/payment/${item?.id}`);
                      }}
                    >
                      {item?.title?.length >= 15
                        ? item?.title.substr(0, 12) + "..."
                        : item?.title}
                    </DashboardContent>
                    <div style={{ color: "#4285F4", paddingRight: "5px" }}>
                      &gt;
                    </div>
                  </DashboardListContent>
                </>
              )}
            </React.Fragment>
          ))}
          <DashboardOverviewButton onClick={onClickOverview}>
            지출결의 전체보기
          </DashboardOverviewButton>
        </DashboardListContainer>
      </Card>
    </>
  );
};

const MainTitle = styled.span`
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
`;

const ApprovalButton = styled.div`
  cursor: pointer;
  display: flex;
  border: 1px solid ${(props) => props.color};
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
  border-radius: 10px;
  width: 150px;
  background: #fff;
`;

const ButtonText = styled.span`
  font-weight: 500;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Level3MdupContent = styled.div`
  // display: flex;
  background: #f3f8ff;
  border-radius: 30px;
  width: 100%;

  padding: 20px 10px;
  // gap: 20px;
  // justify-content: space-around;
  // align-items: center;
  // height: 50px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const SpaceBox = styled.div`
  display: flex;
  justify-content: space-between;
  // border: 1px solid black;
`;

const Level3MdupApprovalButton = styled.div`
  cursor: pointer;
  display: flex;
  border: 1px solid ${(props) => props.color};
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  // width: 50%;
  width: 45%;
  background: #fff;
`;

const Level3MdUpButtonText = styled.span`
  font-weight: 500;
  display: flex;
  // width: 50%;
  padding-left: 30px;
  justify-content: center;
`;
export default Payment;
