import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: {},
    role: "",
    success: "",
    userId: '',
    name: '',
}

const logInReducer = createSlice({
    name: "logIn",
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload.userData;
            state.name = action.payload.name;
        },
        logInCheck: (state, action) => {
            state.success = action.payload
        },
    },  
});

//export const { getUserData, getMyName, logInCheck } = logInReducer.actions
export default logInReducer