import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import api from "../../redux/api";
import forumReducer from "../../redux/reducers/forumReducer";
import styled from "styled-components";

const FileDownload = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // console.log(location);
    api.get(`api/v1${location.pathname}`).then((res) => {
      let files = res.data.data.files;
      let fileId = files.map((item) => item.fileId);
      let originFileName = files.map((item) => item.originFileName);
      dispatch(
        forumReducer.actions.setFiles({
          originFileName: originFileName,
          fileId: fileId,
        })
      );
      setFiles(files);
    });
  }, [dispatch, id, location]);

  const fileDownload = (e) => {
    const config = {
      responseType: "arraybuffer",
      headers: { "content-type": "multipart/form-data" },
    };

    const fileListId = e.currentTarget.id;
    const fileListName = e.currentTarget.value;
    api.get(`api/v1/files/${fileListId}`, config).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileListName}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <FileBox>
      <p>파일첨부</p>
      {files.map((item) => (
        <FileLists key={item.fileId}>
          <button
            onClick={(e) => fileDownload(e)}
            id={item.fileId}
            value={item.originFileName}
          >
            {item.originFileName}
          </button>
          <span>{Math.round((parseInt(item.size) / 1000) * 100) / 100}KB</span>
        </FileLists>
      ))}
    </FileBox>
  );
};

const FileBox = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 14px;
`;

const FileLists = styled.div`
  display: flex;
  color: #3752d3;
  witdh: 100%;
  height: 37px;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  border: 1px solid #b2b8c1;
  margin-bottom: 5px;

  span {
    margin-right: 15px;
  }

  button {
    margin-left: 15px;
    cursor: pointer;
    margin-right: 10px;
    background-color: transparent;
    border: none;
    color: #3752d3;
    font-family: "Noto Sans KR";
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1em;
  }
`;
export default FileDownload;
