import React from "react";

import Anonymous from "../../components/dashborad/Anonymous";
import CalendarOverview from "../../components/dashborad/Calendar";
import NoticeMaster from "../../components/dashborad/NoticeMaster";
import Notice from "../../components/dashborad/Notice";
import Payment from "../../components/dashborad/Payment";
import { Grid, Paper, Stack, useMediaQuery } from "@mui/material";

const Level2 = () => {
  const myRole = localStorage.getItem("role");
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {mdUp ? (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Stack spacing={2}>
              <Paper sx={{ height: "50%", borderRadius: "10px" }}>
                <CalendarOverview role={myRole} />
              </Paper>
              <Paper
                sx={{
                  height: "50%",
                  borderRadius: "10px",
                }}
              >
                <Anonymous />
              </Paper>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Stack spacing={2}>
              <Paper
                sx={{
                  borderRadius: "10px",
                  height: "33%",
                }}
              >
                <NoticeMaster role={myRole} />
              </Paper>
              <Paper
                sx={{
                  borderRadius: "10px",
                  height: "33%",
                }}
              >
                <Notice role={myRole} />
              </Paper>
              <Paper
                sx={{
                  borderRadius: "10px",
                  height: "33%",
                }}
              >
                <Payment />
              </Paper>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CalendarOverview role={myRole} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Anonymous />
          </Grid>
          <Grid item xs={12} md={12}>
            <NoticeMaster role={myRole} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Notice role={myRole} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Payment />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Level2;
