import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  noticetitle: "",
  createDate: "",
  modifyDate: "",
  noticecontents: "",
  contentTitle: "",
  contentsHTML: "",
  contentList: [],
  originFileName: [],
  commentsList: [],
  totalElements: 0,
  totalPages: 0,
  commentPage: 0,

  files: [],
  allFilteredFileList: [],
  filteredFileIdList: [],
  teamId: 0,
  fileId: [],
  fileIdList: [],

  // 비밀글 토큰
  secretToken: undefined,
};

const forumReducer = createSlice({
  name: "forum",
  initialState,
  reducers: {
    setNoticeDetailInfo: (state, action) => {
      state.noticetitle = action.payload.noticetitle;
      state.createDate = action.payload.createDate;
      state.modifyDate = action.payload.modifyDate;
      state.noticecontents = action.payload.noticecontents;
      state.fileId = action.payload.fileId;
    },
    setComment: (state, action) => {
      state.commentsList = action.payload.commentsList;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
    },
    setCommentPage: (state, action) => {
      state.commentPage = action.payload.commentPage;
    },
    addCommnetList: (state, action) => {
      state.commentsList.unshift(action.payload);
    },
    addTotalElements: (state) => {
      state.totalElements = state.totalElements + 1;
    },
    setContentsInfo: (state, action) => {
      state.contentTitle = action.payload.contentTitle;
      state.contentsHTML = action.payload.contentsHTML;
    },
    setAllList: (state, action) => {
      state.contentList = action.payload.contentList;
    },
    setFiles: (state, action) => {
      state.originFileName = action.payload.originFileName;
    },
    getFiles: (state, action) => {
      state.files = action.payload;
    },
    resetFiles: (state, action) => {
      state.files.splice(0, action.payload.length);
    },
    //filter(delete 했을 경우도)
    addFileFilterList: (state, action) => {
      state.allFilteredFileList.push(action.payload);
    },
    addFileFilterIdList: (state, action) => {
      state.filteredFileIdList.push(action.payload);
    },
    updateFileListFilter: (state, action) => {
      state.allFilteredFileList = action.payload;
    },
    updateFileIdFilter: (state, action) => {
      state.filteredFileIdList = action.payload;
    },
    deleteFileListFilter: (state, action) => {
      state.allFilteredFileList.splice(0, action.payload.length);
    },
    deleteeFileIdFilter: (state, action) => {
      state.filteredFileIdList.splice(0, action.payload.length);
    },
    setTeamId: (state, action) => {
      state.teamId = action.payload.teamId;
    },
    fileIdList: (state, action) => {
      state.filteredFileIdList = action.payload;
    },
    // 비밀글
    setSecretToken: (state, action) => {
      state.secretToken = action.payload;
    },
  },
});

export default forumReducer;
