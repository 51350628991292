// Library
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import AuthorInfo from "../../components/AuthorInfo";

// Components
import Buttons from "../../components/Buttons";
import Modal from "../../components/Modal";
import PayInfoType from "../../components/PayInfoType";
import PaymentList from "../../components/PaymentLists";
import Title from "../../components/Title";
import {
  MyContainerWrap,
  MyContentContainer,
  MyDetailButtonContainerWrap,
} from "../../styled/Container";
import { MyPayInfoContainer } from "../../styled/Payment";

// util
import api from "../../redux/api";
import Loading from "../../components/Loading";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../../components/MyButton";
import DetailDateAndTitle from "../../components/DetailDateAndTitle";

const PaymentDetail = () => {
  // TODO: 권한에 따라 다르게 작업해야 함
  const myRole = localStorage.getItem("role");

  const { id } = useParams();

  const navigate = useNavigate();
  const onClickGoBack = useCallback(
    (e) => {
      navigate("/payment");
    },
    [navigate]
  );

  const [content, setContent] = useState();
  const [payList, setPayList] = useState([]);

  // TODO: Modal
  const [rejectModal, setRejectModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);

  const [loading, setLoading] = useState(false);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    myRole === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      // console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const onClickReject = useCallback(() => {
    setRejectModal(true);
  }, [rejectModal]);
  const onHandleReject = useCallback(() => {
    setLoading(true);
    const body = {
      ids: [id],
      type: "R",
    };

    api.put(`/api/v1/expense`, body).then((res) => {
      Swal.fire("알림", "해당 지출 결의 문서를 반려했습니다.", "success").then(
        (res) => {
          setLoading(false);
          navigate("/payment");
        }
      );
    });
  }, []);

  const onClickApproval = useCallback(() => {
    setAcceptModal(true);
  }, [acceptModal]);
  const onHandleApproval = useCallback(() => {
    setLoading(true);
    const body = {
      ids: [id],
      type: "A",
    };

    api.put(`/api/v1/expense`, body).then((res) => {
      Swal.fire(
        "알림",
        "해당 지출 결의 문서를 승인하였습니다.",
        "success"
      ).then((res) => {
        setLoading(false);
        navigate("/payment");
      });
    });
  }, []);

  useEffect(() => {
    api.get(`/api/v1/expense/${id}`).then((res) => {
      setContent(res?.data?.data);
      res?.data?.data?.payType === "R"
        ? setPayList(res?.data?.data?.remittanceList)
        : setPayList(res?.data?.data?.corpList);
    });
  }, []);

  console.log("content: ", content);

  return (
    <Grid>
      <Card
        sx={{
          borderRadius: "10px",
          width: "100%",
        }}
      >
        {loading ? <Loading /> : null}
        {rejectModal ? (
          <Modal
            content={"해당 지출 결의 문서를 반려하시겠습니까?"}
            onClick={onHandleReject}
            setOpenModal={setRejectModal}
          />
        ) : null}
        {acceptModal ? (
          <Modal
            content={"해당 지출 결의 문서를 승인하시겠습니까?"}
            onClick={onHandleApproval}
            setOpenModal={setAcceptModal}
          />
        ) : null}
        <Box p={3}>
          <ContentContainer>
            <Title subtitle={"APPROVAL"} title={"지출결의"} />
            <ContainerWrap
              background={"#F3F8FF"}
              boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
            >
              <DetailDateAndTitle
                date={content?.createDate}
                title={content?.title}
                width={"95%"}
              />

              {/* 작성자 정보 */}
              <AuthorInfo
                userName={content?.name}
                userTeam={content?.teamName}
                userPosition={content?.rank}
              />

              {/* 결제 정보 - 상세 */}
              <PayInfoContainer>
                결재 정보
                <PayInfoType
                  id={id}
                  payType={content?.payType === "R" ? "송금" : "법인카드"}
                  category={
                    content?.category === "T"
                      ? "세금계산서"
                      : content?.category === "C"
                      ? "현금영수증"
                      : "없음"
                  }
                />
                <div>비고</div>
                {content?.note ? (
                  <textarea
                    style={{
                      resize: "none",
                      padding: "15px 20px",
                      border: "1px solid #3752d3",
                      borderRadius: "10px",
                      height: "140px",
                    }}
                    readOnly={true}
                  >
                    {content.note}
                  </textarea>
                ) : null}
                {/* 결제정보 리스트 */}
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  결제 정보
                </div>
                {content?.payType === "R" // 송금 : R 법인카드 : C
                  ? payList?.map((item) => {
                      return (
                        <React.Fragment key={item?.id}>
                          <PaymentList
                            key={item?.id} //
                            payType={"R"}
                            date={item?.usedDate}
                            title={item?.payItem}
                            amount={item?.amount}
                            teamName={item?.department}
                            companyName={item?.companyName}
                            payBank={item?.payBank}
                            accountNumber={item?.accountNumber}
                            accountName={item?.accountName}
                          />
                        </React.Fragment>
                      );
                    })
                  : payList?.map((item) => {
                      return (
                        <React.Fragment key={item?.id}>
                          <PaymentList
                            key={item?.id} //
                            payType={"C"}
                            date={item?.usedDate}
                            title={item?.payItem}
                            amount={item?.amount}
                            teamName={item?.department}
                            person={item?.person}
                          />
                        </React.Fragment>
                      );
                    })}
              </PayInfoContainer>
            </ContainerWrap>
            {myRole === "ROLE_LV3" ? (
              <ButtonGroup justifyContent={"space-between"}>
                {resize >= 995 ? (
                  <>
                    <Buttons
                      onClick={onClickGoBack}
                      text={"뒤로가기"}
                      background={"trans"}
                      border={"1px solid #232323"}
                    />

                    {content?.status !== "W" ? null : (
                      <div>
                        <Buttons
                          onClick={onClickReject}
                          text={"반려"}
                          color={"white"}
                          background={"danger"}
                        />
                        <Buttons
                          onClick={onClickApproval}
                          text={"승인"}
                          color={"white"}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <MyButton
                      width={"90px"}
                      height={"55px"}
                      text={"뒤로가기"}
                      background={"cancel"}
                      color={"black"}
                      onClick={onClickGoBack}
                      border={"1px solid #232323"}
                      margin={"0px 5px 10px 5px"}
                    />

                    <div>
                      <MyButton
                        width={"90px"}
                        height={"55px"}
                        background={"danger"}
                        text={"반려"}
                        onClick={onClickReject}
                        margin={"0px 5px 10px 5px"}
                      />
                      <MyButton
                        width={"90px"}
                        height={"55px"}
                        text={"승인"}
                        onClick={onClickApproval}
                        margin={"0px 5px 10px 5px"}
                      />
                    </div>
                  </>
                )}
              </ButtonGroup>
            ) : (
              <ButtonContainerWrap>
                {resize >= 995 ? (
                  <Buttons
                    onClick={onClickGoBack}
                    text={"뒤로가기"}
                    background={"trans"}
                    border={"1px solid #232323"}
                  />
                ) : (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={"뒤로가기"}
                    background={"cancel"}
                    color={"black"}
                    onClick={onClickGoBack}
                    border={"1px solid #232323"}
                    margin={"0px 5px 10px 5px"}
                  />
                )}
              </ButtonContainerWrap>
            )}
          </ContentContainer>
        </Box>
      </Card>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

// ----- 지출 결의 상세

// 결재정보
const PayInfoContainer = styled.div`
  ${MyPayInfoContainer}
`;

const ButtonContainerWrap = styled.div`
  ${MyDetailButtonContainerWrap}
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};

  width: 100%;
  height: 100px;
  align-items: center;
  margin-top: 7px;
`;

export default PaymentDetail;
