import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Buttons from "../components/Buttons";
import MyButton from "../components/MyButton";
import NewComerInfoList from "../components/human/NewComerInfoList";
import Title from "../components/Title";
import api from "../redux/api";
import {
  MyContainerWrap,
  MyContentContainer,
  MyDetailContainerWrap,
  MyDetailContent,
  MyDetailInnerContainer,
  MyPaginationContainer,
} from "../styled/Container";
import moment from "moment";
import Modal from "../components/Modal";
import Swal from "sweetalert2";
import { Box, Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Errorcode from "../util/Errorcode";

const MyInfo = () => {
  const [edit, setEdit] = useState(false);
  const [myInfo, setMyInfo] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    accessToken === null && navigate("/login");
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    api.get(`/api/v1/account`, config).then((res) => {
      setMyInfo(res.data.data);
      setName(res.data.data.name);
      setEmail(res.data.data.email);
      setAddress(res.data.data.address.address);
      setAddressDetail(res.data.data.address.detail);
    });
    // console.log(myInfo);
  }, []);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const cancelEdit = () => {
    setOpenModal(false);
    setEdit(!edit);
    setName(myInfo.name);
    setEmail(myInfo.email);
    setAddress(myInfo.address.address);
    setAddressDetail(myInfo.address.detail);
    window.location.reload();
  };

  const onEdit = () => {
    setEdit(!edit);

    if (edit) {
      const body = {
        id: myInfo?.id,
        name,
        email,
        address,
        addressDetail,
      };

      api
        .put(`api/v1/users`, body)
        .then((res) => {
          // console.log("put", res);
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("email", res.data.data.email);

          Swal.fire("알림", "수정이 완료되었습니다.", "success");
        })
        .catch((err) => {
          const errlistLength = err.response.data.list.length;
          // console.log(err?.response?.data?.list);
          // console.log(err?.response?.data?.list[errlistLength - 1].message);
          Errorcode({
            code: err?.response?.data?.code,
            message: err?.response?.data?.list[errlistLength - 1].message,
          });
          setEdit(true);
        });
    }
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px" }}
      style={{ position: "relative" }}
    >
      {openModal ? (
        <Modal
          title={"게시물 작성"}
          content={"수정을 취소하시겠습니까?"}
          onClick={cancelEdit}
          setOpenModal={setOpenModal}
        />
      ) : null}
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <Title subtitle={"INFOMATION"} title={"내정보"} />
            <ContentContainer>
              <ContainerWrap background={edit ? "white" : "#3B4351"}>
                <EditInfo height={resize >= 995 ? "200" : "250"} edit={edit}>
                  <EditInfoInner>
                    <MyInfoTitleEdit edit={edit}>변경 가능</MyInfoTitleEdit>
                    <EditList edit={edit}>
                      <EditTitle edit={edit}>이름</EditTitle>
                      <EditInput
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                        edit={edit}
                        readOnly={!edit}
                      ></EditInput>
                    </EditList>
                    <EditList edit={edit}>
                      <EditTitle edit={edit}>이메일</EditTitle>
                      <EditInput
                        edit={edit}
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={myInfo?.email}
                        readOnly={!edit}
                      ></EditInput>
                    </EditList>
                    <EditList edit={edit}>
                      <EditTitle style={{ width: resize < 995 && "15%" }} edit={edit}>
                        주소
                      </EditTitle>
                      <EditInput
                        style={{ width: resize >= 995 ? "41%" : "75%" }}
                        edit={edit}
                        onChange={(e) => setAddress(e.target.value)}
                        defaultValue={myInfo?.address?.address}
                        readOnly={!edit}
                      ></EditInput>
                      {resize >= 995 && (
                        <>
                          <EditTitle style={{ width: "9%" }} edit={edit}>
                            상세주소
                          </EditTitle>
                          <EditInput
                            style={{ width: "35%" }}
                            edit={edit}
                            onChange={(e) => setAddressDetail(e.target.value)}
                            defaultValue={myInfo?.address?.detail}
                            readOnly={!edit}
                          ></EditInput>
                        </>
                      )}
                    </EditList>
                    {resize < 995 && (
                      <EditList edit={edit}>
                        <EditTitle style={{ width: "15%" }} edit={edit}>
                          상세주소
                        </EditTitle>
                        <EditInput
                          // style={{ width: "40%" }}
                          edit={edit}
                          onChange={(e) => setAddressDetail(e.target.value)}
                          defaultValue={myInfo?.address?.detail}
                          readOnly={!edit}
                        ></EditInput>
                      </EditList>
                    )}
                  </EditInfoInner>
                </EditInfo>
                <DetailContainerWrap height={"400"}>
                  <DetailInnerContainer>
                    <MyInfoTitle>
                      변경 불가능
                      {resize >= 995 ? (
                        <span>(변경을 원할 경우 경영지원팀으로 문의바랍니다.)</span>
                      ) : (
                        <span>(변경을 원할 경우 문의바랍니다.)</span>
                      )}
                    </MyInfoTitle>
                    <DetailContent>
                      <NewComerInfoList
                        title={"입사일자"}
                        detail={moment(myInfo?.enterDate).format("YYYY.MM.DD")}
                      />

                      <NewComerInfoList title={"소속"} detail={myInfo?.team?.name} />
                      <NewComerInfoList title={"직급"} detail={myInfo?.rank?.name} />
                      <NewComerInfoList title={"생년월일"} detail={myInfo?.birthDate} />
                      {/* <NewComerInfoList title={"성별"} detail={myInfo?.gender} /> */}
                      <NewComerInfoList title={"전화번호"} detail={myInfo?.phone} />
                      <NewComerInfoList
                        title={"계좌번호"}
                        detail={`${myInfo?.accountNumber} 국민은행`}
                      />
                    </DetailContent>
                  </DetailInnerContainer>
                </DetailContainerWrap>
              </ContainerWrap>

              <PaginationContainer>
                {edit && resize >= 995 && (
                  <Buttons
                    text={"수정 취소"}
                    color={"white"}
                    onClick={() => setOpenModal(true)}
                    background={"danger"}
                  />
                )}
                {edit && resize < 995 && (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    background={"danger"}
                    onClick={() => setOpenModal(true)}
                    text={"수정 취소"}
                    margin={"0px 5px 10px 5px"}
                  />
                )}

                {resize >= 995 ? (
                  <Buttons text={edit ? "수정완료" : "수정"} color={"white"} onClick={onEdit} />
                ) : (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={edit ? "수정완료" : "수정"}
                    onClick={onEdit}
                    margin={"0px 5px 10px 5px"}
                  />
                )}
              </PaginationContainer>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;
const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const DetailInnerContainer = styled.div`
  ${MyDetailInnerContainer}
`;

const DetailContent = styled.div`
  ${MyDetailContent}
`;
const DetailContainerWrap = styled.div`
  ${MyDetailContainerWrap}
  background: #3B4351;
  margin-top: 13px;
`;

const MyInfoTitle = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 13px;

  span {
    font-size: 12px;
  }
`;

const MyInfoTitleEdit = styled.div`
  color: ${(props) => (props.edit ? "#232323" : "#ffffff")};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 13px;
  // margin-top: 30px;

  span {
    font-size: 12px;
  }
`;

const EditInfo = styled.div`
  ${MyDetailContainerWrap}
  align-items: center;
  background: ${(props) => (props.edit ? "#ffffff" : "#3B4351")};
  border: ${(props) => (props.edit ? "1px solid #3752d3" : "none")};
  margin-top: 22px;
  height: ${(props) => props.height};
`;

const EditInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 95%;
  height: 100%;
  // margin-top: 30px;
`;

const EditList = styled.div`
  border: ${(props) => (props.edit ? "1px solid #3752d3" : "1px solid white")};
  width: 100%;
  height: 45px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
`;

const EditTitle = styled.div`
  width: 8%;
  margin-left: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.edit ? "#232323" : "white")};
  display: flex;
  align-items: center;

  @media screen and (max-width: 995px) {
    font-size: 12px;
    width: 15%;
  }
`;

const EditInput = styled.input`
  width: 85%;
  margin-left: 10px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => (props.edit ? "#232323" : "white")};
  height: 90%;
  border: none;
  margin-top: 1px;
  background: ${(props) => (props.edit ? "#ffffff" : "#3B4351")};

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 995px) {
    font-size: 15px;
    width: 75%;
  }
`;
export default MyInfo;
