// 익명 게시판

import { Card, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import api from "../../redux/api";
import {
  DashboardContent,
  DashboardDate,
  DashboardListContainer,
  DashboardListContent,
  DashboardOverviewButton,
  TitleContainer,
} from "./styled";

const Anonymous = ({ role }) => {
  let navigate = useNavigate();

  const onClickOverview = useCallback(() => {
    navigate(`/anonymous`);
  });

  const [contentData, setContentData] = useState([]);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (role === "ROLE_LV1") {
      api.get(`/api/v1/anonymous?page=0&size=4&secret=false`).then((res) => {
        setContentData(res?.data?.data?.content);
      });
    } else if (role === "ROLE_LV4") {
      api.get(`/api/v1/anonymous?page=0&size=4&secret=false`).then((res) => {
        setContentData(res?.data?.data?.content);
      });
    } else {
      api.get(`/api/v1/anonymous?page=0&size=4&secret=false`).then((res) => {
        setContentData(res?.data?.data?.content);
      });
    }
  }, []);

  const sliceContent = contentData.slice(0, 4);

  return (
    <Card
      sx={{
        borderRadius: "10px",
        p: 2,
      }}
    >
      <TitleContainer>
        <MainTitle>NOTICE</MainTitle>
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          익명게시판
        </span>
      </TitleContainer>

      <DashboardListContainer>
        {lgUp
          ? contentData?.map((item) => {
              return (
                // !item.isSecret && (
                <DashboardListContent
                  key={item?.id}
                  onClick={() => {
                    if (item?.isSecret) {
                      Swal.fire({
                        title: "알림",
                        html:
                          `해당 글은 비밀 게시글입니다. <br />` +
                          `익명게시판으로 이동하시겠습니까?`,
                        icon: "question",
                        confirmButtonText: "네",
                        showCancelButton: true,
                        cancelButtonText: "아니요",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/anonymous");
                        }
                      });
                    } else {
                      navigate(`/anonymous/${item?.id}`);
                    }
                  }}
                >
                  <DashboardDate>
                    {item?.createDate?.substr(2, 9).replace(/-/g, ".")}
                  </DashboardDate>
                  {lgUp ? (
                    <DashboardContent>
                      {item?.isSecret
                        ? "비밀글 입니다."
                        : item?.title?.length >= 18
                        ? item?.title.substr(0, 15) + "..."
                        : item?.title}
                    </DashboardContent>
                  ) : (
                    <DashboardContent>
                      {item?.title?.length >= 10
                        ? item?.title.substr(0, 7) + "..."
                        : item?.title}
                    </DashboardContent>
                  )}
                  <div style={{ color: "#4285F4", paddingRight: "5px" }}>
                    &gt;
                  </div>
                </DashboardListContent>
                // )
              );
            })
          : sliceContent?.map((item) => {
              return (
                // !item?.isSecret && (
                <DashboardListContent
                  key={item?.id}
                  onClick={() => {
                    navigate(`/anonymous/${item?.id}`);
                  }}
                >
                  <DashboardDate>
                    {item?.createDate?.substr(2, 9).replace(/-/g, ".")}
                  </DashboardDate>
                  {lgUp ? (
                    <DashboardContent>{item?.title}</DashboardContent>
                  ) : (
                    <DashboardContent>
                      {item?.title?.length >= 10
                        ? item?.title.substr(0, 7) + "..."
                        : item?.title}
                    </DashboardContent>
                  )}
                  <div style={{ color: "#4285F4", paddingRight: "5px" }}>
                    &gt;
                  </div>
                </DashboardListContent>
                // )
              );
            })}

        <DashboardOverviewButton onClick={onClickOverview}>
          익명게시판 전체보기
        </DashboardOverviewButton>
      </DashboardListContainer>
    </Card>
  );
};

const MainTitle = styled.span`
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
`;

export default Anonymous;
