import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ForumEditor from "../../../components/forum/ForumEditor";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";
import Swal from "sweetalert2";
import Errorcode from "../../../util/Errorcode";

const NoticeNew = () => {
  const contentTitle = useSelector((state) => state.forum.contentTitle);
  const contentsHTML = useSelector((state) => state.forum.contentsHTML);
  const filteredFileIdList = useSelector(
    (state) => state.forum.filteredFileIdList
  );
  const allFilteredFileList = useSelector(
    (state) => state.forum.allFilteredFileList
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem("token");
  const myRole = localStorage.getItem("role");

  useEffect(() => {
    accessToken === null && navigate("/login");
    (myRole === "ROLE_LV1" || myRole === "ROLE_LV4") && navigate("/notices");
    //console.log('아')
  }, []);

  const postNoticesContent = () => {
    const body = {
      title: contentTitle,
      contents: contentsHTML,
      files: filteredFileIdList,
    };
    setOpenCheckModal(false);
    setLoading(true);

    api
      .post(`/api/v1/notices`, body)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        navigate("/notices");
        Swal.fire({
          title: "알림",
          text: "등록이 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
        dispatch(
          forumReducer.actions.deleteFileListFilter(allFilteredFileList)
        );
        dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
      })
      .catch((err) => {
        setLoading(false);
        // err &&
        //   Swal.fire({
        //     title: "No contents",
        //     text: "컨텐츠와 제목을 입력해 주세요.",
        //     icon: "error",
        //     confirmButtonText: "확인",
        //   });
        const errlistLength = err.response.data.list.length;
        Errorcode({
          code: err?.response?.data?.code,
          message: err?.response?.data?.list[errlistLength - 1].message,
        });
      });
  };

  const cancel = () => {
    navigate(`/notices`);
    setOpenModal(false);
    dispatch(forumReducer.actions.deleteFileListFilter(allFilteredFileList));
    dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
  };

  return (
    <ForumEditor
      subtitle={"NOTICE"}
      title={"사내 공지사항"}
      buttontext={"등록"}
      cancel={cancel}
      onClick={postNoticesContent}
      onEdit={false}
      setOpenModal={setOpenModal}
      openModal={openModal}
      setOpenCheckModal={setOpenCheckModal}
      openCheckModal={openCheckModal}
      setLoading={setLoading}
      loading={loading}
    />
  );
};

export default NoticeNew;
