import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyButton from "../../MyButton";
import CommuteModalCalendar from "./CommuteModalCalendar";
import CommuteTeamSelect from "./CommuteTeamSelect";
import CommuteNameSelect from "./CommuteNameSelect";
import moment from "moment";
import api from "../../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import commutesReducer from "../../../redux/reducers/commutesReducer";
import Swal from "sweetalert2";
import Errorcode from "../../../util/Errorcode";

const CommuteModal = ({
  // teamList,
  // nameListForAdd,
  setAddListModal,
  setContentData,
}) => {
  const selectedDate = useSelector((state) => state.commutes.selectedDate);
  const selectedTeam = useSelector((state) => state.commutes.selectedTeam);
  const selectedName = useSelector((state) => state.commutes.selectedName);
  const dispatch = useDispatch();

  const now = moment();
  const myDate = now.format("YY/MM/DD");
  const myDateForApi = now.format("YYYY-MM-DD");
  const myRole = localStorage.getItem("role");

  const [startTime, setStartTime] = useState("");
  const [teamList, setTeamList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [etc, setEtc] = useState("");

  const modalCancel = () => {
    setAddListModal(false);
    dispatch(commutesReducer.actions.resetCommuteDates(""));
  };

  useEffect(() => {
    teamList.shift();
  }, [teamList]);

  useEffect(() => {
    myRole !== "ROLE_LV1" &&
      api.get(`/api/v1/teams`).then((res) => {
        setTeamList(
          res.data.list
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );
      }) &&
      (((selectedTeam === undefined || selectedTeam === 0) &&
        api.get(`/api/v1/users?status=Y`).then((res) => {
          setNameList(
            res?.data?.list
              ?.map((item) => [{ value: item.id, label: item.name }])
              .map((item) => item[0])
          );
        })) ||
        (selectedTeam !== undefined &&
          selectedTeam !== 0 &&
          selectedTeam !== 1 &&
          api.get(`/api/v1/teams/${selectedTeam}`).then((res) => {
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.id, label: item.name }])
                .map((item) => item[0])
            );
          })));
  }, [myRole, selectedTeam]);

  const addListHandle = () => {
    const body = {
      createDate: selectedDate !== "" ? selectedDate : myDateForApi,
      teamId: selectedTeam,
      userId: selectedName,
      startTime: startTime,
      etc: etc,
    };
    // console.log(body);
    api
      .post(`/api/v1/commute`, body)
      .then((res) => {
        // console.log(res.data.data);
        setContentData((prev) => [res.data.data, ...prev].splice(0, 9));
        setAddListModal(false);
        Swal.fire({
          title: "알림",
          text: "등록이 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
        // window.location.reload();
      })
      .catch((err) => {
        // Swal.fire({
        //   title: err?.response?.data?.msg,
        //   text: "알수없는 에러입니다.",
        //   err?.response?.data?.code === 303
        //     ? `중복 요청이 되었습니다.`
        //     : err?.response?.data?.code === 112
        //     ? "등록된 부서가 없습니다."
        //     : err?.response?.data?.code === 106
        //     ? "등록된 직원이 없습니다."
        //     : "등록에 실패하였습니다.",
        //   icon: "error",
        //   confirmButtonText: "확인",
        // });
        Errorcode({ code: err?.response?.data?.code });
      });
  };

  return (
    <CommutesModal>
      <TitleBox>
        <h2>출&middot;퇴근 등록</h2>
      </TitleBox>
      <MainBox>
        <SubMainBox>
          <div>
            <SmallMainBox>
              <span>날짜</span>
              <CommuteModalCalendar myDate={myDate} />
            </SmallMainBox>
          </div>

          <div>
            <SmallMainBox>
              <span>부서</span>
              <CommuteTeamSelect myOptions={teamList} />
            </SmallMainBox>
          </div>
          <div>
            <SmallMainBox>
              <span>이름</span>
              <CommuteNameSelect myOptions={nameList} />
            </SmallMainBox>
          </div>
        </SubMainBox>
        <SubMainBox>
          <SmallMainBox>
            <span>출근시간</span>
            <CommuteInput
              placeholder="00:00:00"
              onChange={(e) => setStartTime(e.target.value)}
            ></CommuteInput>
          </SmallMainBox>
          <SmallMainBox>
            <span>기타</span>
            <CommuteInput
              placeholder="기타 사유"
              onChange={(e) => setEtc(e.target.value)}
            ></CommuteInput>
          </SmallMainBox>
        </SubMainBox>
      </MainBox>
      <BtnBox>
        <MyButton
          text={"취소"}
          width={"110px"}
          height={"30px"}
          color={"white"}
          background={"danger"}
          onClick={modalCancel}
        />
        <MyButton
          text={"등록"}
          width={"110px"}
          height={"30px"}
          color={"white"}
          margin={"0px 0px 0px 10px"}
          onClick={addListHandle}
        />
      </BtnBox>
    </CommutesModal>
  );
};

const CommutesModal = styled.div`
  position: absolute;
  background: white;
  // width: 750px;
  width: 50%;
  height: 270px;
  padding: 10px 20px;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -25%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 10px;
  // background: yellow;

  @media screen and (max-width: 1380px) {
    width: 60%;
    margin-left: -30%;
  }

  @media screen and (max-width: 940px) {
    width: 80%;
    margin-left: -40%;
  }

  @media screen and (max-width: 720px) {
    width: 90%;
    margin-left: -45%;
  }

  @media screen and (max-width: 650px) {
    width: 60%;
    margin-left: -30%;
    height: 350px;
  }

  @media screen and (max-width: 455px) {
    width: 60%;
    margin-left: -30%;
    height: 400px;
    margin-top: -180px;
  }
`;

const TitleBox = styled.div`
  h2 {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.1em;
  }
`;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #b2b8c1;
  justify-content: center;
  align-items: center;
  height: 200px;
  // background: pink;

  @media screen and (max-width: 650px) {
    height: 300px;
    justify-content: center;
    align-items: start;
  }
  @media screen and (max-width: 455px) {
    align-items: center;
    height: 400px;
  }
`;

const SubMainBox = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 455px) {
    align-items: center;
    width: 100%;
  }
`;

const SmallMainBox = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
  }
  @media screen and (max-width: 650px) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 455px) {
    flex-direction: column;
    // background: pink;
  }
`;

const CommuteInput = styled.input`
  margin-right: 10px;
  border: 1px solid #b2b8c1;
  height: 35px;
  width: 130px;
  padding-left: 10px;
  border-radius: 10px;

  @media screen and (max-width: 455px) {
    margin-right: 0px;
  }
`;

const BtnBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 65px;
`;

export default CommuteModal;
