import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MyListContents, MyPaymentContents } from "../styled/ListStyle";
import { MyPayContainer, MyPayLists } from "../styled/Payment";

const PaymentList = ({
  key,
  payType,

  // 상세 (송금)
  date,
  title,
  amount,
  teamName,
  companyName,
  payBank,
  accountNumber,
  accountName,

  // 법인카드
  person,
}) => {
  const navigate = useNavigate();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  // console.log(payType);

  return (
    <Grid container key={key}>
      {payType === "R" && (
        <PaymentLists>
          {lgUp ? (
            <>
              <Grid
                item
                md={3}
                lg={1.5}
                sx={{
                  gap: "5px",
                }}
              >
                <span>날짜</span>
                <b style={{ marginLeft: "5px" }}>{date}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                지출목적
                <b style={{ marginLeft: "5px" }}>{title?.substr(0, 4)}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                금액
                <b style={{ marginLeft: "5px" }}>
                  {amount?.toLocaleString("ko-kr")}
                </b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                지출부서
                <b style={{ marginLeft: "5px" }}>{teamName}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                업체명
                <b style={{ marginLeft: "5px" }}>{companyName}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                지급은행
                <b style={{ marginLeft: "5px" }}>{payBank}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                계좌번호
                <b style={{ marginLeft: "5px" }}>{accountNumber}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                예금주
                <b style={{ marginLeft: "5px" }}>{accountName}</b>
              </Grid>
            </>
          ) : mdUp ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                lineHeight: "1.8",
              }}
            >
              <Grid item md={3} lg={1.5}>
                <div>날짜</div>
                <b>{date}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>지출목적</div>
                <b>{title?.substr(0, 4)}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>금액</div>
                <b>{amount?.toLocaleString("ko-kr")}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>지출부서</div>
                <b>{teamName}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>업체명</div>
                <b>{companyName}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>지급은행</div>
                <b>{payBank}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>계좌번호</div>
                <b>{accountNumber}</b>
              </Grid>
              <Grid item md={3} lg={1.5}>
                <div>예금주</div>
                <b>{accountName}</b>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                lineHeight: "2",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={3} lg={1.5}>
                  <div>날짜</div>
                  <b>{date}</b>
                </Grid>
                <Grid item xs={3} lg={1.5}>
                  <div>지출목적</div>
                  <b>{title?.substr(0, 4)}</b>
                </Grid>
                <Grid item xs={3} lg={1.5}>
                  <div>금액</div>
                  <b>{amount?.toLocaleString("ko-kr")}</b>
                </Grid>
                <Grid item xs={3} lg={1.5}>
                  <div>지출부서</div>
                  <b>{teamName}</b>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <div>업체명</div>
                  <b>{companyName}</b>
                </Grid>
                <Grid item xs={3}>
                  <div>지급은행</div>
                  <b>{payBank}</b>
                </Grid>
                <Grid item xs={3}>
                  <div>계좌번호</div>
                  <b>{accountNumber}</b>
                </Grid>
                <Grid item xs={3}>
                  <div>예금주</div>
                  <b>{accountName}</b>
                </Grid>
              </Grid>
            </div>
          )}
        </PaymentLists>
      )}

      {payType === "C" && (
        <PaymentLists>
          {lgUp ? (
            <>
              <Grid md={2.4} lg={2.4}>
                <span>날짜</span>
                <b style={{ marginLeft: "5px" }}>{date}</b>
              </Grid>
              <Grid md={2.4} lg={2.4}>
                지출목적
                <b style={{ marginLeft: "5px" }}>{title}</b>
              </Grid>
              <Grid md={2.4} lg={2.4}>
                금액
                <b style={{ marginLeft: "5px" }}>
                  {amount?.toLocaleString("ko-kr")}
                </b>
              </Grid>
              <Grid md={2.4} lg={2.4}>
                지출부서
                <b style={{ marginLeft: "5px" }}>{teamName}</b>
              </Grid>
              <Grid md={2.4} lg={2.4}>
                인원
                <b style={{ marginLeft: "5px" }}>{person}</b>
              </Grid>
            </>
          ) : mdUp ? (
            <>
              <Grid md={2.4}>
                <div>날짜</div>
                <b>{date}</b>
              </Grid>
              <Grid md={2.4}>
                <div>지출목적</div>
                <b>{title}</b>
              </Grid>
              <Grid md={2.4}>
                <div>금액</div>
                <b>{amount?.toLocaleString("ko-kr")}</b>
              </Grid>
              <Grid md={2.4}>
                <div>지출부서</div>
                <b>{teamName}</b>
              </Grid>
              <Grid md={2.4}>
                <div>인원</div>
                <b>{person}</b>
              </Grid>
            </>
          ) : (
            <>
              <Grid xs={2.4}>
                <div>날짜</div>
                <b>{date}</b>
              </Grid>
              <Grid xs={2.4}>
                <div>지출목적</div>
                <b>{title}</b>
              </Grid>
              <Grid xs={2.4}>
                <div>금액</div>
                <b>{amount?.toLocaleString("ko-kr")}</b>
              </Grid>
              <Grid xs={2.4}>
                <div>지출부서</div>
                <b>{teamName}</b>
              </Grid>
              <Grid xs={2.4}>
                <div>인원</div>
                <b>{person}</b>
              </Grid>
            </>
          )}
        </PaymentLists>
      )}
    </Grid>
  );
};

const PaymentLists = styled.div`
  ${MyPayContainer}
  width: 100%;
`;

export default PaymentList;
