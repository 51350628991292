import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import {
  MyAuthorContainer,
  MyAuthorInfo,
  MyAuthorWrap,
} from "../styled/Payment";

const AuthorInfo = ({ userName, userTeam, userPosition }) => {
  return (
    // FIXME: 작업중
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",

        borderRadius: "10px",
        background: "#3b4351",
        color: "#fff",
        padding: "30px",
        margin: "20px",
      }}
    >
      <div style={{ display: "flex", marginBottom: "10px" }}>작성자 정보</div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <div
            style={{
              display: "flex",
              border: "1px solid #ffffff",
              alignItems: "center",
              borderRadius: "10px",
              minHeight: "50px",
              padding: "10px",
            }}
          >
            <div style={{ width: "20%" }}>이름</div>
            <div
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {userName}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div
            style={{
              display: "flex",
              border: "1px solid #ffffff",
              alignItems: "center",
              borderRadius: "10px",
              minHeight: "50px",
              padding: "10px",
            }}
          >
            <div style={{ width: "20%" }}>부서</div>
            <div
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {userTeam}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div
            style={{
              display: "flex",
              border: "1px solid #ffffff",
              alignItems: "center",
              borderRadius: "10px",
              minHeight: "50px",
              padding: "10px",
            }}
          >
            <div style={{ width: "20%" }}>직급</div>
            <div
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {userPosition}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthorInfo;
