import Swal from "sweetalert2";

const Modal = ({ title, content, onClick, setOpenModal }) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const showModal = () => {
    setOpenModal(false);
  };

  swalWithBootstrapButtons
    .fire({
      title: "알림",
      text: content,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니요",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        onClick();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        showModal();
      }
    });
};
export default Modal;
