import React from "react";
import styled, { css } from "styled-components";
import { FaCheck } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";

const RegisterInput = ({
  placeholder,
  type,
  onChange,
  required,
  addressDiv,
  noIconBox,
  alertText,
  defaultValue,
  iconCheck,
  readOnly,
  value,
  checkIcon,
  id,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <RegisterInputs
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        required={required}
        addressDiv={addressDiv}
        readOnly={readOnly}
        value={value}
        defaultValue={defaultValue}
        id={id}
      />
      {noIconBox ? null : iconCheck === "" ? null : checkIcon && checkIcon ? (
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            left: "385px",
            top: "50%",
            marginTop: "-22px",
            marginLeft: "-10px",
            width: "20px",
            height: "20px",
            color: "#3752D3",
          }}
        >
          <FaCheck />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            left: "385px",
            top: "50%",
            marginTop: "-22px",
            marginLeft: "-10px",
            width: "20px",
            height: "20px",
            color: "#D33751",
          }}
        >
          <BsExclamationCircle />
        </div>
      )}

      <AlertDiv>
        <span>{iconCheck === "" ? null : alertText}</span>
      </AlertDiv>
    </div>
  );
};

const RegisterInputs = styled.input`
  display: flex;
  padding: 0;
  padding-left: 15px;
  border: 1px solid #b2b8c1;
  border-radius: 10px;
  width: 403px;
  height: 38px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  ${(props) =>
    props.addressDiv &&
    css`
      width: 268px;
    `}

  &:focus {
    outline: none;
  }
`;

const AlertDiv = styled.span`
  height: 25px;
  display: flex;
  padding-left: 10px;

  span {
    font-size: 11px;
    color: #d33751;
    letter-spacing: 0.06em;
    font-weight: 500;
  }
`;

export default RegisterInput;
