import React from "react";
import styled from "styled-components";
import { Oval } from "react-loader-spinner";

const Loading = () => {
  return (
    <LoadingWrap>
      <LoadingMain>
        <Oval
          height={50}
          width={50}
          color="#3752D3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#5886BD"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        <LoadingText>잠시만 기다려 주세요.</LoadingText>
      </LoadingMain>
    </LoadingWrap>
  );
};

const LoadingWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
`;

const LoadingMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingText = styled.div`
  font-size: 13px;
`;

export default Loading;
