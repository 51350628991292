import React from "react";
import styled from "styled-components";

const Blank = ({ text, height }) => {
  return (
    <BlankContainer height={height}>
      <CircleContainer>
        <Circle opacity={"0.4"}></Circle>
        <Circle opacity={"0.65"}></Circle>
        <Circle opacity={"0.85"}></Circle>
        <Circle></Circle>
      </CircleContainer>
      <TextContainer>
        <span>{text}</span>
      </TextContainer>
    </BlankContainer>
  );
};

const BlankContainer = styled.div`
  height: ${(props) => props.height || "595"}px;
  width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CircleContainer = styled.div`
  display: flex;
  height: 80px;
`;

const TextContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 20px;
  }
`;

const Circle = styled.div`
  width: 48px;
  height: 48px;
  background: #3752d3;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  opacity: ${(props) => props.opacity};
`;

export default Blank;
