import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ForumDetailLayout from "../../../components/forum/ForumDetailLayout";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";

const MeetingDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noticetitle = useSelector((state) => state.forum.noticetitle);
  const createDate = useSelector((state) => state.forum.createDate);
  const modifyDate = useSelector((state) => state.forum.modifyDate);
  const noticecontents = useSelector((state) => state.forum.noticecontents);
  const [editMode, setEditMode] = useState(false);
  const [files, setFiles] = useState(false);

  const [userName, setUserName] = useState("");
  const [teamName, setTeamName] = useState("");

  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken === null && navigate("/login");
    api.get(`/api/v1/meeting/${id}`).then((res) => {
      dispatch(
        forumReducer.actions.setNoticeDetailInfo({
          noticetitle: res.data.data.title,
          createDate: res.data.data.createDate,
          modifyDate: res.data.data.modifyDate,
          noticecontents: res.data.data.contents,
          fileId: res.data.data.files.map((item) => item.fileId),
        })
      );
      dispatch(
        forumReducer.actions.getFiles({
          files: res.data.data.files,
        })
      );
      setUserName(res.data.data.user.name);
      setTeamName(res.data.data.team.name);
      dispatch(
        forumReducer.actions.setTeamId({
          teamId: res.data.data.team.id,
        })
      );
      // console.log(res.data.data);
      res.data.data.files.length === 0 ? setFiles(false) : setFiles(true);
    });
  }, [dispatch, id]);

  const goToEdit = () => {
    navigate(`/meeting/edit/${id}`);
    setEditMode(true);
  };

  return (
    <ForumDetailLayout
      subtitle={"MEETING"}
      title={"회의록"}
      contentTitle={noticetitle}
      createDate={createDate}
      modifyDate={modifyDate}
      contents={noticecontents}
      goToEdit={goToEdit}
      files={files}
      editMode={editMode}
      id={id}
      teamName={teamName}
      userName={userName}
    />
  );
};

export default MeetingDetail;
