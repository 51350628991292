import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ColumnCenter } from "../styled/Flexbox";

import MyButton from "../components/MyButton";
import api from "../redux/api";
import RegisterInput from "./RegisterInput";

import Swal from "sweetalert2";
import Postcode from "../components/Postcode";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Errorcode from "../util/Errorcode";

import { lighten, darken } from "polished";

const Register = () => {
  const [userId, setUserId] = useState("");
  const idRef = useRef();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [isOpenPost, setIsOpenPost] = useState(false);

  // icon check state
  const [nameCheck, setNameCheck] = useState(false);
  const [nameText, setNameText] = useState("");

  const [userIdCheck, setUserIdCheck] = useState(false);
  const [userIdText, setUserIdText] = useState("");

  const [passwordCheck, setPasswordCheck] = useState(false);
  const [passwordText, setPasswordText] = useState("");

  const [rePasswordCheck, setRePasswordCheck] = useState(false);
  const [rePasswordText, setRePasswordText] = useState("");

  const [birthDateCheck, setBirthDateCheck] = useState(false);
  const [birthDateText, setBirthDateText] = useState("");

  const [accountNumberCheck, setAccountNumberCheck] = useState(false);
  const [accountNumberText, setAccountNumberText] = useState("");

  const [emailCheck, setEmailCheck] = useState(false);
  const [emailText, setEmailText] = useState("");

  const [phoneCheck, setPhoneCheck] = useState(false);
  const [phoneText, setPhoneText] = useState("");

  const [detailAddressCheck, setDetailAddressCheck] = useState(false);
  const [detailAddressText, setDetailAddressText] = useState("");

  const navigate = useNavigate();
  const postRef = useRef();

  useOnClickOutside(postRef, () => {
    setIsOpenPost(false);
  });

  useEffect(() => {
    // if (!/^[가-힣]{2,6}$/.test(name)) {
    if (!/^[a-z|A-Z|가-힣]{2,6}$/g.test(name)) {
      setNameText("한글 2~6 또는 영어 2~6자로 입력해야 합니다.");
      setNameCheck(false);
    } else {
      setNameText("");
      setNameCheck(true);
    }
  }, [name]);

  useEffect(() => {
    if (!/^[a-z]+[a-z0-9]{5,19}$/g.test(userId)) {
      setUserIdText("영문자 또는 영문자+숫자 6~20자로 입력해야 합니다.");
      setUserIdCheck(false);
    } else {
      setUserIdText("");
      setUserIdCheck(true);
    }
  }, [userId]);

  useEffect(() => {
    if (!/^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@^!%*#?&]).{8,50}$/.test(password)) {
      setPasswordText(
        "영문,숫자,특수문자($@^!%*#?&)를 모두 포함하여 8자 이상 입력해야 합니다."
      );
      setPasswordCheck(false);
    } else {
      setPasswordText("");
      setPasswordCheck(true);
    }
  }, [password]);

  useEffect(() => {
    if (password !== rePassword) {
      setRePasswordText("비밀번호가 일치하지 않습니다.");
      setRePasswordCheck(false);
    } else {
      setRePasswordText("");
      setRePasswordCheck(true);
    }
  }, [password, rePassword]);

  useEffect(() => {
    // console.log(birthDate);
    if (
      birthDate.length === 8 &&
      /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
        birthDate
      )
    ) {
      setBirthDate(birthDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
      setBirthDateText("");
      setBirthDateCheck(true);
    } else {
      setBirthDateText("생년월일을 입력해 주세요. ex.YYYYMMDD");
      setBirthDateCheck(false);
    }

    //하이픈 입력했을 때
    if (
      birthDate.length === 10 &&
      /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
        birthDate
      )
    ) {
      setBirthDate(
        birthDate.replace(/-/g, "").replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
      );
      setBirthDateText("");
      setBirthDateCheck(true);
    } else {
      setBirthDateText("생년월일을 입력해 주세요. ex.YYYYMMDD");
      setBirthDateCheck(false);
    }
  }, [birthDate]);

  useEffect(() => {
    if (
      accountNumber.length === 14 &&
      /^(\d{6,6})(\d{2,2})(\d{6,6})$/.test(accountNumber)
    ) {
      setAccountNumber(
        accountNumber.replace(/(\d{6})(\d{2})(\d{6})/, "$1-$2-$3")
      );
      setAccountNumberText("");
      setAccountNumberCheck(true);
    } else {
      setAccountNumberText(
        "국민은행 계좌번호를 입력해 주세요. ex.12345612123456"
      );
      setAccountNumberCheck(false);
    }

    //하이픈 입력했을 때
    if (
      accountNumber.length === 16 &&
      /^(\d{6,6})+[-]+(\d{2,2})+[-]+(\d{6,6})$/.test(accountNumber)
    ) {
      setAccountNumber(
        accountNumber
          .replace(/-/g, "")
          .replace(/(\d{6})(\d{2})(\d{6})/, "$1-$2-$3")
      );
      setAccountNumberText("");
      setAccountNumberCheck(true);
    } else {
      setAccountNumberText(
        "국민은행 계좌번호를 입력해 주세요. ex.12345612123456"
      );
      setAccountNumberCheck(false);
    }
  }, [accountNumber]);

  useEffect(() => {
    if (!/^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
      setEmailText("이메일 형식으로 입력해 주세요. ex.test@gmail.com");
      setEmailCheck(false);
    } else {
      setEmailText("");
      setEmailCheck(true);
    }
  }, [email]);

  useEffect(() => {
    // console.log(phone);
    if (phone.length === 11 && /^010?([0-9]{4})?([0-9]{4})$/.test(phone)) {
      setPhone(phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      setPhoneText("");
      setPhoneCheck(true);
    } else {
      setPhoneText("전화번호를 입력해 주세요. ex.01000000000");
      setPhoneCheck(false);
    }

    //하이픈 입력했을 때
    if (phone.length === 13 && /^010-?([0-9]{4})-?([0-9]{4})$/.test(phone)) {
      setPhone(
        phone.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
      setPhoneText("");
      setPhoneCheck(true);
    } else {
      setPhoneText("전화번호를 입력해 주세요. ex.01000000000");
      setPhoneCheck(false);
    }
  }, [phone]);

  useEffect(() => {
    // console.log(address);
  }, [address]);

  useEffect(() => {
    if (detailAddress !== "") {
      setDetailAddressCheck(true);
      setDetailAddressText("");
    } else {
      setDetailAddressCheck(false);
      setDetailAddressText("");
    }
  }, [detailAddress]);

  const RegisterIsolution = (e) => {
    e.preventDefault();

    const body = {
      userId: userIdCheck && userId,
      password: passwordCheck && password,
      name: nameCheck && name,
      email: emailCheck && email,
      birthDate: birthDateCheck && birthDate,
      // accountNumber: accountNumberCheck
      //   ? accountNumber?.replace("-", "").replace("-", "")
      //   : "",
      accountNumber:
        accountNumberCheck && accountNumber?.replace("-", "").replace("-", ""),
      // phone: phoneCheck ? phone?.replace("-", "").replace("-", "") : "",
      phone: phoneCheck && phone?.replace("-", "").replace("-", ""),
      address: address,
      addressDetail: detailAddress,
    };
    // console.log(body);
    if (rePassword === password) {
      api
        .post(`api/v1/users`, body)
        .then((res) => {
          // console.log(res);
          Swal.fire({
            title: "알림",
            text: `가입 완료`,
            icon: "success",
            confirmButtonText: "확인",
          });
          navigate("/");
        })
        .catch((err) => {
          // err?.response?.data?.code === 101
          //   ? Swal.fire(
          //       "User Duplication Fail",
          //       "이미 존재하는 아이디입니다.",
          //       "error"
          //     )
          //   : Swal.fire("Validation Failed", "정보를 입력해 주세요.", "error");
          Errorcode({ code: err?.response?.data?.code });
        });
    } else {
      return false;
    }
  };

  const onEnter = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      RegisterIsolution();
    }
  };

  const handleBirthDay = (e) => {
    // console.log(e.target.value);
    const regex =
      /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
    if (regex.test(e.target.value)) {
      setBirthDate(e.target.value);
    } else {
      setBirthDate(e.target.value);
    }
  };

  const handleAccountNumber = (e) => {
    // console.log(e.target.value);
    const regex = /^(\d{6,6})(\d{2,2})(\d{6,6})$/;
    if (regex.test(e.target.value)) {
      setAccountNumber(e.target.value);
    } else {
      setAccountNumber(e.target.value);
    }
  };

  const handlePhone = (e) => {
    // console.log(e.target.value);
    const regex = /^010?([0-9]{4})?([0-9]{4})$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    } else {
      setPhone(e.target.value);
    }
  };

  const openPostHandler = (e) => {
    e.preventDefault();
    setIsOpenPost(true);
  };

  return (
    <RegisterWrapper>
      <RegisterDiv>
        <div style={{ marginBottom: "15px" }}>
          {/* <LoginTitle1>ITECH </LoginTitle1>
          <LoginTitle2>CORP.</LoginTitle2> */}
          <img src={`${process.env.PUBLIC_URL}/Group.svg`} alt="itech_logo" />
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            fontSize: "12px",
            marginBottom: "10px",
          }}
        >
          {/* <span style={{ color: "#D33751" }}>
            *는 필수로 입력해야 합니다. <br />
            계좌번호와 전화번호는 추후 개인적인 수정이 불가능합니다.
          </span> */}
        </div>
        <form onKeyUp={onEnter}>
          <RegisterInput
            placeholder="이름을 입력하세요"
            type="text"
            onChange={(e) => setName(e.target.value)}
            alertText={nameText}
            iconCheck={name}
            checkIcon={nameCheck}
            // readOnly={false}
            required
          />
          <RegisterInput
            ref={idRef}
            placeholder="아이디를 입력하세요"
            type="text"
            onChange={(e) => setUserId(e.target.value)}
            alertText={userIdText}
            iconCheck={userId}
            checkIcon={userIdCheck}
            // readOnly={false}
            required
          />
          <RegisterInput
            placeholder="비밀번호를 입력하세요"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            iconCheck={password}
            alertText={passwordText}
            checkIcon={passwordCheck}
            // readOnly={false}
            required
          />
          <RegisterInput
            placeholder="비밀번호를 한 번 더 입력하세요"
            type="password"
            onChange={(e) => setRePassword(e.target.value)}
            iconCheck={rePassword}
            checkIcon={rePasswordCheck}
            alertText={rePasswordText}
            // readOnly={false}
            required
          />
          <RegisterInput
            placeholder="생일을 입력하세요"
            type="text"
            onChange={handleBirthDay}
            value={birthDate}
            iconCheck={birthDate}
            checkIcon={birthDateCheck}
            alertText={birthDateText}
            // readOnly={false}
            required
          />
          <RegisterInput
            placeholder="국민은행 계좌번호를 입력하세요"
            type="text"
            onChange={handleAccountNumber}
            value={accountNumber}
            iconCheck={accountNumber}
            checkIcon={accountNumberCheck}
            alertText={accountNumberText}
            // readOnly={false}
            required
          />
          <RegisterInput
            placeholder="이메일을 입력하세요"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            iconCheck={email}
            checkIcon={emailCheck}
            alertText={emailText}
            // readOnly={false}
            required
          />
          <RegisterInput
            placeholder="전화번호를 입력하세요"
            type="text"
            onChange={handlePhone}
            value={phone}
            iconCheck={phone}
            checkIcon={phoneCheck}
            alertText={phoneText}
            readOnly={false}
            required
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <RegisterInput
              placeholder="주소를 입력하세요"
              type="text"
              onChange={(e) => setAddress(e.target.value)}
              addressDiv
              noIconBox
              iconCheck={address}
              value={address}
              readOnly={isOpenPost ? false : true}
              required
            />
            <MyButton
              text="주소 찾기"
              color={"white"}
              padding={"0"}
              width={"130px"}
              height={"40px"}
              onClick={openPostHandler}
            />
            {isOpenPost && (
              <div className={"modal-address"} ref={postRef}>
                <Postcode
                  setAddress={setAddress}
                  setIsOpenPost={setIsOpenPost}
                />
              </div>
            )}
          </div>

          <RegisterInput
            placeholder="상세주소를 입력하세요"
            type="text"
            onChange={(e) => setDetailAddress(e.target.value)}
            iconCheck={detailAddress}
            id={"detail-address"}
            // readOnly={false}
            checkIcon={detailAddressCheck}
            alertText={detailAddressText}
          />
        </form>
        <div style={{ marginBottom: "5px" }}>
          <span style={{ fontSize: "12px", color: "gray" }}>
            {userIdCheck &&
            passwordCheck &&
            nameCheck &&
            emailCheck &&
            birthDateCheck &&
            accountNumberCheck &&
            phoneCheck &&
            address !== "" &&
            detailAddress !== ""
              ? "승인 요청 버튼이 활성화 되었습니다."
              : "형식이 올바르지 않거나 빈칸을 채우지 않으면 승인 요청 버튼이 활성화 되지 않습니다."}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <MyButton
            text="작성 취소"
            onClick={(e) => navigate("/")}
            color={"white"}
            background={"danger"}
            width={"196px"}
            height={"40px"}
          />
          {/* <MyButton
            text="승인 요청"
            onClick={RegisterIsolution}
            color={"white"}
            width={"196px"}
            height={"40px"}
            disabled={
              userIdCheck &&
              passwordCheck &&
              nameCheck &&
              emailCheck &&
              birthDateCheck &&
              accountNumberCheck &&
              phoneCheck &&
              address !== ""
                ? false
                : true
            }
          /> */}
          <RegisterBtn
            onClick={RegisterIsolution}
            disabled={
              userIdCheck &&
              passwordCheck &&
              nameCheck &&
              emailCheck &&
              birthDateCheck &&
              accountNumberCheck &&
              phoneCheck &&
              address !== "" &&
              detailAddress !== ""
                ? false
                : true
            }
          >
            승인 요청
          </RegisterBtn>
        </div>
      </RegisterDiv>
    </RegisterWrapper>
  );
};

const RegisterWrapper = styled.div`
  ${ColumnCenter}
  width: 100%;
  height: 100vh;
`;

const RegisterDiv = styled.div`
  ${ColumnCenter}
  width: 420px;
  position: relative;
`;
const LoginTitle1 = styled.span`
  color: #3752d3;
  font-size: 45px;
  font-weight: 700;
`;

const LoginTitle2 = styled.span`
  color: #3752d3;
  font-size: 30px;
  font-weight: 700;
`;

const RegisterBtn = styled.button`
  cursor: pointer;
  color: white;
  width: 196px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;

  background: #3752d3;
  &:hover {
    background: ${lighten(0.1, "#3752d3")};
  }
  &:active {
    background: ${darken(0.1, "#3752d3")};
  }
`;

export default Register;
