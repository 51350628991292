import { Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  MyContainer,
  MyContentContainer,
  MyDetailContent,
  MyDetailButtonContainerWrap,
  MyDetailContainerWrap,
  MyDetailFileContainerWrap,
  MyDetailInnerContainer,
  MyMainContainer,
} from "../../styled/Container";
import Title from "../Title";
import FileDownload from "./FileDownload";
import DetailDateAndTitle from "../DetailDateAndTitle";
import Buttons from "../Buttons";
import Comments from "./Comments";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../MyButton";

const ForumDetailLayout = ({
  subtitle,
  title,
  contentTitle,
  createDate,
  modifyDate,
  contents,
  files,
  goToEdit,
  id,
  setPage,
  page,
  teamName,
  userName,
}) => {
  const myRole = localStorage.getItem("role");
  const [content, setContent] = useState("");
  const filesList = useSelector((state) => state.forum.files);
  const forumHeight = filesList?.files?.length !== 0 ? "480" : "650";
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setContent(contents);
    // console.log(filesList.files);
    // console.log(createDate);
  }, [contents]);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const goToList = () => {
    const pathnameArray = window.location.pathname.split("/");
    navigate(`/${pathnameArray[1]}`);
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px" }}
    >
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <Title subtitle={subtitle} title={title} />
            <ContentContainer>
              <DetailContainerWrap height={forumHeight}>
                <DetailInnerContainer>
                  {location.pathname === `/meeting/${id}` ? (
                    <MeetingDetailTitle>
                      <DetailDateAndTitle
                        date={createDate}
                        title={contentTitle}
                      />
                      <MeetingDetailInfo>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>작성자</span>
                          <div
                            style={{
                              background: "#4285F4",
                              color: "white",
                              fontSize: "15px",
                              fontWeight: "500",
                              padding: "5px 10px",
                              borderRadius: "18px",
                              margin: "0px 5px",
                            }}
                          >
                            {userName}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            필요부서
                          </span>
                          <div
                            style={{
                              background: "#4285F4",
                              color: "white",
                              fontSize: "15px",
                              fontWeight: "500",
                              padding: "5px 10px",
                              borderRadius: "18px",
                              margin: "0px 0px 0px 5px",
                            }}
                          >
                            {teamName}
                          </div>
                        </div>
                      </MeetingDetailInfo>
                    </MeetingDetailTitle>
                  ) : (
                    <DetailDateAndTitle
                      date={createDate}
                      title={contentTitle}
                    />
                  )}

                  <DetailContent>
                    {content === contents ? (
                      <Viewer initialValue={content} />
                    ) : null}
                  </DetailContent>
                </DetailInnerContainer>
              </DetailContainerWrap>
              {/* files */}
              {location.pathname === `/anonymous/${id}` ||
              location.pathname === `/anonymous/secret/${id}` ? (
                <Comments page={page} setPage={setPage} />
              ) : (
                filesList?.files?.length !== 0 && (
                  <>
                    <DetailFileContainerWrap>
                      <DetailInnerContainer>
                        <FileDownload />
                      </DetailInnerContainer>
                    </DetailFileContainerWrap>
                  </>
                )
              )}
              <DetailButtonContainerWrap>
                {location.pathname === `/master-notices/${id}` &&
                myRole === "ROLE_LV4" &&
                resize >= 995 ? (
                  <Buttons text={"수정"} onClick={goToEdit} color={"white"} />
                ) : null}
                {location.pathname === `/master-notices/${id}` &&
                myRole === "ROLE_LV4" &&
                resize < 995 ? (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={"수정"}
                    onClick={goToEdit}
                    margin={"0px 5px 10px 5px"}
                  />
                ) : null}

                {location.pathname === `/notices/${id}` &&
                (myRole === "ROLE_LV2" || myRole === "ROLE_LV3") &&
                resize >= 995 ? (
                  <Buttons text={"수정"} onClick={goToEdit} color={"white"} />
                ) : null}
                {location.pathname === `/notices/${id}` &&
                (myRole === "ROLE_LV2" || myRole === "ROLE_LV3") &&
                resize < 995 ? (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={"수정"}
                    onClick={goToEdit}
                    margin={"0px 5px 10px 5px"}
                  />
                ) : null}

                {location.pathname === `/meeting/${id}` &&
                (myRole === "ROLE_LV1" || myRole === "ROLE_LV2") &&
                resize >= 995 ? (
                  <Buttons text={"수정"} onClick={goToEdit} color={"white"} />
                ) : null}
                {location.pathname === `/meeting/${id}` &&
                (myRole === "ROLE_LV1" || myRole === "ROLE_LV2") &&
                resize < 995 ? (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={"수정"}
                    onClick={goToEdit}
                    margin={"0px 5px 10px 5px"}
                  />
                ) : null}

                {resize >= 995 ? (
                  <Buttons
                    onClick={goToList}
                    text={"뒤로가기"}
                    background={"trans"}
                    border={"1px solid #232323"}
                  />
                ) : (
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={"뒤로가기"}
                    background={"cancel"}
                    color={"black"}
                    onClick={goToList}
                    border={"1px solid #232323"}
                    margin={"0px 5px 10px 5px"}
                  />
                )}
              </DetailButtonContainerWrap>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const MainContainer = styled.div`
  ${MyMainContainer}
`;

const Container = styled.div`
  ${MyContainer}
`;

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const DetailContainerWrap = styled.div`
  ${MyDetailContainerWrap}
`;

const DetailFileContainerWrap = styled.div`
  ${MyDetailFileContainerWrap}
`;

const DetailButtonContainerWrap = styled.div`
  ${MyDetailButtonContainerWrap}
`;

const DetailInnerContainer = styled.div`
  ${MyDetailInnerContainer}
`;

const DetailContent = styled.div`
  ${MyDetailContent}
`;

const MeetingDetailTitle = styled.div`
  display: flex;

  @media screen and (max-width: 995px) {
    flex-direction: column;
  }
`;

const MeetingDetailInfo = styled.div`
  display: flex;
  width: 600px;
  justify-content: end;
  align-items: end;

  @media screen and (max-width: 995px) {
    width: 100%;
    margin-top: 5px;
  }
`;

export default ForumDetailLayout;
