import { useState, useCallback } from "react";

export function useInput(initialValue = null) {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback((e) => {
    setValue(e.target.value);
  }, []);
  return [value, handler, setValue];
}

// export function useDate(initialValue = null) {
//   const [value, setValue] = useState(initialValue);
//   const [hyphenValue, setHyphenValue] = useState(initalValue);

//   const handler = useCallback((e) => {
//     const HyphenStr = hyphenValue.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
//   }, [value])

//   // const datestr = data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
//   return [value, handler, setValue]
// }
