import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // width: 90%;
  padding: 10px;
  // border: 1px solid black;
  //   height: 80px;
  height: 100%;
`;

export const DashboardListContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  // height: 70%;
  padding: 10px;
  // border: 1px solid black;
`;

export const DashboardListContent = styled.div`
  display: flex;
  background: #f3f8ff;
  border-radius: 30px;
  width: 100%;

  padding: 20px 10px;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const DashboardDate = styled.div`
  display: flex;
  background: #c9e4ff;
  border-radius: 15px;
  padding: 0px 10px;
  color: #5886bd;
`;

export const DashboardContent = styled.div`
  display: flex-start;
  width: 100%;
`;

export const DashboardOverviewButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #3752d3;
  fontsize: 14px;
  font-weight: 700;
  cursor: pointer;
  height: 40px;
`;
