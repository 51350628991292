import styled, { css } from "styled-components";
import {
  Column,
  ColumnAlignCenter,
  // ColumnCenter
} from "./Flexbox";

export const MyContainer = css`
  // min-width: 1630px;
  width: 100%;
  height: 100%;
  ${Column}
  align-items: center;
`;

export const MyMainContainer = css`
  // min-width: 1580px;
  width: 98%;
  min-height: 860px;
  background-color: white;
  border-radius: 10px;
  margin-top: 30px;
  ${ColumnAlignCenter}

  position: relative;
`;

export const MyContainerWrap = css`
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  // width: 1500px;
  // width: ${(props) => props.width || "1540"}px;
  width: 100%;
  min-height: 660px;
  border-radius: 10px;
  align-items: center;
  box-shadow: ${(props) => props.boxShadow};
`;

export const MyDetailContainerWrap = css`
  background: #f3f8ff;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${(props) => props.height}px;

  border-radius: 10px;
  align-items: center;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.07);
`;

export const MyEditContainerWrap = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 480px;
  border-radius: 10px;
  align-items: center;
`;

export const MyDetailFileContainerWrap = css`
  background: #f3f8ff;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  margin-top: 20px;
  align-items: center;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.07);
`;

export const MyDetailCommentsWrap = css`
  background: #f3f8ff;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 240px;
  border-radius: 10px;
  margin-top: 20px;
  align-items: center;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.07);
`;

export const MyEditFileContainerWrap = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  border: 1px solid #b2b8c1;
  margin-top: 20px;
  align-items: center;
`;

export const MyDetailButtonContainerWrap = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  align-items: center;
  margin-top: 7px;
  justify-content: flex-end;
`;

export const MyDetailInnerContainer = css`
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: 0;
`;

export const MyDetailContent = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  min-height: 0px;
`;

export const MyContentContainer = css`
  // width: 1540px;
  width: 100%;
  min-height: 660px;
  ${ColumnAlignCenter}
`;

export const MyPaginationContainer = css`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 98%;
  margin-top: 20px;
  height: 60px;
  position: relative;
`;

export const MyDashboard = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 92vh;
  background: linear-gradient(180deg, #e9f0f8 0%, #f3f3f3 26.82%);

  // background: pink;
`;

export const MyDashboardContainer = css`
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 95%;
  // background-color: yellow;
`;

export const MyDashboardRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  // background-color: skyblue;
`;

export const MyDashboardColumn = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
`;

export const MyDashboradSection = css`
  display: flex;
  ${ColumnAlignCenter}
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: white;
  border-radius: 10px;
  // border: 1px solid black;
`;

// ${(props) =>
//   props.minHeight390 &&
//   css`
//     min-height: 390px;
//   `}
