// 랴이브러리
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";

// component
import BasicList from "../../components/BasicList";
import Blank from "../../components/Blank";
import Buttons from "../../components/Buttons";
import Pagination2 from "../../components/paging/Pagination2";
import Title from "../../components/Title";
import Loading from "../../components/Loading";

// filter
import FilterTeamName from "../../components/day-off/FilterTeamName";
import FilterCalander from "../../components/FilterCalander";

// util
import { ExcellQuery } from "../../hooks/useExcellQuery";
import api from "../../redux/api";

import {
  MyContainerWrap,
  MyContentContainer,
  MyPaginationContainer,
} from "../../styled/Container";
import { MyMainForumContainer } from "../../styled/Forum";

import {
  MyTitleAndSelect,
  MyTitleList,
  MyTitleListSpan,
} from "../../styled/ListStyle";
import FilterUserName from "../../components/day-off/FilterUserName";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../../components/MyButton";

const DayOff = () => {
  const navigate = useNavigate();
  const onClickRegister = useCallback(() => {
    navigate("/day-off/register");
  }, [navigate]);

  const myRole = localStorage.getItem("role");

  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);
  const [dayOffData, setDayOffData] = useState({});
  const [contentData, setContentData] = useState([]);

  // 날짜
  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat =
    moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");

  const defaultValue = [{ value: 0, label: "전체보기" }];

  // 부서
  const [teamList, setTeamList] = useState([]);
  const [nameList, setNameList] = useState([]);

  // 로딩
  const [loading, setLoading] = useState(false);

  // reducer
  const teamValue = useSelector((state) => state.select.teamValue);
  const teamNameValue = useSelector((state) => state.select.teamNameValue);
  const nameValue = useSelector((state) => state.select.nameValue);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    myRole === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  // TODO: 부서, 회원 리스트
  useEffect(() => {
    myRole !== "ROLE_LV1" &&
      api.get(`/api/v1/teams`).then((res) => {
        setTeamList(
          res.data.list
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );
      }) &&
      (((teamValue === undefined || teamValue === 0) &&
        api.get(`/api/v1/users?status=Y`).then((res) => {
          setNameList(
            res?.data?.list
              ?.map((item) => [{ value: item.name, label: item.name }])
              .map((item) => item[0])
          );
        })) ||
        (teamValue !== undefined &&
          teamValue !== 0 &&
          teamValue !== 1 &&
          api.get(`/api/v1/teams/${teamValue}`).then((res) => {
            // console.log(res);
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.name, label: item.name }])
                .map((item) => item[0])
            );
          })) ||
        (teamValue === 1 &&
          api.get(`/api/v1/teams/leaves`).then((res) => {
            // console.log(res);
            setNameList(
              res?.data?.list
                ?.map((item) => [{ value: item.name, label: item.name }])
                .map((item) => item[0])
            );
          })));
  }, [teamValue]);

  // console.log("contentData: ", contentData);

  // TODO: 엑셀 다운로드
  const onClickExcellDownload = useCallback(() => {
    // console.log("엑셀 다운로드");
    const date = new Date();
    const fileDate = moment(date).format("YYYY-MM-DD_HH:mm:ss");

    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    const teamNameQuery =
      teamNameValue === undefined
        ? ""
        : teamNameValue === "전체보기"
        ? ""
        : `&teamName=${teamNameValue}`;

    const userNameQuery =
      nameValue === undefined
        ? ""
        : nameValue === 0
        ? ""
        : `&userName=${nameValue}`;

    let query = dateQuery + userNameQuery + teamNameQuery;
    const excellQuery = ExcellQuery(query);

    const config = {
      responseType: "blob",
    };

    setLoading(true);

    api
      .get(`/api/v1/day-off/excell${excellQuery}`, config)
      .then((res) => {
        // console.log("res", res);

        // ??
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `day-off_${fileDate}.xls`);
        document.body.appendChild(link);
        link.click();

        setLoading(false);

        Swal.fire({
          title: "알림",
          text: "문서를 다운 받았습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: "알림",
          text: "현재 문서를 다운 받을 수 없습니다.",
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  }, [startDate, endDate, teamNameValue, nameValue]);

  // TODO: 페이지 loadAPI
  useEffect(() => {
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    const teamNameQuery =
      teamNameValue === undefined
        ? ""
        : teamNameValue === "전체보기"
        ? ""
        : `&teamName=${teamNameValue}`;

    const userNameQuery =
      nameValue === undefined
        ? ""
        : nameValue === 0
        ? ""
        : `&userName=${nameValue}`;

    myRole === "ROLE_LV1"
      ? api
          .get(`/api/v1/day-off?page=${page}&size=9${dateQuery}`)
          .then((res) => {
            setContentData(res?.data?.data?.content);
            setDayOffData(res?.data?.data);
            setTotalElements(res?.data?.data?.totalElements);
            setSize(res?.data?.data?.size);
          })
      : api
          .get(
            `/api/v1/day-off?page=${page}&size=9${dateQuery}${userNameQuery}${teamNameQuery}`
          )
          .then((res) => {
            setContentData(res?.data?.data?.content);
            setDayOffData(res?.data?.data);
            setTotalElements(res?.data?.data?.totalElements);
            setSize(res?.data?.data?.size);
          });
  }, [page, startDate, endDate, teamNameValue, nameValue]);

  // console.log("휴가데이터: ", contentData);

  return (
    <Grid container spacing={0} sx={{ borderRadius: "10px" }}>
      {loading ? <Loading /> : null}
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px", width: "100%" }}>
          {/* <Title title={"휴가"} subtitle={"T&A"} />
            <ContainerWrap
              background={"#F3F8FF"}
              boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
            ></ContainerWrap> */}
          <Box p={3}>
            <ContentContainer>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  padding: "0px 10px",
                }}
              >
                <Title title={"휴가"} subtitle={"T&A"} />
                {myRole === "ROLE_LV3" ? null : myRole === "ROLE_LV4" ? null : (
                  <div
                    style={{
                      display: resize < 995 ? "flex" : "none",
                      // border: "1px solid black",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MyButton
                      width={"60px"}
                      height={"40px"}
                      text={"+"}
                      onClick={onClickRegister}
                    />
                  </div>
                )}
              </div>
              {/* <Grid sx={{ width: "100%", background: "yellow" }}> */}
              <ContainerWrap
                background={"#F3F8FF"}
                boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
              >
                <TitleAndSelect>
                  <span>휴가 이력</span>
                  <div style={{ display: "flex" }}>
                    {/* level1 */}
                    {myRole === "ROLE_LV1" ? (
                      <Filters>
                        <FilterCalander setPage={setPage} />
                      </Filters>
                    ) : myRole === "ROLE_LV2" ? (
                      <>
                        <Filters>
                          <FilterCalander setPage={setPage} />
                          {/* FIXME:  부서, 이름*/}
                          <FilterTeamName
                            width={resize < 1520 ? 130 : 180}
                            text={"부서"}
                            myOptions={[defaultValue[0], ...teamList]}
                            setPage={setPage}
                          />
                          <FilterUserName
                            width={resize < 1520 ? 130 : 180}
                            text={"이름"}
                            myOptions={[defaultValue[0], ...nameList]}
                            setPage={setPage}
                          />
                        </Filters>

                        <ExcelDiv>
                          <RiFileExcel2Fill
                            color="#3752D3"
                            onClick={onClickExcellDownload}
                          />
                        </ExcelDiv>
                      </>
                    ) : myRole === "ROLE_LV3" ? (
                      <>
                        <Filters>
                          <FilterCalander setPage={setPage} />
                          <FilterTeamName
                            width={resize < 1520 ? 130 : 180}
                            text={"부서"}
                            myOptions={[defaultValue[0], ...teamList]}
                            setPage={setPage}
                          />
                          <FilterUserName
                            width={resize < 1520 ? 130 : 180}
                            text={"이름"}
                            myOptions={[defaultValue[0], ...nameList]}
                            setPage={setPage}
                          />
                        </Filters>

                        <ExcelDiv>
                          <RiFileExcel2Fill
                            color="#3752D3"
                            onClick={onClickExcellDownload}
                          />
                        </ExcelDiv>
                      </>
                    ) : (
                      <>
                        <Filters>
                          <FilterCalander setPage={setPage} />
                          {/* FIXME:  부서, 이름*/}
                          <FilterTeamName
                            width={resize < 1520 ? 130 : 180}
                            text={"부서"}
                            myOptions={[defaultValue[0], ...teamList]}
                            setPage={setPage}
                          />
                          <FilterUserName
                            width={resize < 1520 ? 130 : 180}
                            text={"이름"}
                            myOptions={[defaultValue[0], ...nameList]}
                            setPage={setPage}
                          />
                        </Filters>

                        <ExcelDiv>
                          <RiFileExcel2Fill color="#3752D3" />
                        </ExcelDiv>
                      </>
                    )}
                  </div>
                </TitleAndSelect>
                {contentData?.length <= 0 ? (
                  <Blank text={"등록된 문서가 없습니다."} />
                ) : (
                  <>
                    <ForumContainer>
                      {resize >= 995 ? (
                        <TitleList>
                          <TitleListSpan spanWidth={"18%"}>
                            작성일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"14%"}>부서</TitleListSpan>
                          <TitleListSpan spanWidth={"14%"}>이름</TitleListSpan>
                          <TitleListSpan spanWidth={"10%"}>타입</TitleListSpan>
                          <TitleListSpan spanWidth={"18%"}>
                            시작일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"18%"}>
                            종료일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"14%"}>
                            차감수
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"11%"}>상태</TitleListSpan>
                          <TitleListSpan spanWidth={"11%"}>
                            시간차
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"11%"}>
                            총시간
                          </TitleListSpan>
                        </TitleList>
                      ) : (
                        <TitleList>
                          <TitleListSpan spanWidth={"22%"}>
                            작성일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"23%"}>이름</TitleListSpan>
                          <TitleListSpan spanWidth={"15%"}>타입</TitleListSpan>
                          <TitleListSpan spanWidth={"25%"}>
                            휴가일자
                          </TitleListSpan>
                          <TitleListSpan spanWidth={"15%"}>상태</TitleListSpan>
                        </TitleList>
                      )}

                      {contentData?.map((item) => {
                        return (
                          <BasicList
                            key={item.id}
                            id={item?.id}
                            name={"day-off"}
                            date={item?.createDate}
                            team={item?.teamName}
                            writer={item?.userName}
                            dayOffType={item?.type}
                            startTime={item?.startDate}
                            endTime={item?.endDate}
                            hours={item?.hours}
                            dayOffStatus={item?.cancelDate}
                            deductionDay={item?.deductionDay}
                            // 시간차 카테고리
                            timeOffType={item?.timeOffType}
                          />
                        );
                      })}
                    </ForumContainer>
                  </>
                )}
              </ContainerWrap>
              {/* </Grid> */}

              <PaginationContainer>
                <Pagination2
                  page={page + 1}
                  totalPages={dayOffData?.totalPages}
                  setPage={setPage}
                  size={size}
                  totalElements={totalElements}
                />
                {myRole === "ROLE_LV3" ? null : myRole === "ROLE_LV4" ? null : (
                  <div
                    style={{
                      display: resize < 995 ? "none" : "",
                    }}
                  >
                    <Buttons
                      onClick={onClickRegister}
                      text={"휴가 작성"}
                      color={"white"}
                    />
                  </div>
                )}
              </PaginationContainer>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
`;

const TitleList = styled.div`
  ${MyTitleList}
`;

const TitleListSpan = styled.div`
  ${MyTitleListSpan}
`;

const ForumContainer = styled.div`
  ${MyMainForumContainer}
`;

const Filters = styled.div`
  display: flex;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const ExcelDiv = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3752d3;
  margin-left: 15px;
  cursor: pointer;
  font-size: 20px;
`;

export default DayOff;
