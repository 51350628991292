import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination2 from "../../../components/paging/Pagination2";
import Title from "../../../components/Title";
import api from "../../../redux/api";
import { MyContentContainer, MyPaginationContainer } from "../../../styled/Container";
import { MyTitleAndSelect } from "../../../styled/ListStyle";
import TextArea from "../../../components/forum/TextArea";
import { useDispatch, useSelector } from "react-redux";
import forumReducer from "../../../redux/reducers/forumReducer";
import DateAndTitle from "../../../components/DateAndTitle";
import moment from "moment";
import Blank from "../../../components/Blank";
import Swal from "sweetalert2";
import Modal from "../../../components/Modal";

import Loading from "../../../components/Loading";
import { Box, Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterCalander2 from "../../../components/FilterCalander2";

const Idea = () => {
  const [ideaList, setIdeaList] = useState([]);
  const [ideaTotalPage, setIdeaTotalPage] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);

  const [textValue, setTextValue] = useState("");
  const [textLength, setTextLength] = useState(0);
  const [openCheckModal, setOpenCheckModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const filteredFileIdList = useSelector((state) => state.forum.filteredFileIdList);
  const allFilteredFileList = useSelector((state) => state.forum.allFilteredFileList);
  const dispatch = useDispatch();

  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat = moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    accessToken === null && navigate("/login");
    // console.log(startDate);
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    // ResetPage(dateQuery, setPage, 0);

    api.get(`/api/v1/ideas?page=${page}&size=6${dateQuery}`).then((res) => {
      // console.log(res);
      setIdeaTotalPage(res.data.data.totalPages);
      setIdeaList(res.data.data.content);
      setTotalElements(res.data.data.totalElements);
      setSize(res.data.data.size);
    });
  }, [page, startDate, endDate]);

  const uploadIdea = () => {
    const body = {
      contents: textValue,
      files: filteredFileIdList,
    };
    setOpenCheckModal(false);
    setLoading(true);

    api
      .post(`/api/v1/ideas`, body)
      .then((res) => {
        setLoading(false);
        // console.log(res.data);
        Swal.fire({
          title: "알림",
          text: "등록이 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
        dispatch(forumReducer.actions.deleteFileListFilter(allFilteredFileList));
        dispatch(forumReducer.actions.deleteeFileIdFilter(filteredFileIdList));
        setIdeaList((prev) => [res.data.data, ...prev].splice(0, 6));
        setTextValue("");
      })
      .catch((err) => {
        setOpenCheckModal(false);
        setLoading(false);
        err &&
          Swal.fire({
            title: "No contents",
            text: "컨텐츠를 입력해 주세요.",
            icon: "error",
            confirmButtonText: "확인",
          });
      });
  };

  const downloadAllFiles = (idx, e) => {
    if (ideaList.map((item) => item.id === e.target.id)) {
      const filesId = ideaList[idx].files?.map((item) => item.fileId);
      const originFileName = ideaList[idx].files?.map((item) => item.originFileName);
      for (let i = 0; i < filesId.length; i++) {
        const config = {
          responseType: "arraybuffer",
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);
        api.get(`api/v1/files/${filesId[i]}`, config).then((res) => {
          setLoading(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${originFileName[i]}`);
          document.body.appendChild(link);
          link.click();
        });
      }
    } else {
      return false;
    }
  };

  const showFileList = (files) => {
    const fileNameArray = files?.map((item) => item?.originFileName);
    // console.log(fileNameArray);

    Swal.fire({
      title: "파일 리스트",
      html: `${fileNameArray?.join("<br>")}`,
    });
  };

  return (
    <>
      <Grid container spacing={0}>
        {loading ? <Loading /> : null}
        {openCheckModal ? (
          <Modal
            title={"게시물 등록"}
            content={"게시물을 등록하시겠습니까?"}
            onClick={uploadIdea}
            setOpenModal={setOpenCheckModal}
          />
        ) : null}
        <Grid item xs={12} lg={12}>
          <Card sx={{ borderRadius: "10px" }}>
            <Box p={2}>
              <ContentContainer>
                <Title subtitle={"Idea"} title={"아이디어 게시판"} />

                <ContainerWrap background={"#F3F8FF"}>
                  <TitleAndSelect width={"100%"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Filters>
                        <FilterCalander2 setPage={setPage} />
                      </Filters>
                    </div>
                  </TitleAndSelect>

                  <Grid container spacing={0} mb={3} justifyContent={"space-between"}>
                    <TextArea
                      onClick={() => setOpenCheckModal(true)}
                      setTextValue={setTextValue}
                      setTextLength={setTextLength}
                      textValue={textValue}
                      textLength={textLength}
                      setLoading={setLoading}
                    />
                  </Grid>

                  {ideaList.length <= 0 ? (
                    <Blank text={"등록된 문서가 없습니다."} height={"500"} />
                  ) : (
                    <Grid container spacing={0}>
                      {ideaList?.map((item, idx) => (
                        <Grid item xs={12} md={6} lg={4} key={`card_${idx}`}>
                          <MyForumCard>
                            <InfoContent>
                              <DateAndTitle
                                date={item.createDate}
                                writerName={item.writer?.name}
                                rankName={item.writer?.rankName}
                                email={item.writer?.email}
                              />
                            </InfoContent>

                            <MainContent>
                              {item.contents.split("\n").map((line, index) => {
                                // console.log(item.contents);
                                return <p key={`line_${idx}_${index}`}>{line}</p>;
                              })}
                            </MainContent>
                            <SubFiles>
                              <div>
                                <h4
                                  style={{
                                    cursor: item?.files?.length !== 0 ? "pointer" : "default",
                                  }}
                                  onClick={
                                    item?.files?.length !== 0
                                      ? () => showFileList(item?.files)
                                      : null
                                  }
                                >
                                  {item?.files?.length}개
                                </h4>
                                <span>의 첨부파일이 있습니다.</span>
                              </div>
                              {item?.files?.length === 0 ? (
                                <div style={{ justifyContent: "end" }}></div>
                              ) : (
                                <div style={{ justifyContent: "end" }}>
                                  <button id={item.id} onClick={(e) => downloadAllFiles(idx, e)}>
                                    첨부파일 전체 다운로드
                                  </button>
                                </div>
                              )}
                            </SubFiles>
                          </MyForumCard>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </ContainerWrap>

                <PaginationContainer>
                  <Pagination2
                    page={page + 1}
                    totalPages={ideaTotalPage}
                    setPage={setPage}
                    totalElements={totalElements}
                    size={size}
                  />
                </PaginationContainer>
              </ContentContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;
const ContainerWrap = styled.div`
  min-height: 660px;
  align-items: center;
  width: 100%;
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
`;

const MyForumCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 32%;
  height: 340px;
  background: #f3f8ff;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  @media screen and (max-width: 1570px) {
    height: 400px;
  }

  @media screen and (max-width: 1330px) {
    height: 440px;
  }

  @media screen and (max-width: 1199px) {
    height: 340px;
  }
  @media screen and (max-width: 470px) {
    height: 390px;
  }
`;

const InfoContent = styled.div`
  min-height: 55px;
  width: 95%;
`;

const MainContent = styled.div`
  // display: flex;
  padding-top: 5px;
  width: 95%;
  min-height: 200px;
  border-bottom: 1px solid #232323;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: scroll;
  // background: yellow;

  p {
    padding: 0;
    margin: 0.3em;
    font-size: 15px;
    widht: 100%;
    overflow: hidden;
    word-break: break-all;
    white-space: normal;
  }

  @media screen and (max-width: 470px) {
    font-size: 14px;
  }
`;

const SubFiles = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 20%;

  div {
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;

    h4 {
      font-weight: 700;
      font-size: 16px;
      color: #3752d3;
      letter-spacing: 0.1em;
    }
    span {
      letter-spacing: 0.1em;
      font-size: 14px;
      color: #232323;
      margin-top: 2px;
      font-weight: 500;
    }

    button {
      color: #3752d3;
      font-weight: 700;
      font-size: 14px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;

const Filters = styled.div`
  display: flex;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

export default Idea;
