import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // 지출 이력
  createdDate: "",
  title: "",
  payType: "",
  status: "",

  // 지출결의 상세
};

const paymentReducer = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentListData: (state, action) => {
      state.createdDate = action.payload.createdDate;
      state.title = action.payload.title;
      state.payType = action.payload.payType;
      state.status = action.payload.status;
    },
  },
});

export default paymentReducer;
