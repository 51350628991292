import React from "react";
import styled from "styled-components";

export const TypeArr = ["송금", "법인카드"];

export const CategoryArr = ["세금계산서", "현금영수증", "없음"];

export const ContentArr = [
  "교통비",
  "기타",
  "도서구입",
  "복리후생비",
  "부가가치세",
  "사무비품",
  "소모품비",
  "숙박비",
  "식대",
  "용역비",
  "접대비",
  "주유비",
  "주차비",
  "지급수수료",
  "출장비",
  "환불 지급",
];

export const BankArr = [
  "경남",
  "광주",
  "국민",
  "기업",
  "농협중앙",
  "대구",
  "부산",
  "산업",
  "상호저축은행",
  "새마을금고",
  "수출입",
  "수협",
  "신한",
  "신협",
  "외환",
  "우리",
  "우체국",
  "전북",
  "제주",
  "지역농협",
  "축협",
  "카카오뱅크",
  "케이뱅크",
  "하나",
  "한국",
  "한국시티",
  "S C제일",
];

export const 지출부서 = ["아직", "작업이", "덜 된", "기능", "입니다"];

export const RegisterArraySelect = ({ data, onChangeData }) => {
  return (
    <RegisterSelect
      onChange={(e) => {
        onChangeData(e.currentTarget.value);
      }}
    >
      {data.map((item) => {
        return (
          <option key={item} value={item}>
            {item}
          </option>
        );
      })}
    </RegisterSelect>
  );
};

const RegisterSelect = styled.select`
  width: 100px;
  height: 35px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  margin-left: 5px;
`;
