import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import api from "../../redux/api";
import { useInput } from "../../hooks/useInput";
import Swal from "sweetalert2";

import AuthorInfo from "../../components/AuthorInfo";
import Title from "../../components/Title";
import {
  MyContainerWrap,
  MyContentContainer,
  MyDetailButtonContainerWrap,
} from "../../styled/Container";
import { MyPayInfoContainer } from "../../styled/Payment";
import { PayInfoWrap } from "./Detail";

import {
  TypeArr,
  CategoryArr,
  TimeOffArr,
} from "../../components/day-off/RegisterArray";
import PayInfoType from "../../components/PayInfoType";
import Buttons from "../../components/Buttons";
import Modal from "../../components/Modal";
import moment from "moment";
import Loading from "../../components/Loading";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../../components/MyButton";
import Errorcode from "../../util/Errorcode";

const DayOffRegister = () => {
  const myName = localStorage.getItem("name");
  const myTeam = localStorage.getItem("team");
  const myPosition = localStorage.getItem("position");

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/day-off");
  };

  const [contentData, setContentData] = useState({});

  // TODO: Modal
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRegistModal, setOpenRegistModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem("token");

  // 선택
  const [payType, setPayType] = useState(TypeArr[0]);
  const [category, setCategory] = useState(CategoryArr[0]);
  const onClickPayType = useCallback((e) => {
    setPayType(e.target.innerText);
  }, []);
  const onClickCategory = useCallback((e) => {
    setCategory(e.target.innerText);
  }, []);

  // TODO: 시간차 시간 카테고리
  const [time, setTime] = useState(TimeOffArr[0]);
  const onChangeTime = useCallback((e) => {
    setTime(e.target.innerText);
  }, []);

  // console.log("시간차 종류: ", time);

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    accessToken === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const [startDate, onChangeStartDate, setStartDate] = useInput("");
  const [endDate, onChangeEndDate, setEndDate] = useInput("");
  const [hours, onChangeHours, setHours] = useInput("0일");
  const hour = hours.length === 3 ? hours.substr(0, 1) : hours.substr(0, 2);

  const start_date = startDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
  const end_date = endDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");

  const date = new Date();
  const today = moment(date).format("YYYY-MM-DD");
  let vacation = 0;

  if (category === "기간") {
    let minusDay = new Date(end_date) - new Date(start_date);

    let day = minusDay.toString().slice(0, -3);

    let dayday = day / 3600 / 24;
    vacation = dayday + 1;
  }

  useEffect(() => {
    if (category === "기간") {
      let minusDay = new Date(end_date) - new Date(start_date);
      let day = minusDay.toString().slice(0, -3);

      let dayday = day / 3600 / 24;
      vacation = dayday + 1;

      if (start_date.length === 10 && end_date.length === 10) {
        if (vacation >= 1) {
          setHours(vacation + "일");
        }
      } else {
        setHours("0일");
      }
    } else {
      setHours("");
    }
  }, [category, start_date, end_date]);

  const [comment, onChangeComment, setComment] = useInput("");

  const onClickCancel = useCallback(() => {
    setOpenCancelModal(true);
  }, [openCancelModal]);

  const onClickRegister = useCallback(() => {
    setOpenRegistModal(true);
  }, [openRegistModal]);

  const onHandleRegister = useCallback(() => {
    let body = {};

    if (vacation < 0) {
      setOpenCancelModal(false);
      setOpenRegistModal(false);
      return Swal.fire("알림", "날짜를 다시 확인해주세요.");
    }

    if (start_date < today) {
      setOpenCancelModal(false);
      setOpenRegistModal(false);
      return Swal.fire("알림", "이미 지난 날짜입니다.");
    }

    if (category === "기간") {
      body = {
        type:
          payType === "유급"
            ? "U"
            : payType === "무급"
            ? "M"
            : payType === "포상"
            ? "R"
            : "F",
        dayOffType: "D",
        start_date: start_date,
        end_date: end_date,
        hours: null,
        comment: comment,
      };
    } else {
      body = {
        type:
          payType === "유급"
            ? "U"
            : payType === "무급"
            ? "M"
            : payType === "포상"
            ? "R"
            : "F",
        dayOffType: "T",
        start_date: start_date,
        end_date: end_date,
        hours: Number(hour),
        comment: comment,
        timeOffType:
          time === "늦게출근" ? "LC" : time === "일찍퇴근" ? "EL" : "IM",
        // time === "출근지연" ? "LC" : time === "조기퇴근" ? "EL" : "IM",
      };
    }

    // console.log("body: ", body);

    // FIXME:
    setOpenCancelModal(false);
    setOpenRegistModal(false);
    // setLoading(true);

    api
      .post(`/api/v1/day-off`, body)
      .then((res) => {
        setLoading(false);
        Swal.fire("알림", "휴가를 작성하셨습니다.", "success");
        navigate("/day-off");
      })
      .catch((err) => {
        Errorcode({ code: err?.response?.data?.code });
      });
  }, [payType, category, start_date, end_date, hours, comment, time]);

  useEffect(() => {
    api.get(`/api/v1/day-off/remaining`).then((res) => {
      setContentData(res?.data?.data);
    });
  }, []);

  // console.log("vacation: ", vacation);

  // console.log("카테고리: ", payType);

  useEffect(() => {
    let body = {};

    if (category === "기간") {
      body = {
        type:
          payType === "유급"
            ? "U"
            : payType === "무급"
            ? "M"
            : payType === "포상"
            ? "R"
            : "F",
        dayOffType: "D",
        start_date: start_date,
        end_date: end_date,
        hours: null,
        comment: comment,
      };
    } else {
      body = {
        type:
          payType === "유급"
            ? "U"
            : payType === "무급"
            ? "M"
            : payType === "포상"
            ? "R"
            : "F",
        dayOffType: "T",
        start_date: start_date,
        end_date: end_date,
        hours: Number(hour),
        comment: comment,
      };
    }
  }, [payType]);

  return (
    <Grid container spacing={0}>
      <Card sx={{ borderRadius: "10px", width: "100%" }}>
        {loading ? <Loading /> : null}
        {openCancelModal ? (
          <Modal
            title={"휴가 작성"}
            content={"휴가 작성을 취소하시겠습니까?"}
            onClick={goBack}
            setOpenModal={setOpenCancelModal}
          />
        ) : null}
        {openRegistModal ? (
          <Modal
            title={"휴가 작성"}
            content={"휴가 작성하시겠습니까?"}
            onClick={onHandleRegister}
            setOpenModal={setOpenRegistModal}
          />
        ) : null}
        <Box p={3}>
          <ContentContainer>
            <Title title={"휴가"} subtitle={"T&A"} />
            <ContainerWrap
              background={"#F3F8FF"}
              boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
            >
              <AuthorInfo
                userName={myName}
                userTeam={myTeam}
                userPosition={myPosition}
              />

              <PayInfoContainer>
                <PayInfoWrap>
                  <div>결재 정보</div>
                  <span>(잔여일 : {contentData?.remainingDayOff})</span>
                </PayInfoWrap>

                <PayInfoType
                  typeArr={TypeArr}
                  payType={payType}
                  onClickPayType={onClickPayType}
                  categoryArr={CategoryArr}
                  category={category}
                  onClickCategory={onClickCategory}
                  // 시작일, 종료일, 총시간
                  today={today}
                  startDate={start_date}
                  onChangeStartDate={onChangeStartDate}
                  endDate={end_date}
                  onChangeEndDate={onChangeEndDate}
                  hours={hours}
                  onChangeHours={onChangeHours}
                  // 시간차
                  timeOffArr={TimeOffArr}
                  time={time}
                  setTime={setTime}
                  onChangeTime={onChangeTime}
                />
                <DayOffComment
                  value={comment}
                  onChange={onChangeComment}
                  placeholder="사유를 적어주세요"
                />
              </PayInfoContainer>
            </ContainerWrap>

            <ButtonContainerWrap>
              {resize >= 995 ? (
                <>
                  <Buttons
                    onClick={onClickCancel}
                    text={"작성 취소"}
                    background={"danger"}
                    color={"white"}
                  />

                  <Buttons
                    onClick={onClickRegister}
                    text={"작성 완료"}
                    color={"white"}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <MyButton
                    onClick={onClickCancel}
                    text={"작성 취소"}
                    background={"danger"}
                    width={"90px"}
                    height={"55px"}
                  />
                  <MyButton
                    onClick={onClickRegister}
                    text={"작성 완료"}
                    width={"90px"}
                    height={"55px"}
                  />
                </div>
              )}
            </ButtonContainerWrap>
          </ContentContainer>
        </Box>
      </Card>
    </Grid>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

const PayInfoContainer = styled.div`
  ${MyPayInfoContainer}
`;

const ButtonContainerWrap = styled.div`
  ${MyDetailButtonContainerWrap}
`;

export const DayOffComment = styled.textarea`
  display: flex;
  align-items: flex-start;

  border: 1px solid #3752d3;
  border-radius: 10px;
  font-size: 18px;
  padding: 15px;
  min-height: 300px;
`;

export default DayOffRegister;
