import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";

const Postcode = ({ setAddress, setIsOpenPost }) => {
  const handleComplete = (data) => {
    // console.log(data);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    // console.log(fullAddress);
    setAddress(fullAddress);
    setIsOpenPost(false);
  };

  const postCodeStyle = {
    display: "block",
    position: "absolute",
    width: "418px",
    height: "658px",
    border: "1px solid lightgray",
  };

  return (
    <DaumPostcodeEmbed style={postCodeStyle} onComplete={handleComplete} />
  );
};

export default Postcode;
