import { css } from "styled-components";
import { Column } from "./Flexbox";

// 지출 결의 페이지 스타일
// Date
export const MyTitleContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  gap: 20px;
  min-height: 60px;

  margin-top: 10px;
  margin-bottom: 20px;
`;

export const MyPayInfoDate = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  gap: 10px;

  background: #c9e4ff;
  color: #5886bd;
  border-radius: 15px;
`;

// 작성자 정보
export const MyAuthorContainer = css`
  display: flex;
  width: 95%;
  flex-direction: column;

  background: #3b4351;
  border-radius: 10px;

  min-height: 140px;

  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
`;

export const MyAuthorWrap = css`
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  gap: 20px;
  margin-top: 10px;
`;

export const MyAuthorInfo = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // gap: 70px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 5px 10px;
  width: 33%;
  min-height: 50px;

  div {
    display: flex
    justify-content: center;
    width: 90%;
  }
`;

// 결제 정보
export const MyPayInfoContainer = css`
  // ${Column}
  width: 95%;
  height: 100%;
`;

export const MyPayTypeContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const MyTypeWrap = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.gap};
  padding: 5px 10px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  min-height: 60px;
  width: 50%;

  span {
    width: 30%;
    display: flex;
  }
`;

export const MyPayType = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
  border-radius: 18px;
  width: 60%;
  minheight: 40px;
  padding: 5px;
  cursor: pointer;
`;

// 결제정보 리스트
export const MyPayContainer = css`
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  margin: 10px 0px;
  font-size: 14px;

  // height: 45px;

  border: 1px solid #3752d3;
  border-radius: 10px;

  text-align: center;

  overflow: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
