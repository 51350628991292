import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ForumDetailLayout from "../../../components/forum/ForumDetailLayout";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";

export default function AnonymousSecretDetial() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const secretToken = useSelector((state) => state.forum.secretToken);

  const { id } = useParams();

  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  const [contentTitle, setContentTitle] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [contents, setContents] = useState("");

  const [page, setPage] = useState(1);

  // 비밀 게시글 상세 조회
  useEffect(() => {
    (async () => {
      if (!secretToken) {
        Swal.fire("권한 없음", "접근 권한 없습니다.", "error", "확인").then(
          () => {
            navigate(`/anonymous`);
          }
        );
      }

      const { data } = await api.get(
        `/api/v1/anonymous/secret/${id}?secretToken=${secretToken}`
      );

      setContentTitle(data.data.title);
      setCreateDate(data.data.createDate);
      setContents(data.data.contents);
    })();
  }, []);

  // 비밀 게시글 댓글 조회
  useEffect(() => {
    (async () => {
      const res = await api
        .get(`/api/v1/comments/${id}?token=${secretToken}`)
        .then((res) => {
          dispatch(
            forumReducer.actions.setComment({
              commentsList: res.data.data.content,
              totalElements: res.data.data.totalElements,
              TotalPages: res.data.data.totalPages,
            })
          );
        });
    })();
  }, [dispatch]);

  return (
    <ForumDetailLayout
      subtitle={"ANONYMOUS"}
      title={"익명게시판"}
      contentTitle={contentTitle}
      createDate={createDate}
      //   modifyDate={}
      contents={contents}
      files={false}
      editMode={false}
      id={id}
      page={1}
      setPage={setPage}
    />
  );
}
