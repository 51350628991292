import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

// Auth
import Login from "./pages/Login";
import Register from "./pages/Register";

// layout
import FullLayout2 from "./layouts/FullLayout2";

// home
import Home from "./pages/dashboard/Home";
import MyInfo from "./pages/MyInfo";
import Commute from "./pages/attendance/Commute";

// 휴가
import DayOff from "./pages/day-off";
import DayOffDetail from "./pages/day-off/Detail";
import DayOffRegister from "./pages/day-off/Register";

// 대표 공지사항
import NoticesMaster from "./pages/forum/NoticeMaster/NoticesMaster";
import NoticeMaster from "./pages/forum/NoticeMaster/NoticeMaster";
import NoticeEditMaster from "./pages/forum/NoticeMaster/NoticeEditMaster";
import NoticeNewMaster from "./pages/forum/NoticeMaster/NoticeNewMaster";

// 공지사항
import Notices from "./pages/forum/Notice/Notices";
import Notice from "./pages/forum/Notice/Notice";
import NoticeEdit from "./pages/forum/Notice/NoticeEdit";
import NoticeNew from "./pages/forum/Notice/NoticeNew";

// 회의록
import Meeting from "./pages/forum/Meeting/Meeting";
import MeetingDetail from "./pages/forum/Meeting/MeetingDetail";
import MeetingEdit from "./pages/forum/Meeting/MeetingEdit";
import MeetingNew from "./pages/forum/Meeting/MeetingNew";

// 비밀게시글
import AnonymousSecretDetial from "./pages/forum/Anonymous/AnonymousSecretDetial";

// 게시판
import Anonymous from "./pages/forum/Anonymous/Anonymous";
import AnonymousDetail from "./pages/forum/Anonymous/AnonymousDetail";
import AnonymousNew from "./pages/forum/Anonymous/AnonymousNew";

// 아이디어 게시판
import Idea from "./pages/forum/Idea/Idea";

// 인사관리
import NewComer from "./pages/human/NewComer";

// 인사관리
import Department from "./pages/human/Department";
import RegisterNewComer from "./pages/human/RegisterNewComer";

// 지출결의
import Payment from "./pages/payment";
import PaymentDetail from "./pages/payment/Detail";
import Register2 from "./pages/payment/register2";

import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme/theme";

import CssBaseline from "@mui/material/CssBaseline";
import FindPasswordStepOne from "./pages/Auth/FindPasswordStepOne";
import FindPasswordStepTwo from "./pages/Auth/FindPasswordStepTwo";
import FindPasswordStepThree from "./pages/Auth/FindPasswordStepThree";
import { useEffect } from "react";
import api from "./redux/api";
import Swal from "sweetalert2";

// 인풋창 비워지기 // 성공시 화면 이동
// 1. 로그인을 했을 때 권한이 다르게 화면을 보여준다
// 2. 로그인을 했을 때 메뉴가 다르게 화면을 보여준다
function App() {
  const findToken = localStorage.getItem("findToken");
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    window.location.pathname !== "/login" &&
      window.location.pathname !== "/register" &&
      window.location.pathname !== "/findpw1" &&
      window.location.pathname !== "/findpw2" &&
      window.location.pathname !== "/findpw3" &&
      api.get(`/api/v1/account`).catch((err) => {
        err.response.data === undefined &&
          Swal.fire(
            "알림",
            "로그인이 만료되었습니다. 다시 로그인 해주세요.",
            "info",
            "확인"
          ).then(function () {
            localStorage.removeItem("token");
            window.location.replace("/");
          });
      });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          {/* <GlobalStyle /> */}
          <div className="App">
            <Routes>
              {/* 테스트 */}
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Login />} />
              <Route path="/register" element={<Register />} />

              <Route path="/findpw1" element={<FindPasswordStepOne />} />
              {findToken !== undefined && (
                <Route path="/findpw2" element={<FindPasswordStepTwo />} />
              )}
              {accessToken !== undefined && (
                <Route path="/findpw3" element={<FindPasswordStepThree />} />
              )}

              {(accessToken !== undefined || accessToken !== null) && (
                <Route element={<FullLayout2 />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/myinfo" element={<MyInfo />} />
                  <Route path="/commutes" element={<Commute />} />
                  {/* 휴가 */}
                  <Route path="/day-off" element={<DayOff />} />
                  <Route
                    path="/day-off/register"
                    element={<DayOffRegister />}
                  />
                  <Route path="/day-off/:id" element={<DayOffDetail />} />

                  {/* 대표 공지사항 */}
                  <Route path="/master-notices" element={<NoticesMaster />} />
                  <Route
                    path="/master-notices/:id"
                    element={<NoticeMaster />}
                  />
                  <Route
                    path="/master-notices/edit/:id"
                    element={<NoticeEditMaster />}
                  />
                  <Route
                    path="/master-notices/new"
                    element={<NoticeNewMaster />}
                  />

                  {/* 사내 공지사항 */}
                  <Route path="/notices" element={<Notices />} />
                  <Route path="/notices/:id" element={<Notice />} />
                  <Route path="/notices/edit/:id" element={<NoticeEdit />} />
                  <Route path="/notices/new" element={<NoticeNew />} />

                  {/* 회의록 */}
                  <Route path="/meeting" element={<Meeting />} />
                  <Route path="/meeting/:id" element={<MeetingDetail />} />
                  <Route path="/meeting/edit/:id" element={<MeetingEdit />} />
                  <Route path="/meeting/new" element={<MeetingNew />} />

                  {/* 익명게시판 */}
                  <Route path="/anonymous" element={<Anonymous />} />
                  <Route
                    path="/anonymous/secret/:id"
                    element={<AnonymousSecretDetial />}
                  />
                  <Route path="/anonymous/:id" element={<AnonymousDetail />} />

                  <Route path="/anonymous/new" element={<AnonymousNew />} />

                  {/* 아이디어 게시판 */}
                  <Route path="/idea" element={<Idea />} />

                  {/* level2 인사관리 */}
                  <Route path="/newcomer" element={<NewComer />} />
                  <Route path="/newcomer/:id" element={<RegisterNewComer />} />
                  <Route path="/department" element={<Department />} />

                  {/* 지출결의 */}
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/payment/register" element={<Register2 />} />
                  <Route path="/payment/:id" element={<PaymentDetail />} />
                </Route>
              )}
              {/* <Route path={"*"} element={<NotFound />} /> */}
            </Routes>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
