import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, useMediaQuery } from "@mui/material";
import { FaBars } from "react-icons/fa";
import api from "../redux/api";

import moment from "moment";
import useInterval from "../hooks/useInterval";
import styled from "styled-components";

const Header2 = ({ sx, customClass, toggleMobileSidebar, position }) => {
  const userId = localStorage.getItem("userId");
  const [startWorkTime, setStartWorkTime] = useState("");
  const [commuteDate, setCommuteDate] = useState("");
  const [startWorkTimeWithDate, setStartWorkTimeWithDate] = useState("");

  const [afterTime, setAfterTime] = useState("");
  const [ourAfterTime, setOurAfterTime] = useState("");
  const [myAfterTime, setMyAfterTime] = useState("");
  const [nowDay, setNowDay] = useState("");

  const commutesTime = new Date(startWorkTimeWithDate);
  const ourCommutesTime = new Date(`${commuteDate} 08:00:00`);

  const myCommutesTimeMilli = moment(commutesTime).valueOf();
  const ourCommutesTimeMilli = moment(ourCommutesTime).valueOf();

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const myRole = localStorage.getItem("role");

  useEffect(() => {
    api.get(`/api/v1/commute/today`).then((res) => {
      setCommuteDate(res.data.data.createDate);
      setStartWorkTime(res.data.data.startWorkTime);
      setStartWorkTimeWithDate(
        `${res.data.data.createDate} ${res.data.data.startWorkTime}`
      );
    });
  }, [startWorkTime]);

  useEffect(() => {
    setNowDay(moment()._d);
  }, []);

  useInterval(() => {
    setNowDay(moment()._d);
  }, 60000);

  useEffect(() => {
    const nowTime = moment(nowDay).valueOf();
    // 근무 시간 = 지금 시간 - 출근한 시간
    setOurAfterTime(nowTime - ourCommutesTimeMilli - 32400000);
    setMyAfterTime(nowTime - myCommutesTimeMilli - 32400000);
  }, [
    ourAfterTime,
    myAfterTime,
    ourCommutesTimeMilli,
    myCommutesTimeMilli,
    nowDay,
  ]);

  useEffect(() => {
    //출근 시간이 8시보다 밀리세컨드가 작은 경우 출근 시간은 8시로 고정한다. 아닐 경우는 원래 시간
    setAfterTime(
      moment(
        myCommutesTimeMilli < ourCommutesTimeMilli ? ourAfterTime : myAfterTime
      ).format("HH시간 mm분")
    );
  }, [
    ourAfterTime,
    myAfterTime,
    afterTime,
    myCommutesTimeMilli,
    ourCommutesTimeMilli,
  ]);

  return (
    <AppBar sx={sx} position={position} elevation={0} className={customClass}>
      <Toolbar>
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          onClick={toggleMobileSidebar}
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
          }}
        >
          <FaBars />
        </IconButton>
        {mdUp && (myRole === "ROLE_LV1" || myRole === "ROLE_LV2") ? (
          <>
            <TimeBox>
              <div>
                <h6>출근</h6>
                <span>
                  {startWorkTime ? startWorkTime : "출근 QR을 찍어주세요."}
                </span>
              </div>
              <div>
                <h6>근무 시간</h6>
                <RestTime>
                  {afterTime !== "Invalid date"
                    ? afterTime
                    : "출근 QR을 찍어주세요."}
                </RestTime>
              </div>
            </TimeBox>
            <InfoIdBox>
              <div>{userId}</div>
            </InfoIdBox>
          </>
        ) : (
          <>
            <TimeBox></TimeBox>
            <InfoIdBox>
              <div>{userId}</div>
            </InfoIdBox>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const TimeBox = styled.div`
  display: flex;
  // min-width: 1450px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  div {
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h6 {
      font-weight: 700;
      font-size: 10px;
      color: #232323;
      margin-left: 10px;
      margin-right: 10px;
    }

    span {
      color: #b2b8c1;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

const RestTime = styled.span`
  color: #232323 !important;
`;

const InfoIdBox = styled.div`
  display: flex;
  height: 100%;
  width: 100px;
  align-items: center;
  justify-content: end;

  div {
    color: #3b4351;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.12em;
  }
`;

export default Header2;
