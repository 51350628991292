import { css } from "styled-components";
import { Column } from "./Flexbox";

export const MyMainForumContainer = css`
  ${Column}
  width: 95%;
  height: 100%;
  // background: yellow;
`;

export const MyMainForumContainerForCard = css`
  display: flex;
  flex-wrap: wrap;
  width: 1560px;
  height: 85%;
`;
