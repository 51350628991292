import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import selectReducer from "../redux/reducers/selectReducer";

const FilterSelect = ({ text, width, myOptions, setPage }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const customStyles = {
    options: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: width,
      background: "transparent",
      borderRadius: "10px",
      marginLeft: "15px",
      padding: "0px 15px",
      letterSpacing: "0.1em",
      fontWeight: 500,
      fontSize: "13px",
      borderColor: "hsl(0, 0%, 80%)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "hsl(0, 0%, 80%)",
      },
    }),
  };

  useEffect(() => {
    setOptions(myOptions);
  }, [myOptions]);

  useEffect(() => {
    // console.log(selectedOption);
    dispatch(
      selectReducer.actions.setTeamValue({
        teamValue: selectedOption?.value,
      })
    );
  }, [dispatch, selectedOption]);

  const onChangeHandler = (e) => {
    setSelectedOption(e);
    setPage(0);
  };

  return (
    <div>
      <Select
        placeholder={text}
        defaultValue={selectedOption}
        onChange={(e) => onChangeHandler(e)}
        options={options}
        styles={customStyles}
      />
    </div>
  );
};

export default FilterSelect;
