import React, { useEffect, useState } from "react";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import styled from "styled-components";

import {
  dummyDataLevel1,
  dummyDataLevel2,
  dummyDataLevel3,
  dummyDataLevel4,
} from "./SidebarList";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarMenu2 from "./SidebarMenu2";
import { ColumnAlignCenter } from "../styled/Flexbox";
import Swal from "sweetalert2";
import api from "../redux/api";

const Sidebar2 = ({
  isMobileSidebarOpen,
  onSidebarClose,
  isSidebarOpen,
  setMobileSidebarOpen,
}) => {
  const env = process.env;
  env.PUBLIC_URL = env.PUBLIC_URL || "";

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [click, setClick] = useState(6);

  const navigate = useNavigate();
  const myName = localStorage.getItem("name");
  const myPosition = localStorage.getItem("position");
  const myEmail = localStorage.getItem("email");
  const myRole = localStorage.getItem("role");

  const location = useLocation();

  const logOut = () => {
    Swal.fire({
      title: "로그아웃",
      text: "로그아웃 하시겠습니까?",
      icon: "question",
      showDenyButton: true,
    }).then((res) => {
      if (res?.isConfirmed) {
        const accessToken = localStorage.getItem("token");
        let config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        api.get("/api/v1/logout", config).then((res) => {
          if (res.data.success) {
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("role");
            localStorage.removeItem("name");
            localStorage.removeItem("position");
            localStorage.removeItem("email");
            localStorage.removeItem("team");
            localStorage.removeItem("anonymousToken");

            navigate("/login");
          } else {
            alert("로그아웃에 실패");
          }
        });
      }
    });
  };

  useEffect(() => {
    if (location.pathname === "/home") {
      setClick(6);
    }

    // level1
    if (location.pathname.includes("commutes")) {
      setClick(0);
    }
    if (location.pathname.includes("day-off")) {
      setClick(0);
    }
    if (location.pathname.includes("payment")) {
      setClick(1);
    }

    if (location.pathname.includes("master")) {
      setClick(2);
    }
    if (location.pathname.includes("notice")) {
      setClick(2);
    }
    if (location.pathname.includes("idea")) {
      setClick(2);
    }
    if (location.pathname.includes("meeting")) {
      setClick(2);
    }
    if (location.pathname.includes("anonymous")) {
      setClick(2);
    }

    if (location.pathname.includes("myinfo")) {
      setClick(3);
    }

    if (location.pathname.includes("newcomer")) {
      setClick(4);
    }
    if (location.pathname.includes("department")) {
      setClick(4);
    }

    if (myRole === "ROLE_LV4") {
      if (location.pathname.includes("/master-notice")) {
        setClick(1);
      }
      if (location.pathname.includes("/notices")) {
        setClick(1);
      }
      if (location.pathname.includes("/idea")) {
        setClick(1);
      }
      if (location.pathname.includes("/anonymous")) {
        setClick(1);
      }
      if (location.pathname.includes("/meeting")) {
        setClick(1);
      }
      if (location.pathname.includes("/myinfo")) {
        setClick(2);
      }
    }
  }, [location]);

  const SidebarContent = (
    <Box p={0} height="100vh">
      <Container>
        <SubContainer>
          <Logo
            style={{ cursor: "pointer" }}
            onClick={() => {
              setClick(6);
              navigate("/home");
            }}
          >
            {/* <Title1>ITECH </Title1>
            <Title2>CORP.</Title2> */}
            <img src={`${process.env.PUBLIC_URL}/Group.svg`} alt="itech_logo" />
          </Logo>
          <InnerContainer>
            <InnerSection>
              <MyInfo>
                {/* FIXME: */}
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/Logo.svg`}
                    width={"35px"}
                    alt={"itech"}
                  />
                </div>
                <Name>
                  {myName}·{myPosition}
                </Name>
                <Email>{myEmail}</Email>
              </MyInfo>
              {myRole === "ROLE_LV1" && (
                <SideNav>
                  <SidebarMenu2
                    list={dummyDataLevel1}
                    click={click}
                    setClick={setClick}
                    setMobileSidebarOpen={setMobileSidebarOpen}
                  />
                </SideNav>
              )}
              {myRole === "ROLE_LV2" && (
                <SideNav>
                  <SidebarMenu2
                    list={dummyDataLevel2}
                    click={click}
                    setClick={setClick}
                    setMobileSidebarOpen={setMobileSidebarOpen}
                  />
                </SideNav>
              )}
              {myRole === "ROLE_LV3" && (
                <SideNav>
                  <SidebarMenu2
                    list={dummyDataLevel3}
                    click={click}
                    setClick={setClick}
                    setMobileSidebarOpen={setMobileSidebarOpen}
                  />
                </SideNav>
              )}
              {myRole === "ROLE_LV4" && (
                <SideNav>
                  <SidebarMenu2
                    list={dummyDataLevel4}
                    click={click}
                    setClick={setClick}
                    setMobileSidebarOpen={setMobileSidebarOpen}
                  />
                </SideNav>
              )}
            </InnerSection>
            <LogoutSection mb={lgUp ? "10px" : "30px"}>
              <H6>LOGOUT</H6>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={logOut}
              >
                로그아웃
              </div>
            </LogoutSection>
          </InnerContainer>
        </SubContainer>
      </Container>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: "265px",
            border: "0 !important",
            boxShadow: "0px 7px 30px 0px rgb(113 122 131 / 11%)",
            height: "100%",
            background: "#fff",
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          width: "265px",
          border: "0 !important",
          background: "#fff",
          height: "100vh",
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
};

const Container = styled.div`
  ${ColumnAlignCenter}
  width: 100%;
  min-height: 100vh;
  background-color: transparent;
`;

const SubContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  ${ColumnAlignCenter}
`;

const Logo = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
`;

// const Title1 = styled.span`
//   color: #3752d3;
//   font-size: 25px;
//   font-weight: 700;
//   margin-right: 5px;
// `;

// const Title2 = styled.span`
//   color: #3752d3;
//   font-size: 14px;
//   font-weight: 700;
//   margin-left: 5px;
//   margin-top: 11px;
// `;

const InnerContainer = styled.div`
  ${ColumnAlignCenter}
  justify-content: space-between;
  min-height: 92vh;
  width: 100%;
  background: #fff;
  // border: 1px solid black;
  // background: yellow;
`;

const InnerSection = styled.div`
  ${ColumnAlignCenter}
  // height: 100vh;
  width: 100%;
  margin-bottom: 50px;
  // border: 1px solid blue;
`;

const LogoutSection = styled.div`
  background: #fff;
  width: 80%;
  font-size: 14px;
  padding-bottom: ${(props) => props.mb || "10px"};
`;

const MyInfo = styled.div`
  margin-top: 20px;
  ${ColumnAlignCenter}
`;

const Name = styled.div`
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.15em;
  font-weight: 700;
  color: #292929;
`;

const Email = styled.div`
  margin-top: 10px;
  font-size: 12px;
  letter-spacing: 0.035em;
  color: #292929;
  font-weight: 700;
`;

const SideNav = styled.div`
  width: 100%;
  // height: 100%;
  margin-top: 30px;
  ${ColumnAlignCenter}
`;

const H6 = styled.h6`
  font-size: 12px;
  color: #b2b8c1;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export default Sidebar2;
