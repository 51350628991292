import React from "react";

import Anonymous from "../../components/dashborad/Anonymous";
import NoticeMaster from "../../components/dashborad/NoticeMaster";
import Notice from "../../components/dashborad/Notice";
import CalendarOverview from "../../components/dashborad/Calendar";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";

const Level4 = () => {
  const myRole = localStorage.getItem("role");
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {mdUp ? (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Stack spacing={2}>
              <Paper sx={{ height: "50vh", borderRadius: "10px" }}>
                <CalendarOverview />
              </Paper>
              <Paper sx={{ borderRadius: "10px" }}>
                <Anonymous role={myRole} />
              </Paper>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Stack spacing={2}>
              <Paper sx={{ height: "50%", borderRadius: "10px" }}>
                <NoticeMaster role={myRole} />
              </Paper>
              <Paper sx={{ height: "50%", borderRadius: "10px" }}>
                <Notice role={myRole} />
              </Paper>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CalendarOverview />
          </Grid>
          <Grid item xs={12} md={12}>
            <Anonymous role={myRole} />
          </Grid>
          <Grid item xs={12} md={12}>
            <NoticeMaster role={myRole} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Notice role={myRole} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Level4;
