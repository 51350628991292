import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import MyButton from "../components/MyButton";
import api from "../redux/api";
import logInReducer from "../redux/reducers/logInReducer";
import { ColumnCenter } from "../styled/Flexbox";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { useMediaQuery } from "@mui/material";
import Errorcode from "../util/Errorcode";

const Login = () => {
  const [userId, setUserId] = useState("");

  const idRef = useRef();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken !== null && navigate("/home");
  }, [accessToken, navigate]);

  const logInUser = useCallback(
    (e) => {
      e.preventDefault();

      const body = {
        userId,
        password,
      };

      api
        .post(`/api/v1/login/web`, body)
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("token", res.data.data.accessToken);
            localStorage.setItem("userId", res.data.data.userId);
            localStorage.setItem("role", res.data.data.role);
            localStorage.setItem("name", res.data.data.name);
            localStorage.setItem("team", res.data.data.team.name);
            localStorage.setItem("position", res.data.data.rank.name);
            localStorage.setItem("email", res.data.data.email);

            navigate("/home", { replace: true });
            window.location.replace("/home");

            dispatch(
              logInReducer.actions.setUserData({
                data: res.data.data,
                name: res.data.data.name,
              })
            );
          } else {
            idRef.current.focus();
          }
        })
        .catch((err) => {
          const errlistLength = err.response.data.list?.length;
          {
            errlistLength
              ? Errorcode({
                  code: err?.response?.data?.code,
                  message:
                    err?.response?.data?.list[errlistLength - 1]?.message,
                })
              : Errorcode({
                  code: err?.response?.data?.code,
                });
          }
        });
    },
    [userId, password]
  );

  const onEnter = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      logInUser();
    }
  };

  const RegisterUser = () => {
    navigate("/register");
  };

  // TODO: 비밀번호 찾기
  const FindPassword = () => {
    navigate("/findpw1");
  };

  return (
    <LoginWrapper>
      <div style={{ marginBottom: "30px" }}>
        {/* <LoginTitle1>ITECH </LoginTitle1>
        <LoginTitle2>CORP.</LoginTitle2> */}
        <img src={`${process.env.PUBLIC_URL}/Group.svg`} alt="itech_logo" />
      </div>
      <form onKeyUp={onEnter}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              left: "25px",
              top: "50%",
              marginTop: "-11px",
              width: "20px",
              height: "20px",
            }}
          >
            <FaUser />
          </div>

          <LoginInput
            ref={idRef}
            placeholder="Id"
            type="text"
            width={mdUp ? "452px" : "300px"}
            onChange={(e) => setUserId(e.target.value)}
          />
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              left: "25px",
              top: "50%",
              marginTop: "-11px",
              width: "20px",
              height: "20px",
            }}
          >
            <FaLock />
          </div>

          <LoginInput
            placeholder="Password"
            type="password"
            width={mdUp ? "452px" : "300px"}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MyButton
            text="로그인"
            onClick={logInUser}
            color={"white"}
            width={mdUp ? "452px" : "300px"}
            height={"63px"}
            fontSize={"16px"}
            padding={"0px"}
            fontWeight={"700"}
            margin={"20px 0px 0px 0px"}
          />
          <MyButton
            text="신규사원등록"
            onClick={RegisterUser}
            color={"#3752D3"}
            background={"cancel"}
            fontSize={"16px"}
            fontWeight={"500"}
            width={mdUp ? "452px" : "300px"}
            padding={"20px 0px 0px 0px"}
            letterSpacing={"0.15em"}
          />

          {/* TODO: 비밀번호 찾기 */}
          <FindPwButton onClick={FindPassword}>비밀번호 찾기</FindPwButton>
        </div>
      </form>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div`
  ${ColumnCenter}
  width: 100vw;
  height: 100vh;
`;

// const LoginTitle1 = styled.span`
//   color: #3752d3;
//   font-size: 45px;
//   font-weight: 700;
// `;

// const LoginTitle2 = styled.span`
//   color: #3752d3;
//   font-size: 30px;
//   font-weight: 700;
// `;

const LoginInput = styled.input`
  display: flex;
  // width: 452px;
  width: ${(props) => props.width};
  height: 61px;
  padding: 0px;
  padding-left: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #3752d3;
  // border: 1px solid red;
  border-radius: 10px;
  font-size: 16px;
  color: #3b4351;
  font-weight: 500px;
`;

const FindPwButton = styled.button`
  font-size: 16px;
  margin-top: 40px;
  background: none;
  border: none;
  color: #677381;
  cursor: pointer;
`;

export default Login;
