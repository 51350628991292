import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ForumEditor from "../../../components/forum/ForumEditor";
import api from "../../../redux/api";
import Swal from "sweetalert2";
import Errorcode from "../../../util/Errorcode";

const AnonymousNew = () => {
  const navigate = useNavigate();
  const contentTitle = useSelector((state) => state.forum.contentTitle);
  const contentsHTML = useSelector((state) => state.forum.contentsHTML);
  const anonymousToken = localStorage.getItem("anonymousToken");
  const [openModal, setOpenModal] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isSecret, setIsSecret] = useState(false);
  const [password, setPassword] = useState("");

  const accessToken = localStorage.getItem("token");

  // console.log("비밀글 등록: ", isSecret);
  // console.log("password: ", password);

  useEffect(() => {
    accessToken === null && navigate("/login");
  }, []);

  const cancel = () => {
    setOpenModal(false);
    navigate(`/anonymous`);
  };

  // TODO: 비밀 게시글일때 body 값을 다르게 적용
  // const postSecretContent = async () => {
  //   if (password === "") {
  //     return Swal.fire("알림", "비밀번호를 입력해주세요.", "warning", "확인");
  //   }

  //   const body = {
  //     title: contentTitle,
  //     contents: contentsHTML,
  //     token: anonymousToken,
  //     isSecret,
  //     password,
  //   };

  //   setOpenCheckModal(false);
  //   setLoading(true);

  //   await api
  //     .post(`/api/v1/anonymous`, body)
  //     .then((res) => {
  //       // console.log(res);
  //       setLoading(false);
  //       navigate("/anonymous");
  //       Swal.fire({
  //         title: "알림",
  //         text: "등록이 완료되었습니다.",
  //         icon: "success",
  //         confirmButtonText: "확인",
  //       });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       const errlistLength = err.response.data.list.length;
  //       Errorcode({
  //         code: err?.response?.data?.code,
  //         message: err?.response?.data?.list[errlistLength - 1].message,
  //       });
  //     });
  // };

  // 게시글 등록
  const postAnonymousContent = () => {
    let body = {};

    if (isSecret && password === "") {
      setOpenCheckModal(false);
      return Swal.fire("알림", "비밀번호를 입력해주세요.", "warning", "확인");
    }

    isSecret
      ? (body = {
          title: contentTitle,
          contents: contentsHTML,
          token: anonymousToken,
          isSecret,
          password,
        })
      : (body = {
          title: contentTitle,
          contents: contentsHTML,
          token: anonymousToken,
        });

    setOpenCheckModal(false);
    setLoading(true);

    api
      .post(`/api/v1/anonymous`, body)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        navigate("/anonymous");
        Swal.fire({
          title: "알림",
          text: "등록이 완료되었습니다.",
          icon: "success",
          confirmButtonText: "확인",
        });
      })
      .catch((err) => {
        setLoading(false);
        const errlistLength = err.response.data.list.length;
        Errorcode({
          code: err?.response?.data?.code,
          message: err?.response?.data?.list[errlistLength - 1].message,
        });
      });
  };

  return (
    <ForumEditor
      subtitle={"ANONYMOUS"}
      title={"익명게시판"}
      buttontext={"등록"}
      cancel={cancel}
      onClick={postAnonymousContent}
      onEdit={false}
      setOpenModal={setOpenModal}
      openModal={openModal}
      setOpenCheckModal={setOpenCheckModal}
      openCheckModal={openCheckModal}
      loading={loading}
      // 비밀글
      isSecret={isSecret}
      setIsSecret={setIsSecret}
      password={password}
      setPassword={setPassword}
    />
  );
};

export default AnonymousNew;
