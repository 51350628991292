import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker__input-container {
      
        input {
            width: 100px;
            height: 35px;
            border: 1px solid #3752d3;
            border-radius: 10px;
            margin-left: 5px;
            text-align: center;
        }
    }
`;

const DatePicker = ({ startDate, setStartDate }) => {
  return (
    <>
      <ReactDatePicker
        wrapperClassName="date_picker full-width"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
      <DatePickerWrapperStyles />
    </>
  );
};

export default DatePicker;
