import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../components/Loading";
import api from "../../redux/api";

const FindPasswordStepTwo = () => {
  const navigate = useNavigate();

  const findToken = localStorage.getItem("findToken");

  console.log("findToken: ", findToken);

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");

  const onChangeCode = useCallback(
    (e) => {
      setCode(e.target.value);
    },
    [code]
  );

  const FindPassword = async () => {
    try {
      setLoading(true);

      const { data } = await api.post(`/api/v1/users/sms-verify`, {
        token: findToken,
        code,
      });

      localStorage.setItem("token", data?.data?.accessToken);
      navigate("/findpw3");
    } catch (error) {
      console.log("err: ", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <FindPasswordContainer>
      <div>
        <div
          style={{
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          2
        </div>
        <FindInput
          placeholder="인증번호를 입력해주세요."
          value={code}
          onChange={onChangeCode}
        />
        <ConfirmButton onClick={FindPassword}>다음</ConfirmButton>
      </div>
    </FindPasswordContainer>
  );
};

const FindPasswordContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const FindInput = styled.input`
  display: flex;
  height: 60px;
  font-size: 14px;
  margin: 10px 0px;
  padding-left: 15px;
  width: 400px;
  border-radius: 10px;
  border: 1px solid gray;
`;

const ConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #3752d3;
  color: white;

  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
  padding: 15px;
  margin-top: 40px;

  border-radius: 10px;
  width: 400px;
`;

export default FindPasswordStepTwo;
