import React from "react";
import styled from "styled-components";

const NewComerInfoList = ({ title, detail }) => {
  return (
    <InfoList>
      <InfoTitle>{title}</InfoTitle>
      <NewComerInfo>{detail}</NewComerInfo>
    </InfoList>
  );
};

const InfoList = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid white;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;
const InfoTitle = styled.span`
  width: 8%;
  margin-left: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 14px;
  color: #cccccc;

  @media screen and (max-width: 995px) {
    font-size: 12px;
    width: 15%;
  }
`;
const NewComerInfo = styled.span`
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;

  @media screen and (max-width: 995px) {
    font-size: 15px;
  }
`;
export default NewComerInfoList;
