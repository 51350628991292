import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const DepartmentList = ({
  key,
  id,
  name,
  teamName,
  teamName2,
  onClick,
  idx,
  clickDeleteBtn,
  className,
}) => {
  return (
    <ListDepartment key={key}>
      <button
        id={id}
        name={name}
        onClick={(e) => onClick(idx, e)}
        className={className}
      >
        <span>{teamName}</span>
      </button>
      {teamName === "퇴사자" || teamName2 === "퇴사자" ? null : (
        <XButton id={`${id}_delete`} onClick={clickDeleteBtn}>
          <FaTimes color={"white"} />
        </XButton>
      )}
    </ListDepartment>
  );
};

const ListDepartment = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid #b2b8c1;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.1em;
      // color: ${(props) => props.clicked && "#3752D3"};
    }
  }
`;

const XButton = styled.div`
  width: 20px;
  height: 20px;
  background: #d33751;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
`;

export default DepartmentList;
