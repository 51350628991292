import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import commutesReducer from "../../../redux/reducers/commutesReducer";
import Select from "react-select";

const CommuteTeamSelect = ({ myOptions }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const customStyles = {
    options: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "130px",
      display: "flex",
      justifyContent: "space-between",
      background: "transparent",
      borderRadius: "10px",
      letterSpacing: "0.1em",
      fontWeight: 500,
      fontSize: "12px",
    }),
  };
  useEffect(() => {
    setOptions(myOptions);
  }, [myOptions]);

  useEffect(() => {
    // console.log(selectedOption);
    dispatch(
      commutesReducer.actions.setCommuteTeam({
        selectedTeam: selectedOption?.value,
      })
    );
  }, [dispatch, selectedOption]);

  const onChangeHandler = (e) => {
    setSelectedOption(e);
  };

  return (
    <div>
      <Select
        placeholder={"부서"}
        defaultValue={selectedOption}
        onChange={(e) => onChangeHandler(e)}
        options={options}
        styles={customStyles}
      />
    </div>
  );
};
export default CommuteTeamSelect;
