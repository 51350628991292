// 지출 결의 등록 부분에서만 사용할 거라고 생각해서 따로 module 형식으로 빼진 않았습니다.
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "../DatePicker";

import styled, { createGlobalStyle } from "styled-components";
import PaymentSelect from "./Select";
import { RegisterArraySelect } from "./RegisterArray";

import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import api from "../../redux/api";
import { Grid, useMediaQuery } from "@mui/material";
import Swal from "sweetalert2";

const RemittanceRegistCard = ({
  key,
  index,
  resultCard,
  setResultCard,
  payType,
}) => {
  const 지출목적 = [
    { value: "0", label: "선택" },
    { value: "1", label: "교통비" },
    { value: "2", label: "기타" },
    { value: "3", label: "도서구입" },
    { value: "4", label: "복리후생비" },
    { value: "5", label: "부가가치세" },
    { value: "6", label: "사무비품" },
    { value: "7", label: "소모품비" },
    { value: "8", label: "숙박비" },
    { value: "9", label: "식대" },
    { value: "10", label: "용역비" },
    { value: "11", label: "접대비" },
    { value: "12", label: "주유비" },
    { value: "13", label: "주차비" },
    { value: "14", label: "지급수수료" },
    { value: "15", label: "출장비" },
    { value: "16", label: "환불 지급" },
  ];

  const 지급은행 = [
    { value: "0", label: "은행 선택" },
    { value: "1", label: "경남" },
    { value: "2", label: "광주" },
    { value: "3", label: "국민" },
    { value: "4", label: "기업" },
    { value: "5", label: "농협중앙" },
    { value: "6", label: "대구" },
    { value: "7", label: "부산" },
    { value: "8", label: "산업" },
    { value: "9", label: "상호저축은행" },
    { value: "10", label: "새마을금고" },
    { value: "11", label: "수출입" },
    { value: "12", label: "수협" },
    { value: "13", label: "신한" },
    { value: "14", label: "신협" },
    { value: "15", label: "외환" },
    { value: "16", label: "우리" },
    { value: "17", label: "우체국" },
    { value: "18", label: "전북" },
    { value: "19", label: "제주" },
    { value: "20", label: "지역농협" },
    { value: "21", label: "축협" },
    { value: "22", label: "카카오뱅크" },
    { value: "23", label: "케이뱅크" },
    { value: "24", label: "하나" },
    { value: "25", label: "한국" },
    { value: "26", label: "한국씨티" },
    { value: "27", label: "S C제일" },
  ];

  const [startDate, setStartDate] = useState(new Date());

  const [usedDate, setUsedDate] = useState("");
  const [payItem, setPayItem] = useState("");
  const [amount, setAmount] = useState("");
  const [department, setDepartment] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [paybank, setPaybank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [teamList, setTeamList] = useState([]);

  const xlUp = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const onChangeDate = useCallback((date) => {
    setResultCard([
      ...resultCard.map((v, i) => {
        if (i !== index) {
          return v;
        } else {
          return { ...v, usedDate: moment(date).format("YYYY-MM-DD") };
        }
      }),
    ]);
  });

  // TODO: select
  const onChangePayItem = useCallback(
    (e) => {
      setPayItem(e.currentTarget.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, payItem: e.currentTarget.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  const onChangeDepartment = useCallback(
    (e) => {
      setDepartment(e.currentTarget.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, department: e.currentTarget.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  const onChangePaybank = useCallback(
    (e) => {
      setPaybank(e.currentTarget.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, payBank: e.currentTarget.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  // TODO: input
  const onChangeAmount = async (e) => {
    const regexNum = /[0-9]/g; // 숫자

    const target = e.target.value;
    const test = target.replace(/ /g, "");
    const number = test.replace(/,/g, "");

    if (!regexNum.test(Number(target[0]))) {
      Swal.fire({
        title: "금액",
        text: "금액은 숫자로 입력해주세요.",
        icon: "question",
      });
      setAmount("");
    } else {
      setAmount(test);

      const test2 = Number(number);

      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, amount: Number(test2) };
          }
        }),
      ]);
    }
  };

  // TODO: 테스트
  // 1, 000, 000

  // console.log("test222:", test2, typeof test2);

  // const onChangeAmount = useCallback(
  //   (e) => {
  //     const regexNum = /[0-9]/g; // 숫자

  //     if (regexNum.test(Number(amount))) {
  //       setAmount(e.target.value);
  //       setResultCard([
  //         ...resultCard.map((v, i) => {
  //           if (i !== index) {
  //             return v;
  //           } else {
  //             return { ...v, amount: Number(e.target.value) };
  //           }
  //         }),
  //       ]);
  //     } else {
  //       setAmount("");
  //     }
  //   },
  //   [index, setResultCard, resultCard, amount]
  // );

  const onChangeCompanyName = useCallback(
    (e) => {
      setCompanyName(e.target.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, companyName: e.target.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  const onChangeAccountNumber = useCallback(
    (e) => {
      const regexNum = /[0-9]/g; // 숫자
      if (regexNum.test(Number(accountNumber))) {
        setAccountNumber(e.target.value);
        setResultCard([
          ...resultCard.map((v, i) => {
            if (i !== index) {
              return v;
            } else {
              return { ...v, accountNumber: e.target.value };
            }
          }),
        ]);
      } else {
        setAccountNumber("");
      }
    },
    [index, setResultCard, resultCard, accountNumber]
  );

  const onChangeAccountName = useCallback(
    (e) => {
      setAccountName(e.target.value);
      setResultCard([
        ...resultCard.map((v, i) => {
          if (i !== index) {
            return v;
          } else {
            return { ...v, accountName: e.target.value };
          }
        }),
      ]);
    },
    [index, setResultCard, resultCard]
  );

  useEffect(() => {
    api.get(`/api/v1/teams`).then((res) => {
      setTeamList(
        res?.data?.list
          ?.map((item) => [{ value: item?.id, label: item?.name }])
          .map((item) => item[0])
      );
    });
  }, []);

  // console.log("amount", amount);
  // if (payType === "송금") {
  return (
    <Grid container>
      <PayRegisterLists key={key}>
        {xlUp ? (
          <>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // border: "1px solid black",
                  width: "30%",
                }}
              >
                날짜
              </div>
              <ReactDatePicker
                wrapperClassName="date_picker full-width"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setResultCard([
                    ...resultCard.map((v, i) => {
                      if (i !== index) {
                        return v;
                      } else {
                        return {
                          ...v,
                          usedDate: moment(date).format("YYYY-MM-DD"),
                        };
                      }
                    }),
                  ]);
                }}
              />
              <DatePickerWrapperStyles />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지출목적</span>
              <RegisterSelect onChange={onChangePayItem}>
                {지출목적?.map((item) => {
                  return <option key={item?.value}>{item?.label}</option>;
                })}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>금액</span>
              <ContentInput
                value={amount}
                onChange={onChangeAmount}
                placeholder="10000"
              />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지출부서</span>
              <RegisterSelect onChange={onChangeDepartment}>
                {teamList?.map(
                  (item) =>
                    item.label !== "퇴사자" && (
                      <option key={item?.value}>{item?.label}</option>
                    )
                )}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>업체명</span>
              <ContentInput
                value={companyName}
                onChange={onChangeCompanyName}
                // placeholder="업체명을 입력해주세요"
                placeholder="업체명"
              />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지급은행</span>
              <RegisterSelect onChange={onChangePaybank}>
                {지급은행?.map((item) => {
                  return <option key={item?.value}>{item?.label}</option>;
                })}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>계좌번호</span>
              <ContentInput
                value={accountNumber}
                onChange={onChangeAccountNumber}
                // placeholder="계좌번호를 입력해주세요"
                placeholder="123456123456"
              />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>예금주</span>
              <ContentInput
                value={accountName}
                onChange={onChangeAccountName}
                // placeholder="예금주 명을 입력해주세요"
                placeholder="예금주 이름"
              />
            </Grid>
          </>
        ) : lgUp ? (
          <>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>날짜</div>
              <ReactDatePicker
                wrapperClassName="date_picker full-width"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setResultCard([
                    ...resultCard.map((v, i) => {
                      if (i !== index) {
                        return v;
                      } else {
                        return {
                          ...v,
                          usedDate: moment(date).format("YYYY-MM-DD"),
                        };
                      }
                    }),
                  ]);
                }}
              />
              <DatePickerWrapperStyles />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지출목적</span>
              <RegisterSelect onChange={onChangePayItem}>
                {지출목적?.map((item) => {
                  return <option key={item?.value}>{item?.label}</option>;
                })}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>금액</span>
              <ContentInput
                value={amount}
                onChange={onChangeAmount}
                placeholder="10000"
              />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지출부서</span>
              <RegisterSelect onChange={onChangeDepartment}>
                {teamList?.map(
                  (item) =>
                    item.label !== "퇴사자" && (
                      <option key={item?.value}>{item?.label}</option>
                    )
                )}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>업체명</span>
              <ContentInput
                value={companyName}
                onChange={onChangeCompanyName}
                // placeholder="업체명을 입력해주세요"
                placeholder="업체명"
              />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>지급은행</span>
              <RegisterSelect onChange={onChangePaybank}>
                {지급은행?.map((item) => {
                  return <option key={item?.value}>{item?.label}</option>;
                })}
              </RegisterSelect>
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>계좌번호</span>
              <ContentInput
                value={accountNumber}
                onChange={onChangeAccountNumber}
                // placeholder="계좌번호를 입력해주세요"
                placeholder="12345612123456"
              />
            </Grid>
            <Grid
              item
              lg={1.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>예금주</span>
              <ContentInput
                value={accountName}
                onChange={onChangeAccountName}
                // placeholder="예금주 명을 입력해주세요"
                placeholder="예금주 이름"
              />
            </Grid>
          </>
        ) : mdUp ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            <Grid container>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  날짜
                </span>
                <ReactDatePicker
                  wrapperClassName="date_picker full-width"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setResultCard([
                      ...resultCard.map((v, i) => {
                        if (i !== index) {
                          return v;
                        } else {
                          return {
                            ...v,
                            usedDate: moment(date).format("YYYY-MM-DD"),
                          };
                        }
                      }),
                    ]);
                  }}
                />
                <DatePickerWrapperStyles />
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                지출목적
                <RegisterSelect onChange={onChangePayItem}>
                  {지출목적?.map((item) => {
                    return <option key={item?.value}>{item?.label}</option>;
                  })}
                </RegisterSelect>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                금액
                <ContentInput
                  value={amount}
                  onChange={onChangeAmount}
                  // placeholder="금액을 입력해주세요"
                  placeholder="10000"
                />
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                지출부서
                <RegisterSelect onChange={onChangeDepartment}>
                  {teamList?.map(
                    (item) =>
                      item.label !== "퇴사자" && (
                        <option key={item?.value}>{item?.label}</option>
                      )
                  )}
                </RegisterSelect>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                업체명
                <ContentInput
                  value={companyName}
                  onChange={onChangeCompanyName}
                  // placeholder="업체명을 입력해주세요"
                  placeholder="업체명"
                />
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                지급은행
                <RegisterSelect onChange={onChangePaybank}>
                  {지급은행?.map((item) => {
                    return <option key={item?.value}>{item?.label}</option>;
                  })}
                </RegisterSelect>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                계좌번호
                <ContentInput
                  value={accountNumber}
                  onChange={onChangeAccountNumber}
                  // placeholder="계좌번호를 입력해주세요"
                  placeholder="123456123456"
                />
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                예금주
                <ContentInput
                  value={accountName}
                  onChange={onChangeAccountName}
                  // placeholder="예금주 명을 입력해주세요"
                  placeholder="예금주 이름"
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  날짜
                </span>
                <ReactDatePicker
                  wrapperClassName="date_picker full-width"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setResultCard([
                      ...resultCard.map((v, i) => {
                        if (i !== index) {
                          return v;
                        } else {
                          return {
                            ...v,
                            usedDate: moment(date).format("YYYY-MM-DD"),
                          };
                        }
                      }),
                    ]);
                  }}
                />
                <DatePickerWrapperStyles />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                지출목적
                <RegisterSelect onChange={onChangePayItem}>
                  {지출목적?.map((item) => {
                    return <option key={item?.value}>{item?.label}</option>;
                  })}
                </RegisterSelect>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                금액
                <ContentInput
                  value={amount}
                  onChange={onChangeAmount}
                  // placeholder="금액을 입력해주세요"
                  placeholder="10000"
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                지출부서
                <RegisterSelect onChange={onChangeDepartment}>
                  {teamList?.map(
                    (item) =>
                      item.label !== "퇴사자" && (
                        <option key={item?.value}>{item?.label}</option>
                      )
                  )}
                </RegisterSelect>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                업체명
                <ContentInput
                  value={companyName}
                  onChange={onChangeCompanyName}
                  // placeholder="업체명을 입력해주세요"
                  placeholder="업체명"
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                지급은행
                <RegisterSelect onChange={onChangePaybank}>
                  {지급은행?.map((item) => {
                    return <option key={item?.value}>{item?.label}</option>;
                  })}
                </RegisterSelect>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                계좌번호
                <ContentInput
                  value={accountNumber}
                  onChange={onChangeAccountNumber}
                  // placeholder="계좌번호를 입력해주세요"
                  placeholder="123456123456"
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                예금주
                <ContentInput
                  value={accountName}
                  onChange={onChangeAccountName}
                  // placeholder="예금주 명을 입력해주세요"
                  placeholder="예금주 이름"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </PayRegisterLists>
    </Grid>
  );
};

//

const PayRegisterLists = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #3752d3;
  border-radius: 10px;
  align-items: center;
  padding: 12px 8px;
`;

const RegisterContent = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const ContentInput = styled.input`
  width: 100px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  margin-left: 5px;
  padding: 8px;
`;

const RegisterSelect = styled.select`
  width: 100px;
  height: 35px;
  border: 1px solid #3752d3;
  border-radius: 10px;
  margin-left: 5px;
`;

const DatePickerWrapperStyles = createGlobalStyle`
  
  .react-datepicker__input-container {
    width: 100%;
    display: flex;
    justify-content: center;

    input {
        width: 100px;
        height: 35px;
        border: 1px solid #3752d3;
        border-radius: 10px;
        margin-left: 5px;
        text-align: center;
    }
  }
`;

export default RemittanceRegistCard;
