import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Column } from "../styled/Flexbox";
import Collapsible from "react-collapsible";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarMenu2 = ({ list, click, setClick, setMobileSidebarOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [oneMoreClick, setOneMoreClick] = useState(0);
  const [subClick, setSubClick] = useState("");

  useEffect(() => {
    if (location.pathname.includes("/home")) {
      setSubClick("");
    }
    if (location.pathname.includes("commutes")) {
      setSubClick("출퇴근");
    }
    if (location.pathname.includes("day-off")) {
      setSubClick("휴가");
    }
    if (location.pathname.includes("payment")) {
      setSubClick("지출결의");
    }
    if (location.pathname.includes("/master-notices")) {
      setSubClick("대표이사 공지사항");
    }
    if (location.pathname.includes("/notice")) {
      setSubClick("사내 공지사항");
    }
    if (location.pathname.includes("idea")) {
      setSubClick("아이디어 게시판");
    }
    if (location.pathname.includes("meeting")) {
      setSubClick("회의록");
    }
    if (location.pathname.includes("anonymous")) {
      setSubClick("익명게시판");
    }
    if (location.pathname.includes("myinfo")) {
      setSubClick("내정보");
    }
    if (location.pathname.includes("newcomer")) {
      setSubClick("신규입사");
    }
    if (location.pathname.includes("department")) {
      setSubClick("부서관리");
    }
  }, [location]);

  return (
    <Container>
      {list?.map((item, i) => {
        const { subtitle } = item;

        return (
          <React.Fragment key={item?.id}>
            <H6>{item?.directory}</H6>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                className={
                  `${i}` == click
                    ? oneMoreClick % 2 === 0
                      ? "Active"
                      : "NoActive"
                    : "NoActive"
                }
              >
                <CategoryWrap
                  onClick={(e) => {
                    setClick(i);
                    i === click
                      ? setOneMoreClick((prev) => prev + 1)
                      : setOneMoreClick(0);
                  }}
                >
                  <div className="clickActive"></div>
                  <SidebarIcon>{item?.icon}</SidebarIcon>
                  <SidebarTitle>{item?.title}</SidebarTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "10%",
                    }}
                    className="icon2"
                  >
                    {item?.icon2}
                  </div>
                </CategoryWrap>
                <div className="pickbox">
                  {subtitle?.map((data) => {
                    return (
                      <div
                        key={data?.id}
                        className="pick"
                        onClick={(e) => {
                          setOneMoreClick(0);
                          setSubClick(e.target.outerText);
                          setMobileSidebarOpen(false);
                          navigate(`/${data?.name}`);
                        }}
                      >
                        {data?.detailtitle === subClick ? (
                          <span style={{ color: "#3752D3" }}>
                            {data?.detailtitle}
                          </span>
                        ) : (
                          <span>{data?.detailtitle}</span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  ${Column}
  width: 100%;
  margin-top: 10px;
`;

const H6 = styled.h6`
  font-size: 12px;
  color: #b2b8c1;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 0px 5px 12px;
`;

const ContentInner = styled.p`
  display: flex;
  margin: 3px 0px;
  padding: 10px 0px;
  border-radius: 10px;

  svg {
    display: flex;
    justifycontent: center;
    width: 20%;
    height: 25px;
  }
`;

const CategoryWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-weight: 500;
`;

const SidebarIcon = styled.div`
  font-size: 20px;
  display: flex;
  width: 30%;
  justify-content: center;
`;

const SidebarTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 60%;
`;

export default SidebarMenu2;
