import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ForumDetailLayout from "../../../components/forum/ForumDetailLayout";
import api from "../../../redux/api";
import forumReducer from "../../../redux/reducers/forumReducer";

const AnonymousDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noticetitle = useSelector((state) => state.forum.noticetitle);
  const createDate = useSelector((state) => state.forum.createDate);
  const modifyDate = useSelector((state) => state.forum.modifyDate);
  const noticecontents = useSelector((state) => state.forum.noticecontents);
  // const commentsList = useSelector((state) => state.forum.commentsList);
  const [page, setPage] = useState(0);
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    accessToken === null && navigate("/login");
    api.get(`/api/v1/anonymous/${id}`).then((res) => {
      dispatch(
        forumReducer.actions.setNoticeDetailInfo({
          noticetitle: res.data.data.title,
          createDate: res.data.data.createDate,
          modifyDate: res.data.data.modifyDate,
          noticecontents: res.data.data.contents,
          // commentsList: res.data.data.comments,
        })
      );
    });
  }, [id, dispatch]);

  useEffect(() => {
    api
      .get(`/api/v1/comments/${id}?size=10&sort=desc&page=${page}`)
      .then((res) => {
        // console.log(res);
        dispatch(
          forumReducer.actions.setComment({
            commentsList: res.data.data.content,
            totalElements: res.data.data.totalElements,
            TotalPages: res.data.data.totalPages,
          })
        );
        dispatch(
          forumReducer.actions.setCommentPage({
            commentPage: page,
          })
        );
      });
  }, [dispatch, id, page]);

  return (
    <ForumDetailLayout
      subtitle={"ANONYMOUS"}
      title={"익명게시판"}
      contentTitle={noticetitle}
      createDate={createDate}
      modifyDate={modifyDate}
      contents={noticecontents}
      files={false}
      editMode={false}
      id={id}
      setPage={setPage}
      page={page + 1}
    />
  );
};

export default AnonymousDetail;
