import React from "react";
import styled, { css } from "styled-components";
import { ThemeProvider } from "styled-components";
import { lighten, darken } from "polished";

const Buttons = ({ text, onClick, color, background, border, id }) => {
  return (
    <ThemeProvider
      theme={{
        rgbColors: {
          default: "#3752D3",
          danger: "#D33751",
          wait: "#F79514",
          trans: "transparent",
        },
      }}
    >
      <Button
        onClick={onClick}
        color={color}
        background={background}
        border={border}
        id={id}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};

const Button = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 10px;
  width: 160px;
  height: 70px;
  margin-left: 20px;
  font-size: 22px;
  font-weight: 700;
  border: ${(props) => props.border};
  white-space: nowrap;
  color: ${(props) => props.color};
  ${(props) => {
    const selected = props.theme.rgbColors[props.background];
    return css`
      background: ${selected};
      &:hover {
        background: ${lighten(0.1, selected)};
      }
      &:active {
        background: ${darken(0.1, selected)};
      }
    `;
  }}
`;

Button.defaultProps = {
  background: "default",
};

export default Buttons;
