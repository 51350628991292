// Library
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";

// Components
import AuthorInfo from "../../components/AuthorInfo";
import Buttons from "../../components/Buttons";
import Modal from "../../components/Modal";
import PayInfoType from "../../components/PayInfoType";
import Title from "../../components/Title";
import {
  MyContainerWrap,
  MyContentContainer,
  MyDetailButtonContainerWrap,
} from "../../styled/Container";
import { MyPayInfoContainer } from "../../styled/Payment";

// util
import api from "../../redux/api";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../../components/MyButton";
import Errorcode from "../../util/Errorcode";

const DayOffDetail = () => {
  const myRole = localStorage.getItem("role");

  const navigate = useNavigate();
  const { id } = useParams();
  const accessToken = localStorage.getItem("token");

  // 화면 크기 불러오기 //
  const [resize, setResize] = useState();

  useEffect(() => {
    accessToken === null && navigate("/login");
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  const [contentData, setContentData] = useState();

  const [cancelModal, setCancelModal] = useState(false);
  const onClickCancel = useCallback(() => {
    setCancelModal(true);
  }, [cancelModal]);

  const onHandleCancel = useCallback(() => {
    api
      .put(`/api/v1/day-off/${id}`)
      .then((res) => {
        // console.log("res: ", res);
        Swal.fire("알림", "휴가를 취소하셨습니다.", "success").then((res) => {
          navigate("/day-off");
        });
      })
      .catch((err) => {
        Errorcode({ code: err?.response?.data?.code });
      });
  }, [navigate]);

  const onClickBack = useCallback(() => {
    navigate("/day-off");
  });

  useEffect(() => {
    api.get(`/api/v1/day-off/${id}`).then((res) => {
      setContentData(res?.data?.data);
    });
  }, []);

  console.log("contentData: ", contentData);

  return (
    <>
      <Grid container spacing={0}>
        <Card
          sx={{
            borderRadius: "10px",
            width: "100%",
          }}
        >
          {cancelModal ? (
            <Modal
              title={"알림"}
              content={"해당 휴가를 취소하시겠습니까?"}
              onClick={onHandleCancel}
              setOpenModal={setCancelModal}
            />
          ) : null}
          <Box p={3}>
            <ContentContainer>
              <Title title={"휴가"} subtitle={"T&A"} />
              <ContainerWrap
                background={"#F3F8FF"}
                boxShadow={"0px 2px 7px rgba(0, 0, 0, 0.07)"}
              >
                <AuthorInfo
                  userName={contentData?.userInfo?.name}
                  userTeam={contentData?.userInfo?.teamName}
                  userPosition={contentData?.userInfo?.rankName}
                />
                <PayInfoContainer>
                  <PayInfoWrap>
                    <div>결재 정보</div>
                    <span>(잔여일 : {contentData?.remainingDayOff})</span>
                  </PayInfoWrap>

                  <PayInfoType
                    id={id}
                    payType={
                      contentData?.type === "U"
                        ? "유급"
                        : contentData?.type === "M"
                        ? "무급"
                        : "기타"
                    }
                    category={contentData?.dayOffType === "D" ? "기간" : "시간"}
                    startDate={contentData?.startDate}
                    endDate={contentData?.endDate}
                    hours={contentData?.hours}
                    time={contentData?.timeOffType}
                  />
                  <DayOffComment>{contentData?.comment}</DayOffComment>
                </PayInfoContainer>
              </ContainerWrap>

              <ButtonContainerWrap>
                {resize >= 995 ? (
                  <>
                    {myRole === "ROLE_LV3" ? null : myRole ===
                      "ROLE_LV4" ? null : (
                      <Buttons
                        onClick={onClickCancel}
                        background={"danger"}
                        color={"#ffffff"}
                        text={"휴가 취소"}
                      />
                    )}

                    <Buttons
                      onClick={onClickBack}
                      text={"뒤로가기"}
                      background={"trans"}
                      border={"1px solid #232323"}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    {myRole === "ROLE_LV3" ? null : myRole ===
                      "ROLE_LV4" ? null : (
                      <MyButton
                        onClick={onClickCancel}
                        background={"danger"}
                        text={"휴가 취소"}
                        width={"90px"}
                        height={"55px"}
                      />
                    )}
                    <MyButton
                      onClick={onClickBack}
                      width={"90px"}
                      height={"55px"}
                      text={"뒤로가기"}
                      background={"cancel"}
                      color={"black"}
                      border={"1px solid #232323"}
                      margin={"0px 5px 10px 5px"}
                    />
                  </div>
                )}
              </ButtonContainerWrap>
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ContainerWrap = styled.div`
  ${MyContainerWrap}
`;

// 결제정보
const PayInfoContainer = styled.div`
  ${MyPayInfoContainer}
`;

export const PayInfoWrap = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    color: #3752d3;
  }
`;

export const DayOffComment = styled.div`
  display: flex;
  align-items: flex-start;

  border: 1px solid #3752d3;
  border-radius: 10px;
  font-size: 18px;
  padding: 10px;
  min-height: 300px;
`;

const ButtonContainerWrap = styled.div`
  ${MyDetailButtonContainerWrap}
`;

export default DayOffDetail;
