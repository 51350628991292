import React from "react";
import styled from "styled-components";
import "moment/locale/ko";
import { useNavigate } from "react-router-dom";
import DateAndTitle from "../DateAndTitle";
import { Viewer } from "@toast-ui/react-editor";

import Swal from "sweetalert2";

import { BiLockAlt } from "react-icons/bi";
import api from "../../redux/api";
import { useDispatch } from "react-redux";
import forumReducer from "../../redux/reducers/forumReducer";

const ForumCard = ({
  date,
  title,
  isSecret,
  content,
  id,
  name,
  height,
  commentCount,

  // 비밀글
  password,
  setPassword,
}) => {
  const dispatch = useDispatch();

  const role = localStorage.getItem("role");

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const goSecretPage = async (value) => {
    let body = {};

    try {
      if (role !== "ROLE_LV3") {
        body = { password: value };
        const { data } = await api.put(`/api/v1/anonymous/secret/${id}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const secretToken = data.data.secretToken;

        dispatch(forumReducer.actions.setSecretToken(secretToken));

        navigate(`/${name}/secret/${id}`);
      } else {
        body = { password: "" };

        const { data } = await api.put(`/api/v1/anonymous/secret/${id}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const secretToken = data.data.secretToken;

        dispatch(forumReducer.actions.setSecretToken(secretToken));

        navigate(`/${name}/secret/${id}`);
      }
    } catch (error) {
      Swal.fire("권한 없음", "비밀번호가 틀렸습니다.", "error", "확인");
    }
  };

  const goToDetailPage = async (e) => {
    if (isSecret === true) {
      if (role !== "ROLE_LV3") {
        Swal.fire({
          title: "비밀글입니다.",
          text: "비밀번호를 입력해주세요.",
          confirmButtonText: "확인",
          icon: "info",
          showCancelButton: true,
          input: "password",
        }).then((result) => {
          if (result.isConfirmed) {
            // console.log("인풋 값: ", result.value);

            goSecretPage(result.value);
          }
        });
      } else {
        goSecretPage();
      }
    } else {
      navigate(`/${name}/${id}`);
    }
  };

  return (
    <MyForumCard onClick={goToDetailPage} value={id} height={height}>
      {role !== "ROLE_LV3" ? (
        <>
          <DateAndTitle date={date} title={isSecret === true ? "" : title} />
          {isSecret === true ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiLockAlt size={70} color={"gray"} />
            </div>
          ) : (
            <MainContent>
              <Viewer
                initialValue={
                  content?.length === 50 ? `${content}...` : content
                }
              />
            </MainContent>
          )}
          {isSecret ? null : (
            <Comments>
              <p style={{ margin: "0" }}>댓글({commentCount})</p>
            </Comments>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              width: "95%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DateAndTitle date={date} title={title} />
            {isSecret ? <BiLockAlt size={22} color={"black"} /> : null}
          </div>

          <MainContent>
            <Viewer
              initialValue={content?.length === 50 ? `${content}...` : content}
            />
          </MainContent>

          <Comments>
            <p style={{ margin: "0" }}>댓글({commentCount})</p>
          </Comments>
        </>
      )}
    </MyForumCard>
  );
};

const MyForumCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 32%;
  cursor: pointer;
  height: ${(props) => props.height || "270px"};
  background: #f3f8ff;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
`;

const MainContent = styled.div`
  display: flex;
  width: 95%;
  height: 50%;
  border-bottom: 1px solid #232323;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  // text-overflow:ellipsis;
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 10%;
  margin-top: 10px;
  overflow: hidden;
`;

export default ForumCard;
