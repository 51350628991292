import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {
  // MyContainerWrap,
  // MyContentContainer,
  MyPaginationContainer,
} from "../../styled/Container";
import api from "../../redux/api";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/Buttons";
import { MyTitleAndSelect } from "../../styled/ListStyle";
import MyButton from "../../components/MyButton";
import DepartmentList from "../../components/human/DepartmentList";
import Modal from "../../components/Modal";
import Swal from "sweetalert2";
import SelectDepartment from "../../components/human/SelectDepartment";
import { Box, Card, Grid, useMediaQuery } from "@mui/material";
import Errorcode from "../../util/Errorcode";

const Department = () => {
  const navigate = useNavigate();
  const myRole = localStorage.getItem("role");
  const [teamList, setTeamList] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const [teamMemberName, setTeamMemberName] = useState("");

  const [selectedRank, setSelectedRank] = useState(null);
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [selectedTeam, setSelecteTeam] = useState(null);

  const [selectedMemberId, setSelectedMemberId] = useState();
  const [info, setInfo] = useState();

  const [addTeam, setAddTeam] = useState(false);
  const [newTeamList, setNewTeamList] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountCheck, setAccountCheck] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openTeamMemberModal, setOpenTeamMemberModal] = useState(false);
  const [userInfoYesOrNo, setUserInfoYesOrNo] = useState(false);
  const [teamIdForReRender, setTeamIdForReRender] = useState();

  const [teamId, setTeamId] = useState();
  const [selectTeam, setSelectTeam] = useState([]);
  const [selectMember, setSelectMember] = useState([]);

  const [rankList, setRankList] = useState([]);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);

  const [rankValue, setRankValue] = useState(null);
  const [workTypeValue, setWorkTypeValue] = useState(null);
  const [teamValue, setTeamValue] = useState(null);

  const onChangePhone = (e) => {
    const regex = /^010?([0-9]{4})?([0-9]{4})$/;
    if (regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    } else {
      setPhoneNumber(e.target.value);
    }
  };

  const onChangeAccount = (e) => {
    // console.log(e.target.value);
    const regex = /^(\d{6,6})(\d{2,2})(\d{6,6})$/;
    if (regex.test(e.target.value)) {
      setAccountNumber(e.target.value);
    } else {
      setAccountNumber(e.target.value);
    }
  };

  useEffect(() => {
    myRole === null && navigate("/login");
    myRole !== "ROLE_LV2" && navigate("/home");
  }, [myRole, navigate]);

  useEffect(() => {
    if (
      phoneNumber?.length === 11 &&
      /^010?([0-9]{4})?([0-9]{4})$/.test(phoneNumber)
    ) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      setPhoneCheck(true);
    } else {
      setPhoneCheck(false);
    }

    //하이픈 입력했을 때
    if (
      phoneNumber?.length === 13 &&
      /^010-?([0-9]{4})-?([0-9]{4})$/.test(phoneNumber)
    ) {
      setPhoneNumber(
        phoneNumber
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
      setPhoneCheck(true);
    } else {
      setPhoneCheck(false);
    }

    phoneNumber === "" && setPhoneCheck(true);
    phoneNumber === null && setPhoneCheck(true);
  }, [phoneNumber]);

  useEffect(() => {
    if (
      accountNumber?.length === 14 &&
      /^(\d{6,6})(\d{2,2})(\d{6,6})$/.test(accountNumber)
    ) {
      setAccountNumber(
        accountNumber?.replace(/(\d{6})(\d{2})(\d{6})/, "$1-$2-$3")
      );
      setAccountCheck(true);
    } else {
      setAccountCheck(false);
    }

    // 하이픈 입력했을 때
    if (
      accountNumber?.length === 16 &&
      /^(\d{6,6})+[-]+(\d{2,2})+[-]+(\d{6,6})$/.test(accountNumber)
    ) {
      setAccountNumber(
        accountNumber
          ?.replace(/-/g, "")
          .replace(/(\d{6})(\d{2})(\d{6})/, "$1-$2-$3")
      );
      setAccountCheck(true);
    } else {
      setAccountCheck(false);
    }

    accountNumber === "" && setAccountCheck(true);
    accountNumber === null && setAccountCheck(true);
  }, [accountNumber]);

  useEffect(() => {
    api.get(`/api/v1/teams`).then((res) => {
      setTeamList(res.data.list);
    });
  }, [teamMember, teamList.length]);

  // useEffect(() => {
  //   setInfo(info);
  //   console.log(info);
  // }, [info]);

  const showTeamMember = useCallback(
    (idx, e) => {
      const id = e.currentTarget.id;
      setTeamIdForReRender(id);

      api.get(`/api/v1/teams/${id}`).then((res) => {
        // id !== "1" && console.log(res);
        id !== "1" && setTeamMember(res.data.list);
      });

      if (e.currentTarget.name === "퇴사자") {
        api.get(`/api/v1/teams/leaves`).then((res) => {
          // console.log(res);
          setTeamMember(res.data.list);
        });
      }

      !selectTeam.includes(id)
        ? setSelectTeam(() => [id])
        : setSelectTeam(selectTeam?.filter((button) => button === id));
    },
    [selectTeam]
  );

  useEffect(() => {
    teamsList.shift();
    // console.log("하이");
  }, [teamsList]);

  const showEmployeeInfo = useCallback(
    (idx, e) => {
      const id = e.currentTarget.id;

      setSelectedMemberId(id);
      setPhoneNumber("");
      setAccountNumber("");

      // console.log(teamMember[idx]);

      // if (teamMember[idx]?.team.name !== "퇴사자") {
      setInfo(teamMember[idx]);
      setTeamMemberName(teamMember[idx]?.name);
      setSelectedRank(teamMember[idx]?.rank.id);
      setSelectedWorkType(teamMember[idx]?.workType.id);
      setSelecteTeam(teamMember[idx]?.team.id);
      setUserInfoYesOrNo(true);
      setRankValue({
        value: teamMember[idx]?.rank?.id,
        label: teamMember[idx]?.rank?.name,
      });
      setWorkTypeValue({
        value: teamMember[idx]?.workType?.id,
        label: teamMember[idx]?.workType?.name,
      });
      setTeamValue({
        value: teamMember[idx]?.team?.id,
        label: teamMember[idx]?.team?.name,
      });
      // }
      // else {
      //   setUserInfoYesOrNo(false);
      // }

      api.get("/api/v1/items").then((res) => {
        setWorkTypeList(
          res.data.data.workTypes
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );

        setTeamsList(
          res.data.data.teams
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );

        setRankList(
          res.data.data.ranks
            ?.map((item) => [{ value: item.id, label: item.name }])
            .map((item) => item[0])
        );
      });

      api.get(`/api/v1/users/${id}`).then((res) => {
        setPhoneNumber(res.data.data.phone);
        setAccountNumber(res.data.data.accountNumber);
      });

      !selectMember.includes(id)
        ? setSelectMember(() => [id])
        : setSelectMember(selectMember.filter((button) => button === id));
    },
    [selectMember, teamMember]
  );

  const AddTeamHandler = () => {
    setAddTeam(!addTeam);
  };

  const getNewTeamList = (e) => {
    setNewTeamList(e.target.value);
    // console.log(e.target.value);
  };

  const saveUserInfo = () => {
    const body = {
      rankId:
        selectedRank?.value === undefined
          ? rankValue?.value
          : selectedRank?.value,
      workTypeId:
        selectedWorkType?.value === undefined
          ? workTypeValue?.value
          : selectedWorkType?.value,
      teamId:
        selectedTeam?.value === undefined
          ? teamValue?.value
          : selectedTeam?.value,
      phoneNumber: phoneCheck
        ? phoneNumber?.replace("-", "").replace("-", "")
        : "",
      accountNumber: accountCheck
        ? accountNumber?.replace("-", "").replace("-", "")
        : "",
    };
    // console.log(selectedMemberId);
    // console.log(body);

    (phoneCheck !== true || accountCheck !== true) &&
      Swal.fire({
        title: "알림",
        text: "저장에 실패하였습니다.",
        icon: "error",
        confirmButtonText: "확인",
      });
    selectedMemberId !== undefined &&
      api
        .put(`/api/v1/users/${selectedMemberId}`, body)
        .then((res) => {
          // console.log(res);
          Swal.fire({
            title: "알림",
            text: "저장되었습니다.",
            icon: "success",
            confirmButtonText: "확인",
          });
          api
            .get(
              `/api/v1/teams/${
                selectedTeam.value === undefined
                  ? teamValue.value
                  : selectedTeam.value
              }`
            )
            .then((res) => {
              setTeamIdForReRender(selectedTeam.value);
              setTeamMember(res.data.list);
              // setSelectTeam([`${selectedTeam.value}`]);
              setSelectTeam([`${teamValue.value}`]);
              setUserInfoYesOrNo(false);
              setSelectMember([]);
            });
        })
        .catch((err) => {
          Swal.fire({
            title: "알림",
            text: "저장에 실패하였습니다.",
            icon: "error",
            confirmButtonText: "확인",
          });
        });

    const body2 = {
      teamName: newTeamList,
    };

    addTeam &&
      api
        .post(`/api/v1/teams`, body2)
        .then((res) => {
          // console.log(res);
          api.get(`/api/v1/teams`).then((res) => {
            setTeamList(res.data.list);
            // console.log(res);
          });
          setAddTeam(!addTeam);
          setNewTeamList("");
          Swal.fire({
            title: "알림",
            text: "저장되었습니다.",
            icon: "success",
            confirmButtonText: "확인",
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "알림",
            text: "저장에 실패하였습니다.",
            icon: "error",
            confirmButtonText: "확인",
          });
        });
  };

  const clickDeleteBtn = (e) => {
    setOpenModal(true);
    // console.log(e.currentTarget.id.replace("_delete", ""));
    setTeamId(e.currentTarget.id.replace("_delete", ""));
  };

  const clickDeleteBtnTeamMember = (e) => {
    setOpenTeamMemberModal(true);
    setSelectedMemberId(e.currentTarget.id.replace("_delete", ""));
  };

  const deleteTeamList = () => {
    api
      .delete(`/api/v1/teams/${teamId}`)
      .then((res) => {
        // console.log(res);
        api.get(`/api/v1/teams`).then((res) => {
          setTeamList(res.data.list);
          setOpenModal(false);
          Swal.fire({
            title: "알림",
            text: "부서가 삭제되었습니다.",
            icon: "success",
            confirmButtonText: "확인",
          });
        });
      })
      .catch((err) => {
        setOpenModal(false);
        Errorcode({ code: err?.response?.data?.code });
      });
  };

  const deleteTeamMember = () => {
    api
      .delete(`/api/v1/users/${selectedMemberId}`)
      .then((res) => {
        setOpenTeamMemberModal(false);
        api.get(`/api/v1/teams/${teamIdForReRender}`).then((res) => {
          setTeamMember(res.data.list);
          Swal.fire({
            title: "알림",
            text: "팀원이 퇴사 처리 되었습니다.",
            icon: "success",
            confirmButtonText: "확인",
          });
        });
      })
      .catch((err) => {
        setOpenTeamMemberModal(false);
        Errorcode({ code: err?.response?.data?.code });
      });
  };

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Grid
      container
      spacing={0}
      sx={{ borderRadius: "10px" }}
      style={{ position: "relative" }}
    >
      {openModal ? (
        <Modal
          title={"알림"}
          content={"부서를 삭제하겠습니까?"}
          setOpenModal={setOpenModal}
          onClick={deleteTeamList}
        />
      ) : null}

      {openTeamMemberModal ? (
        <Modal
          title={"부서원 삭제"}
          content={"부서원을 삭제하겠습니까?"}
          setOpenModal={setOpenTeamMemberModal}
          onClick={deleteTeamMember}
        />
      ) : null}
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px", width: "100%" }}>
          <Box p={3}>
            <Title subtitle={"P&M"} title={"부서관리"} />

            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <SmallContainerWrap>
                  <XSContainer>
                    <TitleAndSelect>
                      <span>부서 항목</span>
                      <MyButton
                        width={"60px"}
                        height={"40px"}
                        text={"+"}
                        onClick={AddTeamHandler}
                      />
                    </TitleAndSelect>
                    <SubDescription>부서를 선택하세요</SubDescription>
                    <InnerScrollWrap>
                      {teamList.map((item, idx) => (
                        <DepartmentList
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          teamName={item.name}
                          onClick={showTeamMember}
                          idx={idx}
                          clickDeleteBtn={clickDeleteBtn}
                          className={
                            selectTeam?.includes(`${item.id}`) && "clickList"
                          }
                        />
                      ))}
                      {addTeam && (
                        <InputDiv>
                          <InputList
                            placeholder="부서명"
                            value={newTeamList}
                            onChange={(e) => getNewTeamList(e)}
                          />
                        </InputDiv>
                      )}
                    </InnerScrollWrap>
                  </XSContainer>
                </SmallContainerWrap>
              </Grid>

              <Grid item xs={12} lg={4}>
                <SmallContainerWrap>
                  <XSContainer>
                    <TitleAndSelect>
                      <span>부서원</span>
                    </TitleAndSelect>
                    <SubDescription>부서원을 선택하세요</SubDescription>
                    <InnerScrollWrap>
                      {teamMember.map((item, idx) => (
                        <DepartmentList
                          key={item.id}
                          id={item.id}
                          teamName={item.name}
                          teamName2={item.team.name}
                          onClick={showEmployeeInfo}
                          idx={idx}
                          clickDeleteBtn={clickDeleteBtnTeamMember}
                          className={
                            selectMember.includes(`${item.id}`) && "clickList"
                          }
                        />
                      ))}
                    </InnerScrollWrap>
                  </XSContainer>
                </SmallContainerWrap>
              </Grid>

              <Grid item xs={12} lg={4}>
                <SmallContainerWrap>
                  {userInfoYesOrNo ? (
                    <XSContainer>
                      <TitleAndSelect>
                        <span>{teamMemberName ? teamMemberName : "직원"}</span>
                      </TitleAndSelect>
                      <SubDescription>
                        {teamMemberName ? teamMemberName : "직원"}의 정보를
                        변경할 수 있습니다.
                      </SubDescription>
                      <InnerScrollWrap style={{ minHeight: "530px" }}>
                        <SelectionList>
                          <SelectionTitle>직급</SelectionTitle>
                          <div
                            style={{
                              width: "70%",
                              height: "100%",
                            }}
                          >
                            {selectedTeam !== 1 ? (
                              <SelectDepartment
                                ranks={rankList}
                                selectedRank={selectedRank}
                                setSelectedRank={setSelectedRank}
                                rankValue={rankValue}
                                setRankValue={setRankValue}
                              />
                            ) : (
                              <InfoRetiree>
                                <span>{rankValue?.label}</span>
                              </InfoRetiree>
                            )}
                          </div>
                        </SelectionList>

                        <SelectionList>
                          <SelectionTitle>근로형태</SelectionTitle>
                          <div
                            style={{
                              width: "70%",
                              height: "100%",
                            }}
                          >
                            {selectedTeam !== 1 ? (
                              <SelectDepartment
                                workTypes={workTypeList}
                                selectedWorkType={selectedWorkType}
                                setSelectedWorkType={setSelectedWorkType}
                                workTypeValue={workTypeValue}
                                // FIXME:
                                setWorkTypeValue={setWorkTypeValue}
                              />
                            ) : (
                              <InfoRetiree>
                                <span>{workTypeValue?.label}</span>
                              </InfoRetiree>
                            )}
                          </div>
                        </SelectionList>
                        <SelectionList>
                          <SelectionTitle>부서</SelectionTitle>
                          <div
                            style={{
                              width: "70%",
                              height: "100%",
                            }}
                          >
                            {selectedTeam !== 1 ? (
                              <SelectDepartment
                                teams={teamsList}
                                selectedTeam={selectedTeam}
                                setSelectedTeam={setSelecteTeam}
                                teamValue={teamValue}
                                setTeamValue={setTeamValue}
                              />
                            ) : (
                              <InfoRetiree>
                                <span>{teamValue?.label}</span>
                              </InfoRetiree>
                            )}
                          </div>
                        </SelectionList>
                        <SelectionList>
                          <SelectionTitle>전화번호</SelectionTitle>

                          {selectedTeam !== 1 ? (
                            <NumbersInput
                              placeholder="핸드폰 번호를 입력해 주세요."
                              name="phoneNumber"
                              onChange={onChangePhone}
                              value={phoneNumber}
                            />
                          ) : (
                            <InfoRetiree>
                              <span>{phoneNumber}</span>
                            </InfoRetiree>
                          )}
                        </SelectionList>
                        <SelectionList>
                          <SelectionTitle>계좌번호</SelectionTitle>

                          {selectedTeam !== 1 ? (
                            <NumbersInput
                              placeholder="국민은행 계좌를 입력해 주세요."
                              name="accountNumber"
                              onChange={onChangeAccount}
                              value={accountNumber}
                            />
                          ) : (
                            <InfoRetiree>
                              <span>{phoneNumber}</span>
                            </InfoRetiree>
                          )}
                        </SelectionList>
                      </InnerScrollWrap>
                    </XSContainer>
                  ) : (
                    <XSContainer>
                      <TitleAndSelect>
                        <span>직원</span>
                      </TitleAndSelect>
                      <SubDescription>
                        직원의 정보를 변경할 수 있습니다.
                      </SubDescription>
                    </XSContainer>
                  )}
                </SmallContainerWrap>
              </Grid>
            </Grid>
            <PaginationContainer>
              {mdUp ? (
                <Buttons text={"저장"} color={"white"} onClick={saveUserInfo} />
              ) : (
                <MyButton
                  text={"저장"}
                  width={"90px"}
                  height={"55px"}
                  color={"white"}
                  onClick={saveUserInfo}
                />
              )}
            </PaginationContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;

const SmallContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  // width: 32%;
  min-height: 640px;
  border-radius: 10px;
  align-items: center;
  background: #f3f8ff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.07);
`;

const XSContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 400px;
  width: 90%;
  min-height: 640px;
`;

const InnerScrollWrap = styled.div`
  display: flex;
  flex-direction: column;
  // width: 400px;
  width: 100%;
  max-height: 530px;
  overflow-y: auto;
`;

const TitleAndSelect = styled.div`
  ${MyTitleAndSelect}
  width: 100%;
`;

const SubDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #b2b8c1;
  margin-bottom: 10px;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 60px;
`;

const InputList = styled.input`
  border: 1px solid #b2b8c1;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  padding-left: 10px;
  background: transparent;

  &:focus {
    outline: none;
  }
`;

const SelectionList = styled.div`
  border: 1px solid #3752d3;
  width: 99%;
  height: 50px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
`;

const SelectionTitle = styled.div`
  width: 22%;
  margin-left: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 11px;
  color: #232323;
  display: flex;
  align-items: center;
`;

const InfoRetiree = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  span {
    font-size: 13px;
    letter-spacing: 0.1em;
  }
`;

const NumbersInput = styled(InputList)`
  margin-top: 3px;
  width: 70%;
  border: none;
  letter-spacing: 0.1em;
  font-size: 13px;
`;

export default Department;
