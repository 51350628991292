import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useLocation } from "react-router-dom";

const DateAndTitle = ({ date, title, writerName, rankName, email }) => {
  // const utcDate = new Date(date);
  // const myDate = moment(utcDate).format("YY.MM.DD");

  const myDate =
    date?.substr(2, 2) + "." + date?.substr(5, 2) + "." + date?.substr(8, 2);

  const location = useLocation();

  return (
    <>
      {location.pathname === "/idea" ? (
        <IdeaDateAndTitles>
          <DateDiv>{myDate}</DateDiv>
          <WriterInfo>
            <span style={{ letterSpacing: "0.2em" }}>
              {writerName}
              &middot;
              {rankName}
            </span>
            {email}
          </WriterInfo>
        </IdeaDateAndTitles>
      ) : (
        <DateAndTitles>
          <DateDiv>{myDate}</DateDiv>
          <span style={{ fontWeight: "600" }}>{title}</span>
        </DateAndTitles>
      )}
    </>
  );
};

const DateAndTitles = styled.div`
  display: flex;
  width: 95%;
  height: 35px;
  margin-top: 15px;

  span {
    margin-left: 10px;
    display: flex;
    width: 70%;
    align-items: center;
    letter-spacing: 0.1em;
  }
`;

const IdeaDateAndTitles = styled.div`
  display: flex;
  width: 95%;
  height: 35px;
  margin-top: 15px;
  justify-content: space-between;
  overflow: hidden;
`;

const DateDiv = styled.div`
  color: #5886bd;
  font-weight: 700;
  background: #c9e4ff;
  border-radius: 15px;
  padding: 5px 10px;
  letter-spacing: 0.1em;
`;

const WriterInfo = styled.div`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.035em;
  color: #232323;

  span {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (min-width: 1199px) and (max-width: 1400px) {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export default DateAndTitle;
