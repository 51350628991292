import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  MyContainer,
  MyContentContainer,
  MyDetailButtonContainerWrap,
  MyEditContainerWrap,
  MyEditFileContainerWrap,
  MyMainContainer,
} from "../../styled/Container";
import Title from "../Title";
import FileUpload from "./FileUpload";
import ToastEditor from "../toasteditor/ToastEditor";
import { useDispatch, useSelector } from "react-redux";
import forumReducer from "../../redux/reducers/forumReducer";
import Buttons from "../Buttons";
import { MyEditorTitle } from "../../styled/Editor";
import { useLocation, useParams } from "react-router-dom";
import Modal from "../Modal";
import Loading from "../Loading";
import { Box, Card, Grid } from "@mui/material";
import MyButton from "../MyButton";
import Swal from "sweetalert2";

const ForumEditor = ({
  subtitle,
  title,
  buttontext,
  onClick,
  onEdit,
  cancel,
  onChangeTeams,
  teamsId,
  teams,
  setOpenModal,
  openModal,
  setOpenCheckModal,
  openCheckModal,
  loading,
  setLoading,
  // 비밀글 등록
  isSecret,
  setIsSecret,
  password,
  setPassword,
}) => {
  const [contentTitle, setContentTitle] = useState("");
  const [contentsHTML, setContentsHTML] = useState();
  const noticetitle = useSelector((state) => state.forum.noticetitle);

  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const getContentTitle = (e) => {
    setContentTitle(e.target.value);
  };

  const [resize, setResize] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResize(window.innerWidth);
    });

    const time = setTimeout(() => {
      // console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    dispatch(
      forumReducer.actions.setContentsInfo({
        contentTitle,
        contentsHTML,
      })
    );
  }, [contentTitle, contentsHTML, dispatch]);

  useEffect(() => {
    if (isSecret) {
      Swal.fire("알림", "비밀글로 등록합니다.", "info", "확인");
    }
  }, [isSecret]);

  return (
    <Grid
      container
      spacing={0}
      sx={{ background: "#fff", borderRadius: "10px" }}
      style={{ position: "relative" }}
    >
      {loading ? <Loading /> : null}
      {(location.pathname === "/meeting/new" ||
        location.pathname === `/meeting/edit/${id}` ||
        location.pathname === "/notices/new" ||
        location.pathname === `/notices/edit/${id}` ||
        location.pathname === "/master-notices/new" ||
        location.pathname === `/master-notices/edit/${id}` ||
        location.pathname === "/anonymous/new") &&
      openModal ? (
        <Modal
          title={"게시물 작성"}
          content={"게시물 작성을 취소하시겠습니까?"}
          onClick={cancel}
          setOpenModal={setOpenModal}
        />
      ) : null}
      {(location.pathname === "/meeting/new" ||
        location.pathname === `/meeting/edit/${id}` ||
        location.pathname === "/notices/new" ||
        location.pathname === `/notices/edit/${id}` ||
        location.pathname === "/master-notices/new" ||
        location.pathname === `/master-notices/edit/${id}` ||
        location.pathname === "/anonymous/new") &&
      openCheckModal ? (
        <Modal
          title={"게시물 등록"}
          content={"게시물을 등록하시겠습니까?"}
          onClick={onClick}
          setOpenModal={setOpenCheckModal}
        />
      ) : null}
      <Grid item xs={12} lg={12}>
        <Card sx={{ borderRadius: "10px" }}>
          <Box p={3}>
            <Title subtitle={subtitle} title={title} />
            <ContentContainer>
              <EditContainerWrap>
                <section
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <EditorTitle
                    onChange={getContentTitle}
                    placeholder="제목을 입력해 주세요"
                    defaultValue={onEdit ? noticetitle : null}
                  />

                  {/* TODO: 비밀글  */}
                  {location.pathname === "/anonymous/new" ? (
                    <SecretBox>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <SecretCheck
                          type={"checkbox"}
                          onClick={() => {
                            setIsSecret(!isSecret);
                          }}
                        />
                        <span
                          style={{
                            paddingLeft: 10,
                          }}
                        >
                          비밀글로 등록
                        </span>
                      </div>

                      {!isSecret ? null : (
                        <input
                          placeholder="비밀번호를 입력해주세요"
                          value={password}
                          onChange={(e) => setPassword(e.target.value.trim())}
                          type={"password"}
                          style={{
                            width: "200px",
                            border: "1px solid #b2b8c1",
                            borderRadius: 10,
                            padding: "10px 5px",
                          }}
                        />
                      )}
                    </SecretBox>
                  ) : null}

                  {(location.pathname === "/meeting/new" ||
                    location.pathname === `/meeting/edit/${id}`) && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SelectSpan>해당부서</SelectSpan>
                      <SelectBox onChange={onChangeTeams} value={teamsId}>
                        {teams?.map(
                          (item) =>
                            item.name !== "퇴사자" && (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            )
                        )}
                      </SelectBox>
                    </div>
                  )}
                </section>
                <section style={{ width: "100%" }}>
                  <ToastEditor
                    setContentsHTML={setContentsHTML}
                    onEdit={onEdit}
                  />
                </section>
              </EditContainerWrap>
              {location.pathname === "/anonymous/new" ? null : (
                <EditFileContainerWrap>
                  <FileUpload sectionHeight={"45px"} setLoading={setLoading} />
                </EditFileContainerWrap>
              )}

              {resize >= 995 ? (
                <ButtonContainerWrap>
                  <Buttons
                    text={"작성 취소"}
                    onClick={() => setOpenModal(true)}
                    color={"white"}
                    background={"danger"}
                  />
                  <Buttons
                    text={buttontext}
                    onClick={() => setOpenCheckModal(true)}
                    color={"white"}
                  />
                </ButtonContainerWrap>
              ) : (
                <ButtonContainerWrap>
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    background={"danger"}
                    onClick={() => setOpenModal(true)}
                    text={"작성 취소"}
                    margin={"0px 5px 10px 5px"}
                  />
                  <MyButton
                    width={"90px"}
                    height={"55px"}
                    text={"등록"}
                    onClick={() => setOpenCheckModal(true)}
                    margin={"0px 5px 10px 5px"}
                  />
                </ButtonContainerWrap>
              )}
            </ContentContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const MainContainer = styled.div`
  ${MyMainContainer}
`;

const Container = styled.div`
  ${MyContainer}
`;

const ContentContainer = styled.div`
  ${MyContentContainer}
`;

const ButtonContainerWrap = styled.div`
  ${MyDetailButtonContainerWrap}
`;

const EditContainerWrap = styled.div`
  ${MyEditContainerWrap}
`;

const EditFileContainerWrap = styled.div`
  ${MyEditFileContainerWrap}
`;

const EditorTitle = styled.input`
  ${MyEditorTitle}
`;

const SelectSpan = styled.span`
  color: ##232323;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.1em;
  margin-right: 5px;

  @media screen and (max-width: 995px) {
    display: none;
  }
`;

const SelectBox = styled.select`
  background: #4285f4;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 15px;
`;

const SecretBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  margin-bottom: 10px;
`;

const SecretCheck = styled.input`
  width: 16px;
  height: 16px;
`;

export default ForumEditor;
