import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import selectReducer from "../redux/reducers/selectReducer";
import moment from "moment";
import useOnClickOutside from "../hooks/useOnClickOutside";

const FilterCalander = ({ setPage }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [filterDate, setFilterDate] = useState([]);

  const selectedStartDate = new Date(filterDate[0]?.startDate);
  const selectedStartDateFormat = moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedStartDateFormatForMobile = moment(selectedStartDate).format("MM/DD");
  const selectedEndDate = new Date(filterDate[0]?.endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");
  const selectedEndDateFormatForMobile = moment(selectedEndDate).format("MM/DD");

  const calendarRef = useRef();

  useOnClickOutside(calendarRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    dispatch(
      selectReducer.actions.setDates({
        startDate: filterDate[0]?.startDate,
        endDate: filterDate[0]?.endDate,
      })
    );
  }, [state, filterDate, dispatch]);

  const showCalander = () => {
    setOpen(!open);
  };

  const filterDateHandler = () => {
    setOpen(!open);
    // console.log(state);
    setFilterDate(state);
    setPage(0);
  };

  const showAllList = () => {
    setOpen(!open);
    setFilterDate([]);
    setPage(0);
  };

  return (
    <>
      <SelectButtonWrap>
        <SelectDiv>
          {filterDate[0]?.startDate === undefined ? (
            <SelectText>날짜 - 날짜</SelectText>
          ) : (
            <SelectText>
              {selectedStartDateFormat} -{selectedEndDateFormat}
            </SelectText>
          )}
        </SelectDiv>
        <SelectDiv2>
          {filterDate[0]?.startDate === undefined ? (
            <SelectText>날짜</SelectText>
          ) : (
            <SelectText>
              {selectedStartDateFormatForMobile}-{selectedEndDateFormatForMobile}
            </SelectText>
          )}
        </SelectDiv2>
        <IconDiv onClick={showCalander}>
          <div>
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
        </IconDiv>
        {open && (
          <CalendarDiv ref={calendarRef}>
            <DateRange
              className="filter-calendar"
              editableDateInputs={true}
              ranges={state}
              moveRangeOnFirstSelection={false}
              onChange={(item) => setState([item.selection])}
            />
            <CheckBox>
              <button onClick={showAllList}>전체보기</button>
              <button onClick={filterDateHandler}>확인</button>
            </CheckBox>
          </CalendarDiv>
        )}
      </SelectButtonWrap>
    </>
  );
};

const SelectButtonWrap = styled.div`
  width: 280px;
  background: transparent;
  border-radius: 10px;
  margin-left: 15px;
  padding: 0px 15px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 13px;
  min-height: 38px;
  outline: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: hsl(0, 0%, 80%);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  position: relative;

  @media screen and (max-width: 1410px) {
    width: 180px;
  }
`;

const SelectDiv = styled.div`
  align-items: center;
  display: grid;
  flex-wrap: wrap;
  padding: 2px 8px;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: 1410px) {
    display: none;
  }
`;

const SelectDiv2 = styled.div`
  align-items: center;
  display: none;
  flex-wrap: wrap;
  padding: 2px 8px;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (max-width: 1410px) {
    display: grid;
  }
`;

const SelectText = styled.div`
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
`;

const IconDiv = styled.div`
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
  box-sizing: border-box;

  div {
    color: hsl(0, 0%, 80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      color: hsl(0, 0%, 40%);
    }

    svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
      vertical-align: middle;

      &:after,
      &:before {
        box-sizing: border-box;
      }
    }
  }
`;

const CalendarDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 0%;
  margin-top: 1px;
  background: white;
  height: 350px;
  z-index: 100;

  @media screen and (max-width: 1410px) {
    left: -10%;
  }
`;

const CheckBox = styled.div`
  width: 100%;
  height: 46.4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: end;

  button {
    border: none;
    background: white;
    border-radius: 5px;
    height: 30px;
    color: #4285f4;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export default FilterCalander;
