import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

import styled from "styled-components";

import MyButton from "../MyButton";
import FileUpload from "./FileUpload";

const TextArea = ({ onClick, setTextValue, setTextLength, textValue, textLength, setLoading }) => {
  // useEffect(() => {
  //   console.log(textValue);
  // }, [textValue]);

  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleSetTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      let val = e.target.value;
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      e.target.value = val.substring(0, start) + "\t" + val.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      handleSetValue(e);
      return false;
    }
  };

  const handleSetLength = (e) => {
    let test_length = textValue.length;
    let max_length = 200;

    setTextLength(test_length);
    if (test_length > max_length) {
      alert(`${max_length}자 이상 입력할 수 없습니다`);
      setTextValue(textValue.substring(0, max_length));
      setTextLength(max_length);
    }
  };

  return (
    <>
      <Grid item xs={12} lg={7} sx={{ marginBottom: lgUp ? "" : "10px" }}>
        <div
          style={{
            border: "1px solid #b2b8c1",
            borderRadius: "10px",
            padding: "4px 10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h5 style={{ padding: "0", margin: "0" }}>작성</h5>
          <NewIdeaSmallDiv>
            <NewIdeaTextArea
              // onPaste={(e) => {
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              value={textValue}
              onChange={(e) => handleSetValue(e)}
              placeholder="아이디어를 입력하세요"
              onKeyDown={(e) => handleSetTab(e)}
              onKeyUp={handleSetLength}
            ></NewIdeaTextArea>
            <span>{textLength}자/200자</span>
          </NewIdeaSmallDiv>
        </div>
      </Grid>

      <Grid item xs={12} lg={3.5} sx={{ marginBottom: lgUp ? "" : "10px" }}>
        <div
          style={{
            border: "1px solid #b2b8c1",
            padding: "10px",
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <FileUpload marginTop={"-15px"} setLoading={setLoading} />
        </div>
      </Grid>

      <Grid item xs={12} lg={1} sx={{ height: lgUp ? "105px" : "35px" }}>
        <div
          style={{
            cursor: "pointer",
            border: "none",
            borderRadius: "10px",
            fontSize: " 14px",
            fontWeight: "700",
            color: "white",
            background: "#3752D3",

            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onClick}
        >
          등록
        </div>
      </Grid>
    </>
  );
};

const NewIdeaWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  width: 1540px;
  height: 100px;
  // background: yellow;
  margin-top: 5px;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const NewIdeaDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64.5%;
  height: 90px;
  // background: yellow;
  border: 1px solid #b2b8c1;
  border-radius: 10px;
  padding: 4px 10px;

  h5 {
    font-weight: 500;
    font-size: 14px;
    color: #232323;
    width: 100%;
    margin: 0;
    // margin-top: -5px;
    // background: pink;
  }
`;

const NewIdeaSmallDiv = styled.div`
  // background: red;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75px;

  span {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #3b4351;
    text-align: right;
  }
`;

const NewIdeaTextArea = styled.textarea`
  width: 100%;
  height: 90%;
  resize: none;
  border: none;
  font-size: 13px;
  border-radius: 10px;
  // padding: 10px;
  &:focus {
    outline: none;
  }
`;

const NewIdeaFile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24%;
  height: 78px;
  border: 1px solid #b2b8c1;
  padding: 10px;
  border-radius: 10px;
`;

export default TextArea;
