import axios from "axios";
import Swal from "sweetalert2";

const accessToken = localStorage.getItem("token");

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
});

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     console.log(error);
//     const code = error.response.data.code;
//     console.log(code);
//     // if (code === 905) {
//     //   const originalRequest = error.config;
//     //   const refreshToken = await EncryptedStorage.getItem('refreshToken');
//     //   const userId = await EncryptedStorage.getItem('userId');
//     //   const {data} = await axios.post(
//     //     ${process.env.REACT_APP_BASE_API_URL}/re-issue, // token refresh api
//     //     {userId},
//     //     {headers: {authorization: `Bearer ${refreshToken}`}},
//     //   );

//     //   dispatch(userSlice.actions.setAccessToken(data.data.accessToken));
//     //   originalRequest.headers.authorization = Bearer ${data.data.accessToken};

//     //   return axios(originalRequest);
//     // } else
//     if (code === 101) {
//       Swal.fire("알림", "이미 존재하는 회원입니다.");
//       return;
//     } else if (code === 102) {
//       Swal.fire("알림", "해당 유저는 존재하지 않습니다.");
//       return;
//     } else if (code === 103) {
//       Swal.fire("알림", "비밀번호를 틀리셨습니다.");
//       return;
//     } else if (code === 104) {
//       Swal.fire("알림", "가입처리가 승인되지 않았습니다.");
//       return;
//     } else if (code === 105) {
//       Swal.fire("알림", "존재하지 않는 상태값입니다.");
//       return;
//     } else if (code === 106) {
//       Swal.fire("알림", "등록된 직원이 없습니다.");
//       return;
//     } else if (code === 107) {
//       Swal.fire("알림", "변경이 불가능한 유저입니다.");
//       return;
//     } else if (code === 111) {
//       Swal.fire(
//         "알림",
//         "부서에 속한 인원이 존재하거나 삭제가 불가능한 부서입니다."
//       );
//       return;
//     } else if (code === 112) {
//       Swal.fire("알림", "존재하지 않는 부서입니다.");
//       return;
//     } else if (code === 201) {
//       Swal.fire("알림", "존재하지 않는 공지사항입니다.");
//       return;
//     } else if (code === 203) {
//       Swal.fire("알림", "토큰을 찾을수가 없습니다.");
//       return;
//     } else if (code === 204) {
//       Swal.fire("알림", "익명게시글이 존재하지 않습니다.");
//       return;
//     } else if (code === 302) {
//       Swal.fire("알림", "출근기록이 없습니다.");
//       return;
//     } else if (code === 303) {
//       Swal.fire("알림", "퇴근을 이미 하셨습니다.");
//       return;
//     } else if (code === 501) {
//       Swal.fire("알림", "존재하지 않는 휴가입니다.");
//       return;
//     } else if (code === 502) {
//       Swal.fire("알림", "사용하지 않은 유급휴가가 존재합니다.");
//       return;
//     } else if (code === 503) {
//       Swal.fire("알림", "사용가능한 유급휴가가 부족합니다.");
//       return;
//     } else if (code === 504) {
//       Swal.fire("알림", "이미 등록 되어있는 휴가입니다.");
//       return;
//     } else if (code === 505) {
//       Swal.fire("알림", "시간을 정확히 입력해주세요.");
//       return;
//     } else if (code === 506) {
//       Swal.fire(
//         "알림",
//         "휴가 취소 가능 시간을 초과하셨습니다.(당일 오후 1시까지)"
//       );
//       return;
//     } else if (code === 700) {
//       Swal.fire("알림", "정보가 올바르지 않습니다.");
//       return;
//     } else if (code === 701) {
//       Swal.fire("알림", "정보가 올바르지 않습니다.");
//       return;
//     } else if (code === 800) {
//       Swal.fire("알림", "게시글이 존재하지 않습니다.");
//       return;
//     } else if (code === 901) {
//       Swal.fire("알림", "회원정보가 일치하지 않습니다.");
//       return;
//     } else if (code === 902) {
//       Swal.fire("알림", "알수없는 회원의 정보입니다.");
//       return;
//     } else if (code === 903) {
//       Swal.fire("알림", "접근이 불가합니다.");
//       return;
//     } else if (code === 999) {
//       Swal.fire("알림", "서버 에러");
//       return;
//     }
//     return Promise.reject(error);
//   }
// );

export default api;
