import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import api from "../../redux/api";
import styled from "styled-components";
import {
  MyDetailCommentsWrap,
  MyPaginationContainer,
} from "../../styled/Container";
import ForumCommentLists from "./ForumCommentLists";
import MyButton from "../MyButton";
import forumReducer from "../../redux/reducers/forumReducer";
import Pagination2 from "../paging/Pagination2";
import Swal from "sweetalert2";
import Errorcode from "../../util/Errorcode";

const Comments = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { id } = useParams();

  const boardId = useParams();
  const anonymousToken = localStorage.getItem("anonymousToken");
  const commentsList = useSelector((state) => state.forum.commentsList);
  const totalElements = useSelector((state) => state.forum.totalElements);
  const totalPages = useSelector((state) => state.forum.totalPages);
  const commentPage = useSelector((state) => state.forum.commentPage);

  const secretToken = useSelector((state) => state.forum.secretToken);

  const [textValue, setTextValue] = useState("");
  const [textLength, setTextLength] = useState(0);

  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };

  const handleSetTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      let val = e.target.value;
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      e.target.value = val.substring(0, start) + "\t" + val.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      handleSetValue(e);
      return false;
    }
  };

  const handleSetLength = (e) => {
    let test_length = textValue.length;
    let max_length = 200;

    setTextLength(test_length);
    if (test_length > max_length) {
      alert(`${max_length}자 이상 입력할 수 없습니다`);
      setTextValue(textValue.substring(0, max_length));
      setTextLength(max_length);
    }
  };

  const postComments = useCallback(
    (e) => {
      e.preventDefault();

      let body = {};

      if (location.pathname === `/anonymous/secret/${id}`) {
        body = {
          boardId: boardId.id,
          contents: textValue,
          token: secretToken,
        };
      } else {
        body = {
          boardId: boardId.id,
          contents: textValue,
          token: anonymousToken,
        };
      }

      api
        .post(`/api/v1/comments`, body)
        .then((res) => {
          if (commentPage === 0) {
            dispatch(forumReducer.actions.addCommnetList(res.data.data));
          }
          dispatch(forumReducer.actions.addTotalElements(totalElements));
          // console.log(res);
        })
        .catch((err) => {
          // Swal.fire({
          //   title: err?.response?.data?.msg,
          //   text:
          //     err?.response?.data?.code === 203
          //       ? `토큰이 발급되지 않았습니다.`
          //       : err?.response?.data?.code === 204
          //       ? "존재하지 않는 게시글 입니다."
          //       : "등록에 실패하였습니다.",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // });
          Errorcode({ code: err?.response?.data?.code });
        });
      // setPostComment(textValue);
      setTextLength(0);
      setTextValue("");
    },
    [
      anonymousToken,
      boardId.id,
      commentsList,
      dispatch,
      textValue,
      totalElements,
    ]
  );

  // const replyTextArea = (e) => {
  //   // commentDivRef.current.children[
  //   //   parseInt(e.target.id)
  //   // ].children[3].classList.toggle("display_none");
  // };

  return (
    <DetailCommentsWrap>
      <span>댓글({totalElements})</span>
      <CommentDiv>
        <CommentColumnDiv>
          <CommentTextArea
            multiline
            value={textValue}
            onChange={(e) => handleSetValue(e)}
            placeholder="댓글을 작성하세요"
            onKeyDown={(e) => handleSetTab(e)}
            onKeyUp={handleSetLength}
          ></CommentTextArea>
          <span>{textLength}자/200자</span>
        </CommentColumnDiv>
        <MyButton
          onClick={postComments}
          text={"등록"}
          color={"white"}
          width={"95px"}
        />
      </CommentDiv>
      <CommentListWrap>
        {commentsList?.map((item, idx) => {
          return (
            <ForumCommentLists
              contents={item.contents}
              key={item?.id?.toString()}
              id={item.id}
              parentId={item.id}
              userName={item.userName}
              createDate={item.createDate}
              childCount={item.childCount}
              // replyTextArea={replyTextArea}
            />
          );
        })}
      </CommentListWrap>
      {commentsList.length === 0 ? null : (
        <PaginationContainer>
          <Pagination2
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            totalElements={totalElements}
            size={10}
          />
        </PaginationContainer>
      )}
    </DetailCommentsWrap>
  );
};

const DetailCommentsWrap = styled.div`
  ${MyDetailCommentsWrap}

  span {
    width: 95%;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #232323;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
`;

const CommentDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
`;

const CommentColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 91%;
  border-radius: 10px;
  border: 1px solid #b2b8c1;
  padding: 5px;
  margin-right: 10px;

  span {
    width: 100%;
    text-align: right;
    font-weight: 700;
    font-size: 12px;
    margin: 0;
  }
`;

const CommentTextArea = styled.textarea`
  width: 100%;
  height: 50px;
  resize: none;
  border: none;
  background: transparent;
  letter-spacing: 0.1em;

  &:focus {
    outline: none;
  }
`;

const CommentListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: 0;
`;
const PaginationContainer = styled.div`
  ${MyPaginationContainer}
`;
export default Comments;
