import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ForumLayout from "../../../components/forum/ForumLayout";
import api from "../../../redux/api";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NoticesMaster = () => {
  const [contentList, setContentList] = useState([]);
  const [noticeTotalPage, setNoticeTotalPage] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);

  const startDate = useSelector((state) => state.select.startDate);
  const endDate = useSelector((state) => state.select.endDate);

  const selectedStartDate = new Date(startDate);
  const selectedStartDateFormat =
    moment(selectedStartDate).format("YYYY-MM-DD");
  const selectedEndDate = new Date(endDate);
  const selectedEndDateFormat = moment(selectedEndDate).format("YYYY-MM-DD");
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(startDate);
    const accessToken = localStorage.getItem("token");
    accessToken === null && navigate("/login");
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const dateQuery =
      startDate === undefined
        ? ""
        : startDate === null
        ? ""
        : startDate === ""
        ? ""
        : endDate === undefined
        ? ""
        : endDate === null
        ? ""
        : endDate === ""
        ? ""
        : `&start=${selectedStartDateFormat}&end=${selectedEndDateFormat}`;

    // ResetPage(dateQuery, setPage, 0);

    api
      .get(`/api/v1/master-notices?page=${page}&size=9${dateQuery}`, config)
      .then((res) => {
        // console.log(res);
        setContentList(res.data.data.content);
        // console.log(contentList);
        setNoticeTotalPage(res.data.data.totalPages);
        setTotalElements(res.data.data.totalElements);
        setSize(res.data.data.size);
      });
  }, [page, startDate, endDate]);

  return (
    <ForumLayout
      subtitle={"MASTER NOTICE"}
      title={"대표이사 공지사항"}
      buttonboolean={false}
      Lists={contentList}
      name={"master-notices"}
      page={page + 1}
      totalPages={noticeTotalPage}
      totalElements={totalElements}
      size={size}
      setPage={setPage}
      littleTitle={"공지사항 항목"}
    ></ForumLayout>
  );
};

export default NoticesMaster;
