import { configureStore } from "@reduxjs/toolkit";
// import { combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import commutesReducer from "./reducers/commutesReducer";
import forumReducer from "./reducers/forumReducer";
import humanReducer from "./reducers/humanReducer";
import paymentReducer from "./reducers/paymentReducer";

import logInReducer from "./reducers/logInReducer";
import selectReducer from "./reducers/selectReducer";

import pageReducer from "./reducers/pageReducer";

const store = configureStore({
  reducer: {
    logIn: logInReducer.reducer,
    commutes: commutesReducer.reducer,
    human: humanReducer.reducer,
    forum: forumReducer.reducer,
    payment: paymentReducer.reducer,
    select: selectReducer.reducer,
    pageQuery: pageReducer.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
